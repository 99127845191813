import React from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from 'components/Container';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import MediaQuery from 'react-responsive';

const Support = () => {
    return (
    <div>

    <MediaQuery maxDeviceWidth={459}>
        <Container>
            <Grid container direction='row' >
                <Grid xs={1}>
                </Grid>
                <Grid container item xs={10} justifyContent='center'>
                    <Typography variant='h6' fontWeight={600} marginBottom={2} >
                        Need a Support?
                    </Typography>
                    <Typography text='secondary' textAlign='center' marginBottom={2} >
                        Contact Prism Support for your technical support 
                        requests or view our resources
                    </Typography>
                    <Button variant="text" href='https://prismsoftware.com/support' >Open a Ticket</Button>
                </Grid>
                <Grid xs={1}>
                </Grid>
                <Grid item container justifyContent='center'>
                    <Divider variant='middle' textAlign='center' marginTop={2}  style={{width:'300px'}} />
                </Grid>
                <Grid xs={1}>
                </Grid>
                <Grid container item xs={10} justifyContent='center'>
                    <Typography variant='h6' fontWeight={600} marginBottom={2} textAlign='center' marginTop={2}>
                        Customize a Plan
                    </Typography>
                    <Typography text='secondary' textAlign='center' marginBottom={2}>
                        Prism Software can focus on customized solutions based on your organization needs
                    </Typography>
                    <Button variant="text" href='https://prismsoftware.com/contact-us/'>Contact Us</Button>
                </Grid>
                <Grid xs={1}>
                </Grid>
            </Grid>
        </Container>
    </MediaQuery>
    <MediaQuery minDeviceWidth={460}>
        <Container>
            <Grid container direction='row' >
                <Grid container item xs={1}>
                </Grid>
                <Grid container item xs={4} justifyContent='center'>
                    <Typography variant='h6' fontWeight={600} marginBottom={2} >
                        Need a Support?
                    </Typography>
                    <Typography text='secondary' textAlign='center' marginBottom={2} >
                        Contact Prism Support for your technical support 
                        requests or view our resources
                    </Typography>
                    <Button variant="text" href='https://prismsoftware.com/support'>Open a Ticket</Button>
                </Grid>
                <Grid container item xs={1}>
                </Grid>
                <Divider orientation='vertical' alignItems='center' display="flex"  style={{height:'200px'}} />
                <Grid container item xs={1}>
                </Grid>
                <Grid container item xs={4} justifyContent='center'>
                    <Typography variant='h6' fontWeight={600} marginBottom={2} textAlign='center'>
                        Customize a Plan
                    </Typography>
                    <Typography text='secondary' textAlign='center' marginBottom={2}>
                        Prism Software can focus on customized solutions based on your organization needs
                    </Typography>
                    <Button variant="text" href='https://prismsoftware.com/contact-us/'>Contact Us</Button>
                </Grid>
                <Grid container item xs={1}>
                </Grid>
            </Grid>
        </Container>
    </MediaQuery>

    </div>
    )
};

export default Support;