import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Typography from '@mui/material/Typography';
import platform from '../../../../images/isometric-organization-1.svg';
import cog from '../../../../images/isometric-cog.svg';
import home from '../../../../images/dr/dr-overview-home-new.svg';
import MediaQuery from 'react-responsive';

const Meat = () => {
    const theme = useTheme();
    return (
    <Box>
        <Box sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>

        <Grid justifyContent='center'>
            <Typography
                variant='h4'
                textAlign='center'
                marginTop='30px'
                fontWeight={700}
                textAlign='center'
                paddingLeft={{ xs: 3, sm: 3, md: 0 }}
                paddingRight={{ xs: 3, sm: 3, md: 0 }}
                marginBottom={{ xs: 3, sm: 3, md: 0 }}
                >
                Enterprise Content Management with Work Process Automation
            </Typography>
            <Container sx={{ maxWidth: '580px', marginTop: '-40px', marginBottom: '-20px' }}>
            <Typography
                flex={1}
                textAlign='center'
                justifyContent='center'
                color='text.secondary'
                >
                DocRecord is an affordable, comprehensive, and all-inclusive ECM platform 
                that enables the automation of complex work processes.
            </Typography>
            </Container>

            <Box sx={{ backgroundColor: theme.palette.alternate.main }}>
            <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
                <Grid 
                    container 
                    spacing={2}
                    position={'relative'}
                    marginTop={-10}
                    marginBottom={-10}
                >
                
                    <Grid container item xs={12} md={6} justifyContent='center' >

                        <Container sx={{ justifyContent: 'center', textAlignVertical: 'center' }}>
                        <Typography variant='h4' marginTop={{ xs: 5, sm: 3, md: 0 }} style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>DocRecord &mdash; Platform for the whole organization</Typography>
                        <Typography color='text.secondary'>
                            Every department benefits from automated work processes and workflows, digital 
                            archiving, and instant electronic document retrieval.<br/><br/>
                            DocRecord is an affordable, all-inclusive platform that is priced simply by 
                            the concurrent user and not by the module.
                        </Typography>
                        </Container>
                    </Grid>

                    <Grid container item xs={12} md={6} justifyContent='center'>
                    <Container marginTop={{ xs: 0, sm: -2, md: 4 }} marginBottom={{ xs: 2, sm: 0, md: 0 }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                        <img style={{ maxWidth: '400px', left: 'auto', right: 'auto', textAlign: 'center', marginTop: -40, marginBottom: 40 }} src={platform} />
                    </Container>
                    </Grid>
                </Grid>
            </Container>
            </Box>

            <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
                <Grid container 
                    spacing={2}
                    position={'relative'}
                    marginTop={-10}
                    marginBottom={-10}
                    direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}
                >

                    <Grid container justifyContent='center' item xs={12} sm={12} md={6}>
                        <Container marginTop={{ xs: -6, sm: -10, md: 0 }} marginBottom={{ xs: 8, sm: 5, md: 3 }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                            <img style={{ maxWidth: '400px', left: 'auto', right: 'auto' }} src={cog} />
                        </Container>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Container>
                        <Typography variant='h4' marginTop={{ xs: 5, sm: 3, md: 0 }} style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Work process automation improves efficiencies & reduces costs</Typography>
                        <Typography color='text.secondary'>
                            Automated work processes and workflows are essential for an organization to improve 
                            efficiencies and reduce costs. It allows you to get more done in less time.
                        </Typography>
                        </Container>
                    </Grid>
                </Grid>
            </Container>

            <Box sx={{ backgroundColor: theme.palette.alternate.main }}>
            <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
                <Grid 
                    container 
                    spacing={2}
                    position={'relative'}
                    marginTop={-10}
                    marginBottom={-10}
                >
                
                    <Grid container item xs={12} md={6} justifyContent='center' >

                        <Container sx={{ justifyContent: 'center', textAlignVertical: 'center' }}>
                        <Typography variant='h4' marginTop={{ xs: 5, sm: 3, md: 0 }} style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Instant access to workflows, documents & content archive from anywhere</Typography>
                        <Typography color='text.secondary'>
                            DocRecord provides instant, remote, and secure access to all your work processes, 
                            workflows, eForms, documents, and content &mdash; allowing your team to work from anywhere.
                        </Typography>
                        </Container>
                    </Grid>

                    <Grid container item xs={12} md={6} justifyContent='center'>
                    <Container marginTop={{ xs: -2, sm: -4, md: 4 }} marginBottom={{ xs: 2, sm: 1, md: 0 }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                        <img style={{ maxWidth: '400px', left: 'auto', right: 'auto', textAlign: 'center', marginTop: -40, marginBottom: 40 }} src={home} />
                    </Container>
                    </Grid>
                </Grid>
            </Container>
            </Box>
        </Grid>
        </Box>
    </Box>
    )
};

export default Meat;
