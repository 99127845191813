import React from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from 'components/Container';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { colors } from '@mui/material';
import './Automation.css';
import etl from '../../../../../images/dr/etl.jpg';
import intelligent from '../../../../../images/dr/intelligent.jpg';
import rpa from '../../../../../images/dr/rpa.png';
import iso from '../../../../../images/dr/isometric-cog.png';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';


const mock = [
  {
    media: <img src={intelligent} style={{ borderRadius: '20px', maxWidth: '100%', display: 'block', margin: 'auto' }} />,
    title: 'Intelligent work process automation',
    description: 'DocRecord’s intelligent work process automation is designed to eliminate much of an organization’s tedious and repetitive manual document and data processing. These automations enable substantial costs savings and greater operational efficiencies by speeding processing times, reducing workforce labor, and eliminating errors. ',

  },
  {
    media: <img src={rpa} style={{ maxWidth: '100%', display: 'block', margin: 'auto' }} />,
    title: 'RPA: Robotic Process Automation',
    description: 'DocRecord incorporates RPA to greatly reduce repetitive manual tasks. It allows the creation of automated and reusable processes for tasks such as accounts payable and receivable efforts, billing and invoice processing, check reconciliation and approvals, document validation and merging, loan and claims processing, and more. ',
  },
  {
    media: <img src={iso} style={{ borderRadius: '20px', maxWidth: '100%', display: 'block', margin: 'auto' }} />,
    title: 'ETL: Extract, Transform, and Load Automation',
    description: 'DocRecord’s ETL function provides additional capabilities for automated document processing such as data extraction with OCR, file and document conversions, document merging, annotations, security, automated redaction, document and image clean-up and corrections, document serialization, and more.',
  },
];


const Automation = () => {
  const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1100,
      lg: 1200,
      xl: 1536,
    },
  },
});

  const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const sliderOpts = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: ( isMd ? true : false),
    autoplay: true,
    autoplaySpeed: 4000,
  };

    const sliderOptsCopy = {
    dots: true,
    lazyLoad: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div>
    <Container>
    <link href="http://kenwheeler.github.io/slick/slick/slick.css" rel="stylesheet"/>
    <link href="http://kenwheeler.github.io/slick/slick/slick-theme.css" rel="stylesheet"/>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Your Work Process Automation That Lowers Cost
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color={'text.secondary'}
          data-aos={'fade-up'}
          marginBottom={{ xs: -4, sm: -4, md: -4 }}
        >
          Easily transform and automate your manual processes
        </Typography>
      </Box>
      <ThemeProvider theme={theme}>
      <Box maxWidth={{ xs: 420, sm: 720, md: 1400 }} margin={'0 auto'}>
        <Slider {...sliderOpts}>
          {mock.map((item, i) => (
            <Box key={i} padding={{ xs: 1, md: 2, lg: 3 }}>
              <Box
                display={'block'}
                width={1}
                height={1}
              >
               <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}
                  alignItems={{ xs: 'stretched', sm: 'flex-start', md: 'flex-start', lg: 'flex-start' }}
                  justifyContent={'center'}
                  marginTop={2}
                >
                  <Grid container spacing={10}>

                    <Grid item container xs={12} sm={12} md={7} justifyContent='center' alignItems='center'  marginBottom={{ xs: 8, sm: -8, md: -8 }} >
                      <Container>
                      <Box sx={{ borderRadius: '20px', display: 'flex', flex: 0, objectFit: 'contain' }} marginTop={{ xs: -2, sm: -4, md: -4 }}>
                        {item.media}
                      </Box>
                      </Container>
                    </Grid>

                    <Grid container  item xs={12} sm={12} md={5}>
                      <Typography variant='h6' fontWeight={600} marginBottom={{ xs: 4, sm: 3, md: 6 }} marginTop={{ xs: -16, sm: -2, md: 4 }}>
                        {item.title}
                      </Typography>
                      <Typography color="text.secondary" marginTop={{ xs: -6, sm: 0, md: -8 }}>
                        {item.description}
                      </Typography>
                    </Grid>

                  </Grid>
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
      </ThemeProvider>
    </Container>
    </div>
  );
};

export default Automation;

