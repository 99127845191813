/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import hero from './Hero.svg'
const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const LeftSide = () => (
    <Box>
      <Box marginBottom={2}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'light',
          }}
          gutterBottom
          color={'common.white'}
        >
          Protect Konica Minolta bizhub MFPs from unauthorized breaches of documents and data
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography
          component={'span'}
          variant="h3"
          sx={{ fontWeight: 700, color: 'common.white' }}
        >
          bizhub SECURE Alert protects multifunctional printers
        </Typography>
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent={'flex-start'}
        marginTop={2}
      >
      </Box>
    </Box>
  );

  return (
    <Grid container spacing={4}>
      <Grid item container alignItems={'center'} xs={12} md={6}>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <LeftSide />
        </Box>
      </Grid>
    
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            position: 'relative',
            marginX: 'auto',
          }}
        >
          {/*<Box
            sx={{
              position: 'relative',
              marginX: 'auto',
            }}
          >
            <Box>
              <Box
                position={'relative'}
                display={{ xs: 'none', sm: 'none', md: 'flex' }}
                zIndex={2}
                maxWidth={1}
                height={'auto'}
                sx={{ verticalAlign: 'middle' }}
              >
                <img src={hero} style={{ maxWidth: '100%', borderRadius: '25px'}} />
              </Box>
            </Box>
          </Box>*/}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Hero;
