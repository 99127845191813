import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import cs_benefits1 from 'images/cs_benefits1.jpg';
import cs_benefits2 from 'images/cs_benefits2.jpg';
import tablet from '../images/tablet.svg';
import handshake from '../images/handshake.svg';

const Benefits = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const LeftSide = () => (
    <Grid container spacing={2}>
      <Grid container item alignItems='center'>
        <Box
          width={0.9}
          height={0.9}
          sx={{
            display: { xs: 'none', sm: 'block', md: 'block' }
          }}
        >
          <img src={tablet} style={{ borderRadius: '20px' }} />
        </Box>
      </Grid>
      <Grid container item alignItems='center'>
        <Box
          width={0.6}
          height={0.6}
          sx={{
            transform: 'translateX(140px)',
            display: { xs: 'none', sm: 'block' }
          }}
        >
          {/*<img src={handshake} style={{ borderRadius: '20px' }} />*/}
        </Box>
      </Grid>
    </Grid>
  );

  const RightSide = () => (
    <Box>
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
            color: '#555555'
          }}
          gutterBottom
          color={'text.secondary'} // TODO change color
        >
          Always data-driven
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
          Create complex personalized documents
        </Typography>
        <Typography color="text.secondary" variant={'h6'}>
          DocForm creates these complex communications by ingesting data from multiple sources and inputs. 
          These input sources include multiple databases, print queues, XML streams, and many other data sources.
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {[
          'DocForm also processes nested-XML streams to create complex and data-nested statements often used in financial and banking statements.',
          'DocForm can then conditionally process these data sources to further create on-the-fly charts and graphs, conditional image selection, and calculations.',
          'DocForm can dynamically create QR codes and 95 other styles of barcodes to match all barcode requirements.'
        ].map((item, i) => (
          <Grid item xs={12} key={i}>
            <Box component={ListItem} disableGutters width={'auto'} padding={0}>
              <Box
                component={ListItemAvatar}
                minWidth={'auto !important'}
                marginRight={2}
              >
                <Box
                  component={Avatar}
                  bgcolor={theme.palette.secondary.main}
                  width={20}
                  height={20}
                >
                  <svg
                    width={12}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Box>
              </Box>
              <ListItemText primary={item} />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box
        marginTop={4}
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'flex-start' }}
      >
        <Button
          component={'a'}
          variant="contained"
          color="primary"
          size="large"
          href={
            'https://prismsoftware.com/contact-us/'
          }
        >
          Learn More
        </Button>
        {/* <Box
          marginTop={{ xs: 2, sm: 0 }}
          marginLeft={{ sm: 2 }}
          width={{ xs: '100%', md: 'auto' }}
        >
          <Button
            component={'a'}
            href={'/docs/introduction'}
            variant="outlined"
            color="primary"
            size="large"
            fullWidth={isMd ? false : true}
          >
            Download Datasheet      {/**TODO add download arrow *
          </Button>
        </Box> */}
      </Box>
    </Box>
  );

  return (
    <Grid container spacing={4} >
      <Grid
        item
        xs={12}
        md={6}
      >
        <LeftSide />
      </Grid>
      <Grid item container justifyContent='center' alignItems={'center'} xs={12} md={6}>
        <RightSide />
      </Grid>
    </Grid>
  );
};

export default Benefits;
