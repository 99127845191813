import React from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from 'components/Container';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from '@mui/material';
import './Automation.css';
//import slideOne from './slideone.svg';
//import slideTwo from './slidetwo.svg';

const mock = [
  {
    //media: <img src={slideOne}  />,
    title: 'Secure repository',
    description: 'Prism eForms provides keyboard-searchable, secure, and advanced-featured archives for all collected data, eForms, and created documents. \
    Users can access this archive from either a user\'s desktop or through a mobile client.'

  },
  {
    //media: <img src={slideTwo} />,
    title: 'Easily create eForm Packets',
    description: 'Enter key data, such as a patient or student ID, and perform data look-up from a database and auto-populate a packet of forms \
    or documents. This saves time and eliminates data entry errors.'
  },
  {
    //media: <img src={integration} style={{ borderRadius: '20px' }} />,
    title: 'Proof of delivery',
    description: 'Provide easy proof of delivery of services and goods with mobile eForms with finger signature ability. \
    Create a workflow that sends notifications to others, mates the delivery receipt with other documents, and \
    files the document into its own archive system.'
  },
];


const Automation = () => {
  const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1100,
      lg: 1200,
      xl: 1536,
    },
  },
});

  const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const sliderOpts = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: ( isMd ? true : false),
    autoplay: true,
    autoplaySpeed: 4000,
  };

    const sliderOptsCopy = {
    dots: true,
    lazyLoad: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div>
    <Container>
    <link href="http://kenwheeler.github.io/slick/slick/slick.css" rel="stylesheet"/>
    <link href="http://kenwheeler.github.io/slick/slick/slick-theme.css" rel="stylesheet"/>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Why organizations choose eForms
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color={'text.secondary'}
          data-aos={'fade-up'}
        >
          Replace your organization's paper forms and discover a total processing system that goes beyond web forms.
        </Typography>
      </Box>
      <ThemeProvider theme={theme}>
      <Box maxWidth={{ xs: 420, sm: 720, md: 1400 }} margin={'0 auto'}>
        <Slider {...sliderOpts}>
          {mock.map((item, i) => (
            <Box key={i} padding={{ xs: 1, md: 2, lg: 3 }}>
              <Box
                display={'block'}
                width={1}
                height={1}
              >
               <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}
                  alignItems={{ xs: 'stretched', sm: 'flex-start', md: 'flex-start', lg: 'flex-start' }}
                  justifyContent={'center'}
                  marginTop={2}
                >
                  <Grid container>
                    <Grid item xs={1} sm={1} md={1}>
                    
                    </Grid>

                    {/*<Grid item container xs={12} sm={12} md={5} justifyContent='center' alignItems='center' marginBottom={{ xs: 8, sm: 8, md: 0 }} >
                      <Box width={0.8} height={0.8}>
                        {item.media}
                      </Box>
                    </Grid>*/}

                    <Grid container  item xs={12} sm={12} md={10}>
                      <Typography variant='h6' fontWeight={600} marginBottom={5} marginTop={2}>
                        {item.title}
                      </Typography>
                      <Typography color="text.secondary">
                        {item.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                    
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
      </ThemeProvider>
    </Container>
    </div>
  );
};

export default Automation;
