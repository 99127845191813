import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Container from 'components/Container';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import Contact from '../../Contact/Contact.js';
import Main from 'layouts/Main';
import { useTheme } from '@mui/material/styles';
import Routes from '../../../../../../Routes.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Aug12 = () => {
  const theme = useTheme();
  // mui xs, sm, md responsiveness
  return (
    <Main>
    
        <Box marginTop={{md: 1.8}}>
          <Box bgcolor={theme.palette.primary.main}>
            <Container paddingX={{ xs: 2, sm: 4 }}>
              <Typography
                variant={'h4'}
                gutterBottom
                sx={{
                  fontWeight: 700,
                  color: theme.palette.common.white,
                }}
              >
                News
              </Typography>
              <Typography
                gutterBottom
                sx={{  
                  color: theme.palette.common.white,
                }}
              >
                Latest news of Prism Software
              </Typography>
            </Container>
            <Box
              component={'svg'}
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
              width={1}
              marginBottom={-1}
            >
              <path
                fill={theme.palette.background.paper}
                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
              ></path>
            </Box>
          </Box>
          <Container
        paddingTop={'0 !important'}
        paddingX={{ xs: 5, sm: 4 }}
        position={'relative'}
        top={0}
          >
            <Box
              component={Grid}
              container
              spacing={4}
              flexDirection={{ xs: 'column', md: 'row' }}
            >
    <Box sx={{ justifyContent: 'center' }}>
    <Container>
      <Box marginLeft={'-6px'} marginBottom={'15px'}>
        <Button href='/news' startIcon={<ArrowBackIcon/>} >Back</Button>
      </Box>

      <Typography variant='h5' sx={{ marginBottom: 2 }}>
        Prism Software Announces the Release of DocRecord v6.5
      </Typography>

      <Typography color='text.secondary' sx={{ textTransform: 'uppercase', marginBottom: 2 }}>
        August 12, 2019
      </Typography>

      <Typography variant='div' marginBottom={2}>
      <span style={{ fontWeight: 'bold' }}>About Update: </span> 
        DocRecord v6.5 introduces an industry-first with advanced document security for Konica Minolta MFPs 
        (multifunction printers). MFP document security by DocRecord allows organizations to secure their documents 
        and data by tracking Konica Minolta MFPs usage &mdash; who used, when, and for what. Konica Minolta has separately 
        launched this capability under their product name <span style={{ fontStyle: 'italic' }}>bizhub SECURE Alert</span>.
        <br/><br/>
        MFP Document Security (Konica Minolta: <span style={{ fontStyle: 'italic' }}>bizhub SECURE Alert</span>) allows organizations to be immediately notified of 
        document security breaches that occur at their MFPs. These user-configurable breach alerts enable organizations 
        to choose key words and data to monitor, and then to be alerted upon the occurrence of this data with any MFP 
        document activity. DocRecord also archives an MFP-user record, audit trail, and full document record of all MFP 
        activities enabling organizations to examine the details of any document/data security breach. For more information, 
        please visit <a href='https://www.prismsoftware.com/solutions/bizhub-secure-alert' style={{ textDecoration: 'none', marginLeft: '3px' }}>https://www.prismsoftware.com/solutions/bizhub-secure-alert</a>.    
        <br/><br/>
      </Typography>

      <Typography variant='div' marginBottom={2}>
      <span style={{ fontWeight: 'bold' }}>About DocRecord: </span> 
        DocRecord is a very affordable, all-inclusive electronic content management (ECM) application 
        designed for the midsized organization. It is priced simply by the concurrent user and not by 
        each feature. Its all-inclusive feature set contains: eForms, automated workflows, advanced 
        data capture, content archiving, automated personalized document creation, network folder migration, 
        and much more. DocRecord also optionally enables MFP document security that provides real-time 
        monitoring of user activities at Konica Minolta MFPs (multifunction printers) by providing security 
        breach alerts and user audit trails. DocRecord's all-inclusive architecture ensures a scalable and low-cost 
        platform that easily grows as a customer's organization and processing requirements grow.
        For more information, please visit
        <a href='https://www.prismsoftware.com/solutions/docrecord' style={{ textDecoration: 'none', marginRight: '3px', marginLeft: '3px' }}>https://www.prismsoftware.com/solutions/docrecord</a>
        and <a href='https://www.prismsoftware.com/solutions/bizhub-secure-alert' style={{ textDecoration: 'none', marginLeft: '3px' }}>https://www.prismsoftware.com/solutions/bizhub-secure-alert</a>.    
        <br/><br/>
      </Typography>

      <Typography variant='div' marginBottom={2}>
      <span style={{ fontWeight: 'bold' }}>About Prism Software: </span> 
        Prism provides software solutions that greatly improve and automate how work is done while substantially lowering costs. 
        Prism specializes in: ECM with work process automation, advanced work process automation, easy no-code REST API integrations 
        and connections, advanced data capture, and automated business connections. 
        Learn more at <a href='/' style={{ textDecoration: 'none' }}>www.prismsoftware.com</a>.
      </Typography>

    </Container>      
    </Box>
    </Box>
  </Container>
  <Contact />
</Box>

</Main>
  );
};

export default Aug12;
