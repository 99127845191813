/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import more from './More.svg';



const More = () => {
  const theme = useTheme();

  const RightSide = () => (
    <Box>
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
            color: '#555555'
          }}
          gutterBottom
        >
          User-Defined Keywords and Data Patterns
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
          Customize with your keywords and data
        </Typography>
        <Typography color="text.secondary" variant={'h6'} marginBottom={4}>
          Each organization can define temporary or permanent data and keywords that constitute a breach. Keywords 
          and data patterns can be entered singly or batch-imported.
        </Typography>
        <Typography variant={'p'}>
          Data patterns include:
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {[
          'Social security numbers',
          'Credit card and Patient numbers',
          'Phone numbers and dates',
          'IDs of patients, students and employees',
          'Email addresses and URLs',
          'Name of patients, students, employees, and others',
          'Product or trade names',
          'Compliance terms',
          'Discrimination terms and phrases',
          'Disparaging phrases',
        ].map((item, i) => (
          <Grid item xs={12} sm={6} key={i}>
            <Box component={ListItem} disableGutters width={'auto'} padding={0}>
              <Box
                component={ListItemAvatar}
                minWidth={'auto !important'}
                marginRight={2}
              >
                <Box
                  component={Avatar}
                  bgcolor={theme.palette.secondary.main}
                  width={20}
                  height={20}
                >
                  <svg
                    width={12}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Box>
              </Box>
              <ListItemText primary={item} />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box
        marginTop={4}
        marginBottom={{ xs: 0, sm: 4, md: 0 }}
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'flex-start' }}
      >
        <Button
          component={'a'}
          variant="contained"
          color="primary"
          size="large"
          href={
            'https://prismsoftware.com/contact-us/'
          }
        >
          Learn More
        </Button>
        {/* <Box
          marginTop={{ xs: 2, sm: 0 }}
          marginLeft={{ sm: 2 }}
          width={{ xs: '100%', md: 'auto' }}
        >
          <Button
            component={'a'}
            href={'/docs/introduction'}
            variant="outlined"
            color="primary"
            size="large"
            fullWidth={isMd ? false : true}
          >
            Download Datasheet      {/**TODO add download arrow *
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
  return (
    <Grid container spacing={{xs: 8, sm: 10}} paddingY={{xs: '30px', sm:'40px'}} justifyContent={'center'}>
      <Grid item container alignItems={'center'} xs={12} md={12}>
        <Box marginBottom={{ xs: 0, sm: -10, md: 0 }}>
          <RightSide />
        </Box>
      </Grid>
    </Grid>
  );
};

export default More;
