import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import capture from 'images/capture.png';
import paper from '../images/paper.svg';

const Features = () => {
  const theme = useTheme();

  const RightSide = () => (
    <Grid container spacing={1}>
      <Grid container item style={{display: 'block'}}>
        <Box
          width={1}
          height={1}
        >
          <img src={paper} style={{ width: '100%', height: '100%', borderRadius: '20px' }} />
        </Box>
      </Grid>
    </Grid>
  );

  const LeftSide = () => (
    <Box>
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          ADVANCED CAPABILITIES
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
          Discover advanced scanning capabilities for your MFP
        </Typography>
        <Typography color="text.secondary" variant={'h6'}>
          Simply scanning a document from a multifunction printer (MFP) is usually not enough. 
          Users at the MFP need to do more with their scanned documents.
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {[
          'Easily convert and process scanned documents. This is ideal for organizations that bulk scan documents and require their automated data extraction and filing.',
          'Scan to network folders, online repositories, and many more. Automatic routing of documents save time and eliminate errors.',
          'Provide security and convenience for MFP users with FollowPrint and secure release printing.',
        ].map((item, i) => (
          <Grid item xs={12} key={i}>
            <Box component={ListItem} disableGutters width={'auto'} padding={0}>
              <Box
                component={ListItemAvatar}
                minWidth={'auto !important'}
                marginRight={2}
              >
                <Box
                  component={Avatar}
                  bgcolor={theme.palette.secondary.main}
                  width={20}
                  height={20}
                >
                  <svg
                    width={12}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Box>
              </Box>
              <ListItemText primary={item} />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box marginTop={4}>
        <Button
          variant={'contained'}
          size={'large'}
          href={
            'https://prismsoftware.com/contact-us/'
          }
        >
          Get started
        </Button>
      </Box>
    </Box>
  );

  return (
    <Grid container spacing={4}>
      <Grid
        item
        xs={12}
        md={6}
      >
        <LeftSide />
      </Grid>
      <Grid item container alignItems={'center'} xs={12} md={6}>
        <RightSide />
      </Grid>
    </Grid>
  );
};

export default Features;
