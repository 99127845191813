import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Meat from './Meat.js';
import Capabilities from './Capabilities.js';
import CommonProbs from './CommonProblems.js';
import InterestForm from './InterestForm';
import Video from './Video.js';


const OverviewDR = () => {
  const theme = useTheme();
  return (
    <Box>
        <Meat />
        <Capabilities />
        <CommonProbs />
        <Video />
        <InterestForm />
    </Box>
  );
};

export default OverviewDR;
