//import pages from other folders to be linked
import Routes from '../Routes.js';

const pages = {
  products: [
    {
      title: 'Enterprise Content Management (ECM)',
      subtitle: 'DocRecord',
      href: '/solutions/docrecord'
    },
    {
      title: 'Work Process Automation',
      subtitle: 'Prism WorkPath',
      href: '/solutions/workpath',
    },
    {
      title: 'Restful API Integration',
      subtitle: 'WorkPath Connect',
      href: '/solutions/workpath-connect',
    },
    {
      title: 'Smartphone Forms with eSignature',
      subtitle: 'Smartphone Forms',
      href: '/solutions/smartphone-forms',
    },
    {
      title: 'Automated Document Capture & Extraction',
      subtitle: 'Prism Capture',
      href: '/solutions/capture',
    },
    {
      title: 'Automated Processing of Scanned Documents',
      subtitle: 'ScanPath',
      href: '/solutions/scanpath',
    },
    {
      title: 'Automated Business Communications',
      subtitle: 'DocForm',
      href: '/solutions/docform',
    },
    {
      title: 'eForms & Data Collection',
      subtitle: 'eForms',
      href: '/solutions/eforms',
    },
    {
      title: 'MFP Security & Breach Alerts',
      subtitle: 'bizhub SECURE Alert',
      href: '/solutions/bizhub-secure-alert',
    },
  
  ],
  about: [
    {
      subtitle: 'Company',
      href: '/about',
    },
    {
      subtitle: 'News',
      href: '/news',
    },
    {
      subtitle: 'Careers',
      href: '/careers',
    },
    
  ],
  support: [
    {
      subtitle: 'Support',
      href: '/support',
    },
    {
      subtitle: 'Technical Knowledge Base',
      href: 'https://prismsoftware.atlassian.net/wiki/spaces/PS/overview?mode=global',
    },
    {
      subtitle: 'Product Documentation',
      href: 'http://prismsupport.com/documentation/Content/A_IntroTopics/PrismDocumentation-Home.htm',
    },
    
  ],
  
};

export default pages;
