import * as React from 'react';
import MenuUnstyled from '@mui/base/MenuUnstyled';
import Box from '@mui/material/Box';
import MenuItemUnstyled, {
  menuItemUnstyledClasses,
} from '@mui/base/MenuItemUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/system';

const blue = {
    95: '#e8f2fc',
    90: '#d1e6fa',
    75: '#8dbff2',
    45: '#1976d2',
};

const grey = {
    100: '#f2f2f2',
    200: '#808080',
    300: '#595959',
};

//TAB STYLING

const Tab = styled(TabUnstyled)`
  font-family: Lato, sans-serif;
  color: #535b5f;
  cursor: pointer;
  font-size: 0.875rem;
  background-color: transparent;
  width: 100%;
  padding: 12px 12px;
  margin: 4px 4px;
  margin-right: 4px;
  border: none;
  border-radius: 8px;
  justify-content: center;

  &:hover {
    background-color: ${blue[95]};
  }


  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[90]};
    color: #474e52;
  }

`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  justify-content: center;
  font-family: Lato, sans-serif;
  font-size: 0.875rem;
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: hidden;
`;

const TabsList = styled(TabsListUnstyled)`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
  align-content: space-evenly;
`;


//DROPDOWN MENU STYLING

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-family: Lato, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 2px 3px;
  margin: 10px 0px;
  min-width: 200px;
  background: #fff;
  border: 1px solid ${ grey[300]};
  width: 100%;
  color: ${grey[300]};
  outline: 0px;
  display: flex;
  flex-direction: column;
  `,
);

const StyledMenuItem = styled(MenuItemUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${menuItemUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : blue[95]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : blue[95]};
  }

  &.${menuItemUnstyledClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${menuItemUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : blue[95]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }
  `,
);

const TriggerButton = styled('button')(
  ({ theme }) => `
  font-family: Lato, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width: 200px;
  background: #fff;
  border: 1px solid ${grey[300]};
  border-radius: 0.75em;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  justify-content: center;
  line-height: 1.5;
  color: ${grey[300]};

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : blue[95]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &.${buttonUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[95]};
  }

  &::after {
    content: '▾';
    float: right;
  }
  `,
);

const Popper = styled(PopperUnstyled)`
  z-index: 1;
`;

function TabPanelMobile(props) {
  const { children, value, index, name, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelMobile.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  name:  PropTypes.string.isRequired,
};

function allprops(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function XsProblem() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);
  const buttonRef = React.useRef(null);
  const menuActions = React.useRef(null);
  //set value number for each option
  const [value, setValue] = React.useState(0);
  //update name upon selection
  const [name, setName] = React.useState("Accounting");

  const handleButtonClick = (event) => {
    if (isOpen) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleNameChange = (event, newName) => {
        setName(newName);
        console.log('the name has been updated')
    };

  const handleChange = (event, newValue) => {
        setValue(newValue);
        console.log('the value has been updated')
    };

  const handleButtonKeyDown = (event) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      if (event.key === 'ArrowUp') {
        menuActions.current?.highlightLastItem();
      }
    }
  };

  const close = () => {
    setAnchorEl(null);
    buttonRef.current.focus();
  };

  const handleClose = () => {
        setAnchorEl(null);
    };

  const createHandleMenuClick = (menuItem) => {
    return () => {
      console.log(`Clicked on ${menuItem}`);
      close();
    };
  };

  return (
    <Box sx={{ marginBottom: 20 }}>
        <Typography variant='h4' sx={{ fontWeight: 700, textAlign: 'center', marginBottom: 3}}>
            Department {'&'} Industry Problems We Solve
        </Typography>
        <Typography color='text.secondary' sx={{ textAlign: 'center', marginBottom: 5 }}>
            Automate your work processes, eliminate manual 
            processing, integrate applications, and greatly 
            reduce costs.
        </Typography>
        <Box sx={{ justifyContent: 'center', textAlign: 'center', transform: 'translate(0, -50%)' }}>
            <TriggerButton
                value={name}
                label="Accounting"
                onChange={handleChange}
                onClick={handleButtonClick}
                onKeyDown={handleButtonKeyDown}
                ref={buttonRef}
                aria-controls={isOpen ? 'simple-menu' : undefined}
                aria-expanded={isOpen || undefined}
                aria-haspopup="menu"
            >

                
                    <TabsUnstyled >
                    <MenuUnstyled
                    open={isOpen}
                    onClose={close}
                    anchorEl={anchorEl}

                    components={{ Root: Popper, Listbox: StyledListbox }}
                    componentsProps={{ listbox: { id: 'simple-menu' } }}
                >
                        <TabsList
                            orientation="vertical"
                            label="Name"
                            value={value}
               
                            onChange={handleChange}
                            >
                            <StyledMenuItem name='Accounting' onClick={createHandleMenuClick('Accounting')}>
                                <Tab value={0}>Accounting</Tab>
                            </StyledMenuItem>
                            <StyledMenuItem name='Human Resources' onClick={createHandleMenuClick('Human Resources')}>
                                <Tab value={1}>Human Resources</Tab>
                            </StyledMenuItem>
                            <StyledMenuItem name='Legal' onClick={createHandleMenuClick('Legal')}>
                                <Tab value={2}>Legal</Tab>
                            </StyledMenuItem>
                            <StyledMenuItem name='Manufacturing' onClick={createHandleMenuClick('Manufacturing')}>
                                <Tab value={3}>Manufacturing</Tab>
                            </StyledMenuItem>
                            <StyledMenuItem name='Healthcare' onClick={createHandleMenuClick('Healthcare')}>
                                <Tab value={4}>Healthcare</Tab>
                            </StyledMenuItem>
                            <StyledMenuItem  name='Education' onClick={createHandleMenuClick('Education')}>
                                <Tab value={5}>Education</Tab>
                            </StyledMenuItem>
                        </TabsList>
                    </MenuUnstyled>
                        <Box>
        <Box 
          display='flex'
          flexDirection='row'
          justifyContent={'center'}
          marginTop={2}
          >
           <TabPanel value={0} >
                    <Grid container rowSpacing={4}>
            
                        <Grid item container xs={12}>
                            <Typography variant='h5' sx={{ fontSize: '18px', fontWeight: 700, marginBottom: 4 }}>
                                Accelerate AP {'&'} AR Processing 
                            </Typography>
                            <Typography color="text.secondary" fontSize='14px'>
                                Organizations require a wide range of 
                                custom accounting process automations 
                                that are nearly instant, error-free, 
                                and reduce costs. With Prism’s applications, 
                                these automated custom and complex processes 
                                are easily built and affordable. 
                                <br />
                                <br />
                                These automated accounting processes include 
                                data extraction from invoices and other documents, 
                                database lookup and validation, payment exceptions 
                                determination, intelligent workflows that include 
                                routing rules and approval guidelines, automated 
                                notifications, purchase order and check creations, 
                                sending data to line-of-business application, 
                                and much more. 
                            </Typography>
                        </Grid>
                      
                    </Grid>
            </TabPanel>
            <TabPanel value={1}>
                    <Grid container rowSpacing={4}>
                        
                        <Grid item container xs={12}>
                            <Typography variant='h5' sx={{ fontSize: '18px', fontWeight: 700, marginBottom: 5 }}>
                                Streamline Application {'&'} On-Boarding Processes 
                            </Typography>
                            <Typography color="text.secondary" fontSize='14px'>
                                New hire applications and on-boarding, as well 
                                as current employee record keeping, require comprehensive 
                                and custom workflows and employee communications. 
                                <br />
                                <br />
                                Prism provides affordable and customized eForms and 
                                workflows for the collection of data and documents for 
                                applicants, and the automated dissemination of documents 
                                for new hires. Existing employees require customized workflows 
                                utilizing eForms, custom employee communications, employee 
                                records archiving, and much more.  
                            </Typography>
                        </Grid>
                    </Grid>
                    </TabPanel>
                    <TabPanel value={2} >
                    <Grid container rowSpacing={4}>
                        
                        <Grid item container xs={12}>
                            <Typography variant='h5' sx={{ fontSize: '18px', fontWeight: 700, marginBottom: 5 }}>
                                Enable Instant Document Access {'&'} Automated Workflows 
                            </Typography>
                            <Typography color="text.secondary" fontSize='14px'>
                                Law firms and legal departments require automated 
                                operational improvements that help minimize labor 
                                costs and speed response and communication times 
                                while substantially improving digital record keeping 
                                and remote retrieval. 
                                <br />
                                <br />
                                Prism’s DocRecord provides this wide range of 
                                affordable automations including document versioning, 
                                document check-in and -out, intelligent custom workflows, 
                                automated email notifications, eForms, document retention 
                                policies, case management, data extraction from documents, 
                                and much more. 
                            </Typography>
                        </Grid>
                    </Grid>
                    </TabPanel>
                    <TabPanel value={3} >
                    <Grid container rowSpacing={4}>
                        
                        <Grid item container xs={12}>
                            <Typography variant='h5' sx={{ fontSize: '18px', fontWeight: 700, marginBottom: 5 }}>
                                Lower Cost of Goods Sold {'&'} Improve Manufacturing Times 
                            </Typography>
                            <Typography color="text.secondary" fontSize='14px'>
                                Manufacturing operations require highly customized 
                                work automation processes that lower costs, speed 
                                production times, reduce errors, improve record keeping, 
                                and provide accurate and timely communications to all stakeholders. 
                                <br />
                                <br />
                                Prism provides this affordable and highly customized set of 
                                automated processes and communications. This automation 
                                includes extracting data from incoming sales orders, 
                                creating manufacturing and supporting documents, intelligent 
                                workflows with multi-stage approvals, auto-generation of 
                                manufacturing and compliance documents, routing status 
                                and other data to other departments, and much more.  
                            </Typography>
                        </Grid>

                    </Grid>
                    </TabPanel>
                    <TabPanel value={4} >
                    <Grid container rowSpacing={4}>
                        
                        <Grid item container xs={12}>
                        <Typography variant='h5' sx={{ fontSize: '18px', fontWeight: 700, marginBottom: 5 }}>
                            Significantly Improve Student {'&'} Faculty Operations  
                        </Typography>
                        <Typography color="text.secondary" fontSize='14px'>
                            Education organizations require a uniquely wide range 
                            of automations that greatly ease and improve all aspects 
                            of campus life, for both students and faculty. These process 
                            automations include enrollments and admissions, financial aid, 
                            accounting, HR, student records, faculty records, healthcare, 
                            and many other aspects. 
                            <br />
                            <br />
                            Prism provides affordable, custom, and complex automations for 
                            many campus data and document work process. These automations 
                            include data gathering eForms, intelligent workflows with 
                            conditional routing, student notifications, routing data to 
                            other campus applications, automated electronic document and 
                            booklet creations, email notifications, data extraction, document 
                            portfolio assembly, document retention policy, remote access to 
                            archives, and much more. 
                        </Typography>
                        </Grid>
                        
                    </Grid>
                    </TabPanel>
                    <TabPanel value={5} >
                    <Grid container rowSpacing={4}>
                        
                        <Grid item container xs={12}>
                            <Typography variant='h5' sx={{ fontSize: '18px', fontWeight: 700, marginBottom: 5 }}>
                                Significantly Improve Data Gathering {'&'} Communications 
                            </Typography>
                            <Typography color="text.secondary" fontSize='14px'>
                                Healthcare organizations require a wide range of automations 
                                that help improve patient care and response times, greatly 
                                speed data collection, and enable instant record retrieval.
                                <br />
                                <br />
                                Prism provides affordable, custom, and complex automations 
                                for every department in healthcare. These automations 
                                include data gathering eForms, intelligent workflows with 
                                multi-level and conditional approvals, discharge alerts, 
                                patient notifications, accounting data, and much more. 
                            </Typography>
                        </Grid>
                            
                    </Grid>
                    </TabPanel>
        </Box>
      </Box>
                    </TabsUnstyled>
                
            </TriggerButton>
      </Box>

      {/*tab content panels */}

      
    </Box>
  );
}