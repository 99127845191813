/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import more from './More.svg';



const More = () => {
  const theme = useTheme();

  const RightSide = () => (
    <Box>
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
            color: '#555555'
          }}
          gutterBottom
        >
          All-inclusive DocRecord
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
          Powered by DocRecord, Prism's all-inclusive ECM
        </Typography>
        <Typography color="text.secondary" variant={'h6'}>
          Prism eForm Designer is part of DocRecord's all-inclusive capabilities. DocRecord users 
          can build a wide range of eForms to collect data from both mobile and desktop platforms. 
          DocRecord users can experience all the features of eForm Designer including:
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {[
          'eSignatures',
          'Workflow and routing',
          'Automated form completion',
          'eForm and data archiving',
          'And many more'
        ].map((item, i) => (
          <Grid item xs={12} sm={6} key={i}>
            <Box component={ListItem} disableGutters width={'auto'} padding={0}>
              <Box
                component={ListItemAvatar}
                minWidth={'auto !important'}
                marginRight={2}
              >
                <Box
                  component={Avatar}
                  bgcolor={theme.palette.secondary.main}
                  width={20}
                  height={20}
                >
                  <svg
                    width={12}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Box>
              </Box>
              <ListItemText primary={item} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
  return (
    <Grid container spacing={{xs: 8, sm: 10}} paddingY={{xs: '30px', sm:'40px'}} justifyContent={'center'}>
      <Grid item container alignItems={'center'} xs={12} md={6}>
        <Box marginBottom={{ xs: 0, sm: -10, md: 0 }}>
          <RightSide />
        </Box>
      </Grid>
      <Grid item container alignItems={'center'} justifyContent='center' xs={12} md={6}>
        <Box
        width={{ xs: 1, sm: 0.8, md: 1 }}
        height={{ xs: 1, sm: 0.8, md: 1 }}
        alignItems='center'
        >
          <img src={more} style={{ borderRadius: '25px' }} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default More;
