import React, { useState } from 'react';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Container from 'components/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Routes from '../../Routes.js';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import capture from '../../svg/prism/landing-capture-1.svg';
import rpa from '../../svg/prism/landing-rpa-icon-1.svg';
import integrations from '../../svg/prism/landing-integrations-icon-1.svg';
import ecm from '../../svg/prism/landing-ecm-icon-1.svg';
import MediaQuery from 'react-responsive'
import etl from '../../images/ETL-WorkPath-Screenshot.png';
import workflow from '../../images/DocRecord-Workflow.jpg';
import drECM from '../../images/DocRecord-desktop-mobile.jpg';
import integrationsPic from '../../images/integrations.png';
import capturePic from '../../images/capture.png';

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Tab = styled(TabUnstyled)`
  font-family: Lato, sans-serif;
  color: #2d3748;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 250px;
  height: 250px;
  padding: 12px 16px;
  margin: 60px 40px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  box-shadow: 1px 1px 9px 5px #f2f2f2;

  &:hover {
    box-shadow: 1px 1px 9px 5px #cce8ff;
  }

  &:focus {
    color: black;
    border-radius: 3px;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    box-shadow: 1px 1px 9px 5px #cce8ff;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: Lato, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

export default function Solutions() {
  return (
    <Box>
            <Typography variant='h4' sx={{ fontWeight: 600, textAlign: 'center', marginBottom: 2, marginTop: 5, fontSize: '20px' }}>
                Solutions for improving how you work {'&'} lowering your costs
            </Typography>
    
    <TabsUnstyled defaultValue={0}>
    <Box>
      <TabsList>
        <Tab>
            <Box>
                <Grid container rowSpacing={6}>
                    <Grid item container>
                        <img src={ecm} style={{ maxWidth: '80px', marginTop: 10 }}/>
                    </Grid>
                    <Grid item container>
                        <Typography sx={{ fontSize: '20px', fontWeight: 600, textAlign: 'left' }}>
                            ECM with Work Process Automation
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Tab>
        <Tab>
            <Box>
                <Grid container rowSpacing={6}>
                    <Grid item container>
                        <img src={rpa} style={{ maxWidth: '80px', marginTop: 10 }}/>
                    </Grid>
                    <Grid item container>
                        <Typography sx={{ fontSize: '20px', fontWeight: 600, textAlign: 'left' }}>
                            Advanced Work Process Automation with RPA {'&'} ETL
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Tab>
        <Tab>
            <Box>
                <Grid container rowSpacing={6}>
                    <Grid item container>
                        <img src={integrations} style={{ maxWidth: '80px', marginTop: 10 }}/>
                    </Grid>
                    <Grid item container>
                        <Typography sx={{ fontSize: '20px', fontWeight: 600, textAlign: 'left' }}>
                            Intelligent Integrations for Data {'&'} Applications
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Tab>
        <Tab>
            <Box>
                <Grid container rowSpacing={6}>
                    <Grid item container>
                        <img src={capture} style={{ maxWidth: '80px', marginTop: 10 }}/>
                    </Grid>
                    <Grid item container>
                        <Typography sx={{ fontSize: '20px', fontWeight: 600, textAlign: 'left' }}>
                            Advanced Data Capture, Extraction {'&'} Automation
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Tab>
      </TabsList>
      </Box>
      <Box maxWidth={{ xs: 420, sm: 620, md: 1 }} margin={'0 auto'}>
            <Box padding={{ xs: 1, md: 2, lg: 3 }}>
              <Box
                display={'block'}
                width={1}
                height={1}
              >
               <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  alignItems={{ xs: 'stretched', sm: 'flex-start' }}
                  justifyContent={'center'}
                  marginTop={2}
                >
                    <Container>
                    <TabPanel value={0}>
                    <Grid container columnSpacing={3} sx={{ marginTop: -12 }}>
            
                        <Grid item container xs={6}>
                            <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: 700, marginBottom: 5 }}>
                                ECM For the Whole Organization 
                            </Typography>
                            <Typography color="text.secondary" marginBottom={1}>
                                DocRecord is an affordable, comprehensive, and all-inclusive ECM 
                                (enterprise content management) platform that enables the automation 
                                of complex work processes throughout the whole organization. 
                                <br />
                                <br />
                                DocRecord includes advanced data capture from documents, advanced workflow 
                                with RPA (robotic process automation) and ETL (extract, transform, load), 
                                eForms, and automated document creation – all based on a powerful content/document 
                                repository system.
                            </Typography>
                            <Button size='small' variant='contained' href='/products/docrecord' sx={{ height: '43px', width: '88px' }}>Learn More</Button>
                        </Grid>
                        <Grid item container justifyContent='center' alignItems='center' xs={6}>
                            <Box>
                              <MediaQuery maxDeviceWidth={1050}>
                                <img src={drECM} style={{ justifyContent: 'center', maxWidth: '420px', borderRadius: '10px' }} />
                              </MediaQuery>
                              <MediaQuery minDeviceWidth={1051} maxDeviceWidth={1150}>
                                <img src={drECM} style={{ justifyContent: 'center', maxWidth: '480px', borderRadius: '10px' }} />
                              </MediaQuery>
                              <MediaQuery minDeviceWidth={1151}>
                                <img src={drECM} style={{ justifyContent: 'center', maxWidth: '500px', borderRadius: '10px' }} />
                              </MediaQuery>
                            </Box>
                        </Grid>
                      
                    </Grid>
                    </TabPanel>
                    <TabPanel value={1}>
                   <Grid container columnSpacing={3} sx={{ marginTop: -12 }}>
            
                        <Grid item container xs={6}>
                            <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: 700, marginBottom: 5 }}>
                                Data {'&'} Document Processing Automation
                            </Typography>
                            <Typography color="text.secondary" marginBottom={3}>
                                Prism WorkPath is an affordable and easy-to-use platform for the rapid creation of 
                                complex work process automations for data and documents that enable organizations 
                                to eliminate their manual processes.
                                <br />
                                <br />
                                Prism WorkPath workflows utilize both RPA (robotic process automation) and ETL 
                                (extract, transform, load) capabilities for automating repetitive tasks. 
                                WorkPath's module - REST API Connector - enables seamless bidirectional 
                                communication between applications.
                            </Typography> 
                            <Button size='small' variant='contained' href='/products/workpath' sx={{ height: '43px', width: '88px' }}>Learn More</Button>
                        </Grid>
                        <Grid item container justifyContent='center' alignItems='center' xs={6}>
                            <Box>
                              <MediaQuery maxDeviceWidth={1050}>
                                <img src={etl} style={{ justifyContent: 'center', maxWidth: '420px', borderRadius: '10px' }} />
                              </MediaQuery>
                              <MediaQuery minDeviceWidth={1051} maxDeviceWidth={1150}>
                                <img src={etl} style={{ justifyContent: 'center', maxWidth: '480px', borderRadius: '10px' }} />
                              </MediaQuery>
                              <MediaQuery minDeviceWidth={1151}>
                                <img src={etl} style={{ justifyContent: 'center', maxWidth: '500px', borderRadius: '10px' }} />
                              </MediaQuery>
                            </Box>
                        </Grid>
                    </Grid>
                    </TabPanel>
                    <TabPanel value={2}>
                    <Grid container columnSpacing={5} sx={{ marginTop: -12 }}>
                        
                        <Grid item container xs={6}>
                            <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: 700, marginBottom: 5 }}>
                                Rapid REST API Data Integrations 
                            </Typography>
                            <Typography color="text.secondary" marginBottom={1}>
                                Prism WorkPath’s REST API Connector is an affordable, no-code 
                                connector that enables the bidirectional data exchange between the 
                                REST APIs of different applications.
                                <br />
                                <br />
                                Easily and quickly enable enterprise application integration and data 
                                exchange between different REST APIs without requiring developer resources. 
                                Achieve faster data integrations with significantly less work and at significantly 
                                less cost.
                            </Typography>
                            <Button size='small' variant='contained' href='/products/workpath-connect' sx={{ height: '43px', width: '88px' }}>Learn More</Button>
                        </Grid>
                        <Grid item container justifyContent='center' alignItems='center' xs={6}>
                            <Box>
                              <MediaQuery maxDeviceWidth={1050}>
                                <img src={integrationsPic} style={{ justifyContent: 'center', maxWidth: '420px', borderRadius: '10px' }} />
                              </MediaQuery>
                              <MediaQuery minDeviceWidth={1051} maxDeviceWidth={1150}>
                                <img src={integrationsPic} style={{ justifyContent: 'center', maxWidth: '480px', borderRadius: '10px' }} />
                              </MediaQuery>
                              <MediaQuery minDeviceWidth={1151}>
                                <img src={integrationsPic} style={{ justifyContent: 'center', maxWidth: '500px', borderRadius: '10px' }} />
                              </MediaQuery>
                            </Box>
                        </Grid>
                        <Grid item container xs={1}>
                            {/*empty grid to create more left/right margins, all grid components add up to 12*/}
                        </Grid>
                    </Grid>
                    </TabPanel>
                    <TabPanel value={3}>
                    <Grid container columnSpacing={5} sx={{ marginTop: -12 }}>
                        
                        <Grid item container xs={6}>
                            <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: 700, marginBottom: 5 }}>
                                Automatically {'&'} Intelligently Extract Data
                            </Typography>
                            <Typography color="text.secondary" marginBottom={1}>
                                Prism Capture an affordable and easy-to-use platform to accurately capture and 
                                extract data from electronic documents. Once extracted, Prism Capture can perform 
                                database lookups for data verification.
                                <br />
                                <br />
                                Eliminate tedious manual data extraction for both line-item and key data. 
                                Automatically route extracted data to line-of-business application, 
                                SharePoint, or other applications. Prism Capture is built for heavy volume 
                                and complex documents.
                            </Typography>
                            <Button size='small' variant='contained' href='/products/capture' sx={{ height: '43px', width: '88px' }}>Learn More</Button>
                        </Grid>
                        <Grid item justifyContent='center' alignItems='center' container xs={6}>
                            <Box>
                             <MediaQuery maxDeviceWidth={1050}>
                                <img src={capturePic} style={{ justifyContent: 'center', maxWidth: '420px', borderRadius: '10px' }} />
                              </MediaQuery>
                              <MediaQuery minDeviceWidth={1051} maxDeviceWidth={1150}>
                                <img src={capturePic} style={{ justifyContent: 'center', maxWidth: '480px', borderRadius: '10px' }} />
                              </MediaQuery>
                              <MediaQuery minDeviceWidth={1151}>
                                <img src={capturePic} style={{ justifyContent: 'center', maxWidth: '500px', borderRadius: '10px' }} />
                              </MediaQuery>
                            </Box>
                        </Grid>
                        
                    </Grid>
                    </TabPanel>
                    </Container>
                </Box>
            </Box>
            </Box>
        </Box>
    </TabsUnstyled>
    </Box>
  );
}

