import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const SupportContact = () => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography
              variant={'h6'}
              fontWeight={700}
              align={'center'}
              gutterBottom
              marginBottom={2.5}
            >
              Corporate Sales Support
            </Typography>
            <Typography align={'center'} marginBottom={2.5}>
              +1 (949) 855-3100
            </Typography>

            <Typography align={'center'}  marginBottom={2.5}>
              <span><a href='mailto:sales@prismsoftware.com' style={{ textDecoration: 'none', justifyContent: 'center' }}>sales@prismsoftware.com</a></span>
            </Typography>

            <Typography align={'center'}  marginBottom={1}>
              Weekly Hours of Operation
            </Typography>

            <Typography align={'center'}>
              Monday - Friday 7am - 5pm
            </Typography>
            
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            borderLeft: {
              xs: 'none',
              md: `1px solid ${theme.palette.divider}`,
            },
          }}
        >
          <Box>
            <Typography
              variant={'h6'}
              fontWeight={700}
              align={'center'}
              gutterBottom
              marginBottom={2.5}
            >
              Technical Support
            </Typography>
            <Typography align={'center'} marginBottom={2.5}>
              +1 (949) 855-3100 Ext. 1
            </Typography>

            <Typography align={'center'}  marginBottom={2.5}>
              <span><a href='mailto:support@prismsoftware.com' style={{ textDecoration: 'none', justifyContent: 'center' }}>support@prismsoftware.com</a></span>
            </Typography>

            <Typography align={'center'}  marginBottom={1}>
              Technical Support Hours
            </Typography>

            <Typography align={'center'}>
              Monday - Friday 6am - 5pm
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SupportContact;
