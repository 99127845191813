import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import {Helmet} from 'react-helmet'; 
import { grey } from '@mui/material/colors';
import video from './Prism-Software-office-ecm-solutions_2560px.png';

const VideoInquiry = () => {
    return (
        <div >
        <Helmet>
            <script
                async type="text/javascript"
                src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=TPaD2m"
            ></script>
        </Helmet>
        <Box
        sx={{ 
            backgroundImage: `url(${video})`, 
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            position: 'relative',
            marginBottom: '-1px'
            }}>
        <Container sx={{ justifyContent: 'center' }}>
                <Grid container sx={{ zIndex: 2,position: 'relative', padding: { xs: '10px', sm: '15px', md: '60px'}, justifyContent: 'center' }}>
                    <Card sx={{ display: 'flex', left: 'auto', right: 'auto', width: '600px', height: '350px', justifyContent: 'center', textAlign: 'center', zIndex: 2, boxShadow: '3px 6px 6px 5px rgba(0,0,0,0.2)', alignItems: 'center' }}>
                    
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" fontWeight={600} fontSize={'32px'} marginBottom={'15px'}>
                            Free Training
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div" marginBottom={'15px'}>
                            Our technical review of DocRecord will help you see how DocRecord is an essential part of any organization.
                            
                        </Typography>
                            <div class="klaviyo-form-VtjaHP"></div>
                        </CardContent>

                    </Box>
                    </Card>
                </Grid>
        </Container>
        </Box>
        </div>
    )
};

export default VideoInquiry;