import React from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from 'components/Container';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from '@mui/material';
import './Automation.css';
import etl from '../../../../images/dr/etl.jpg';
import intelligent from '../../../../images/dr/intelligent.jpg';
import rpa from '../../../../images/dr/rpa.png';
import iso from '../../../../images/dr/isometric-cog.png';
import workpath from '../images/workpath.svg';
import meeting from '../images/meeting.svg';
import integration from '../images/employee-meeting.png';

const mock = [
  {
    media: <img src={workpath}  />,
    title: 'Integrate with Prism WorkPath',
    description: 'Prism WorkPath works seamlessly with ScanPath. WorkPath provides automated document processing at the desktop. From the MFP, you can quickly scan a document directly to that WorkPath process, selected from MFP User Panel, and the scanned document will be sent directly to your desktop where it will be automatically processed by WorkPath.',

  },
  {
    media: <img src={integration} style={{ borderRadius: '20px', width: '360px', alignContent: 'center', justifyContent: 'center' }} />,
    title: 'Automatically scan to custom-built workflows',
    description: 'Custom document workflow processes can be created and published to the MFP panel. These automatic processes eliminate manual document processing. Automate workflows for each department to replace repetitive tasks involved in invoice scanning and processing, purchase orders, expense reports, and more. ',
  },
  {
    media: <img src={meeting} style={{ borderRadius: '20px', alignContent: 'center', justifyContent: 'center' }} />,
    title: 'Interact with third-party applications at your MFP',
    description: `ScanPath's FastLink enables custom links (dedicated scan-to-button) to third-party applications that are designed and published to the MFP user panel. Within these custom scan-to-buttons, data fields and database-driven fields can also be created for User selection. This allows ScanPath to interact with virtually any third-party application that can ingest a data file.`,
  },
];


const Automation = () => {
  const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1100,
      lg: 1200,
      xl: 1536,
    },
  },
});

  const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const sliderOpts = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: ( isMd ? true : false),
    autoplay: true,
    autoplaySpeed: 4000,
  };

    const sliderOptsCopy = {
    dots: true,
    lazyLoad: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div>
    <Container>
    <link href="http://kenwheeler.github.io/slick/slick/slick.css" rel="stylesheet"/>
    <link href="http://kenwheeler.github.io/slick/slick/slick-theme.css" rel="stylesheet"/>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Why organizations choose ScanPath
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color={'text.secondary'}
          data-aos={'fade-up'}
        >
          Experience advanced scanning capabilities and standardize your organization's document processing
        </Typography>
      </Box>
      <ThemeProvider theme={theme}>
      <Box maxWidth={{ xs: 420, sm: 720, md: 1400 }} margin={'0 auto'}>
        <Slider {...sliderOpts}>
          {mock.map((item, i) => (
            <Box key={i} padding={{ xs: 1, md: 2, lg: 3 }}>
              <Box
                display={'block'}
                width={1}
                height={1}
              >
               <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}
                  alignItems={{ xs: 'stretched', sm: 'flex-start', md: 'flex-start', lg: 'flex-start' }}
                  justifyContent={'center'}
                  marginTop={2}
                >
                  <Grid container>
                    <Grid item xs={1} sm={1} md={1}>
                    
                    </Grid>
                    <Grid item container xs={12} sm={12} md={5} justifyContent='center' alignItems='center' marginBottom={{ xs: 8, sm: 8, md: 0 }} >
                      <Box width={0.8} height={0.8}>
                        {item.media}
                      </Box>
                    </Grid>

                    <Grid container  item xs={12} sm={12} md={5}>
                      <Typography variant='h6' fontWeight={600} marginBottom={5} marginTop={2}>
                        {item.title}
                      </Typography>
                      <Typography color="text.secondary">
                        {item.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                    
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
      </ThemeProvider>
    </Container>
    </div>
  );
};

export default Automation;
