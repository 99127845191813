import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const mock = [
{
    title: 'Accessibility Plan',
    description:
      `Prism Software's Accessibility Plan will be reviewed and updated at least once 
      every year and will be posted on the company's website. Upon request, Prism 
      will provide a copy of the Accessibility Plan in an accessible format.`,
  },
  {
    title: 'Training',
    description:
      `Prism will ensure training is provided on the accessibility standards referred 
      to in the Regulations on the Human Rights Code as it pertains to the persons with 
      disabilities. We will keep a record of training provided under this section as outlined 
      by the Regulation.`,
  },
  {
    title: ``,
    description: 
      `The training will be appropriate to the duties of the employees. Employees will be 
      trained when changes are made to the accessibility policy. New employees will be 
      trained as part of the established onboarding practices. Prism will keep a record 
      of the training it provides.`,
  },
  {
    title: 'Communication Standards',
    description:
      `Prism will provide or arrange for the provision of accessible formats and communication 
      supports for persons with disabilities, upon request. We are committed to consulting the 
      person making the request in determining the suitability of an accessible format or 
      communication support.`,
  },
  {
    title: 'Feedback',
    description:
      `Prism will ensure that its process for receiving and responding to feedback is accessible 
      to persons with disabilities by providing, or arranging for the provision of, accessible 
      formats and communications supports, upon request.`,
  },
  {
    title: 'Website and Web Content',
    description:
      `Prism works to ensure that our websites and content conform to the requirements of the 
      Regulation, except where impracticable.`,
  },
  {
    title: 'Employment Standards and Recruitment',
    description:
      `Prism will notify job applicants when they are selected to participate in an assessment to 
      participate further in the selection process that accommodations are available upon request in relation 
      to the materials or processes being used. If the selected applicant requests an accommodation, 
      we will consult with the applicant and arrange for the provision of a suitable accommodation.`,
  },
  {
    title: 'Notice to Successful Applicants',
    description:
     `Prism will notify the successful applicant of its policies for accommodating employees 
     with disabilities.`,
  },
  {
    title: 'Informing Employees of Supports',
    description:
      `Prism will continue to inform its employees of its policies (and any updates to those policies) used 
      to support employees with disabilities, including policies on the provision of job accommodations that 
      take into account an employee's accessibility needs due to disability. This information will be 
      provided to new employees as soon as practicable after commencing employment.`,
  },
  {
    title: 'Workplace Emergency Response Information',
    description:
      `Prism will provide individualized workplace emergency information to employees who have a disability, 
      if the disability is such that the individualized information is necessary, and if Prism is aware 
      of the need for accommodation to the employee's disability.`,
  },
  {
    title: ``,
    description: 
      `Prism will review the individualized workplace emergency response information when the employee moves 
      to a different location in the organization, when the employee's overall accommodations needs or plans 
      are viewed.`,
  },
  {
    title: 'Return to Work Process',
    description:
      `Prism will maintain a documented return to work process for its employees who have been absent from work 
      due to a disability and who require disability related accommodations in order to return to work. The 
      return-to-work process will document the steps Prism will take to facilitate the return to work and will 
      include documented individual accommodation plans as part of the process.`,
  },
  {
    title: 'Performance Management, Career Development and Advancement and Redeployment',
    description:
      `Prism will take into account the accessibility needs of employees with disabilities when using 
      our performance management process.`,
  },
  {
    title: 'Documentation',
    description:
      `This information will be available to the public in accordance with the Regulation, and will be 
      provided, upon request, in a timely manner.`,
  },
];

const PrivacySection = ({ title, description }) => {
  return (
    <Box>
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 600,
        }}
      >
        {title}
      </Typography>
      <Typography component={'p'} color={'text.secondary'}>
        {description}
      </Typography>
    </Box>
  );
};

PrivacySection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Content = () => {
  return (
    <Box>
      <Typography component={'p'} color={'text.secondary'} marginBottom='20px' marginTop='25px'>
        The following policy has been established by Prism Software to strive 
        at all times to commit to the goals and requirements in the 
        <span style={{ fontWeight: 'bold', color: '#575757' }}> Section 508 of the Rehabilitation Act of 1973, as amended (Sec. 508)</span>,
        the <span style={{ fontWeight: 'bold', color: '#575757' }}> Americans with Disabilities Act of 1990, as 
        amended (ADA)</span>, and the <span style={{ fontWeight: 'bold', color: '#575757' }}> Accessibility for 
        Ontarians with Disabilities Act, 2005 (AODA)</span>. We are dedicated to fostering a community 
        that welcomes and is responsive to the needs of those with disabilities.
      </Typography>
      {mock.map((item, i) => (
        <Box key={i} marginBottom={i < mock.length - 1 ? 4 : 0}>
          <PrivacySection {...item} />
        </Box>
      ))}
       <Box>
            <Typography
              variant={'h6'}
              gutterBottom
              sx={{
                fontWeight: 600,
                marginTop: 4
              }}
            >
              Accessibility
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              If anyone has a question about the policy, or if the purpose of the policy is not understood, an explanation 
              will be provided by contacting <a href='mailto:accessibility_policy@prismsoftware.com' style={{ textDecoration: 'none' }}>accessibility_policy@prismsoftware.com</a>
            </Typography>
          </Box>
    </Box>
  );
};

export default Content;
