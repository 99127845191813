import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Container from 'components/Container';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import Routes from '../../../../Routes.js';

const Content = () => {
  // mui xs, sm, md responsiveness
  return (
    
    <Box sx={{ justifyContent: 'center' }}>
    <Container>

    <Typography variant='h5' sx={{ marginBottom: 2, marginTop: 2 }}>
        Prism Software Releases Latest WorkPath Connect; A Low-Cost, No-Code Data 
        Integration Application
      </Typography>

      <Typography sx={{ textTransform: 'uppercase', marginBottom: 2 }}>
        May 26, 2022
      </Typography>

      <Typography variant='div'>
        Enhancements continue Prism WorkPath Connect's leadership role for low-cost RESTful 
        API integrations.
      </Typography>
      
      <Box marginTop={'8px'} marginLeft={'-6px'}>
        <Button variant='text' href='/news/workpath-v4.2' >View Article</Button>
      </Box>

    <Typography variant='h5' sx={{ marginBottom: 2, marginTop: 2 }}>
        Prism Software Releases Latest DocRecord; Affordable All-Inclusive ECM 
        with Advanced Work Process Automation
      </Typography>

      <Typography sx={{ textTransform: 'uppercase', marginBottom: 2 }}>
        May 3, 2022
      </Typography>

      <Typography variant='div'>
        Enhancements continue DocRecord's leadership role for low-cost ECM with intelligent 
        work process automation for midsize organizations.
      </Typography>
      
      <Box marginTop={'8px'} marginLeft={'-6px'}>
        <Button variant='text' href='/news/docrecord-v7.1' >View Article</Button>
      </Box>
  
      <Typography variant='h5' sx={{ marginBottom: 2, marginTop: 6 }}>
        Prism Software Announces the Release of WorkPath Standard v4.1
      </Typography>

      <Typography sx={{ textTransform: 'uppercase', marginBottom: 2 }}>
        November 15, 2021
      </Typography>

      <Typography variant='div'>
        Prism's WorkPath Standard v4.1 introduces new ease-of-use and technical capability enhancements. 
        Among the many additions are changes to database and FTP configurations, pop-up dialogs for easier 
        configurations, and much more.
      </Typography>
      
      <Box marginTop={'8px'} marginLeft={'-6px'}>
        <Button variant='text' href='/news/workpath-standard-v4.1' >View Article</Button>
      </Box>



      <Typography variant='h5' sx={{ marginBottom: 2, marginTop: 6 }}>
        Prism Software Announces the Release of DocRecord v7
      </Typography>

      <Typography sx={{ textTransform: 'uppercase', marginBottom: 2 }}>
        May 10, 2021
      </Typography>

      <Typography variant='div'>
        DocRecord's new version 7 greatly expands the traditional concept of ECM (electronic content management) 
        by introducing powerful new features that enable advanced and comprehensive work process automation.
      </Typography>
      
      <Box marginTop={'8px'} marginLeft={'-6px'}>
        <Button variant='text' href='/news/docrecord-v7' >View Article</Button>
      </Box>


      <Typography variant='h5' sx={{ marginBottom: 2, marginTop: 6 }}>
        Prism Software Announces the Release of WorkPath Connect
      </Typography>

      <Typography sx={{ textTransform: 'uppercase', marginBottom: 2 }}>
        January 11, 2021
      </Typography>

      <Typography variant='div'>
        Prism Software announces the release of WorkPath Connect. It is designed for organizations that need to 
        exchange data easily and seamlessly between different applications without having to utilize developers. 
        Easily pass data between your CRM, ERP, accounting, HR, ECM, shopping, payment, messaging, and other applications.
      </Typography>
      
      <Box marginTop={'8px'} marginLeft={'-6px'}>
        <Button variant='text' href='/news/workpath-connect-release' >View Article</Button>
      </Box>


      <Typography variant='h5' sx={{ marginBottom: 2, marginTop: 6 }}>
        Prism Software Announces the Release of WorkPath Advanced
      </Typography>

      <Typography sx={{ textTransform: 'uppercase', marginBottom: 2 }}>
        January 11, 2021
      </Typography>

      <Typography variant='div'>
        Prism Software announces the release of Prism WorkPath Advanced. It is designed for organizations that have 
        complex data and document work process automation requirements.
      </Typography>
      
      <Box marginTop={'8px'} marginLeft={'-6px'}>
        <Button variant='text' href='/news/workpath-advanced-release' >View Article</Button>
      </Box>


      <Typography variant='h5' sx={{ marginBottom: 2, marginTop: 6 }}>
        Prism Software Announces the Release of WorkPath Standard v4
      </Typography>

      <Typography sx={{ textTransform: 'uppercase', marginBottom: 2 }}>
        August 17, 2020
      </Typography>

      <Typography variant='div'>
        Prism's WorkPath Standard v4 introduces significant new features and enhancements. WorkPath Standard now natively outputs 
        to a wide range of online repositories including SharePoint, Google Drive, OneDrive, Dropbox, Box, and many more.
      </Typography>
      
      <Box marginTop={'8px'} marginLeft={'-6px'}>
        <Button variant='text' href='/news/workpath-standard-v4' >View Article</Button>
      </Box>


      <Typography variant='h5' sx={{ marginBottom: 2, marginTop: 6 }}>
        Prism Software Announces the Release of DocRecord v6.6
      </Typography>

      <Typography sx={{ textTransform: 'uppercase', marginBottom: 2 }}>
        February 10, 2020
      </Typography>

      <Typography variant='div'>
        DocRecord's new version 6.6 introduces numerous features and updates to its powerful all-inclusive 
        ECM platform. The primary focus of v6.6 is continued enhancements to the user web client interface. 
        Other enhancements include numerous feature additions to the eForms and workflow experiences.
      </Typography>
      
      <Box marginTop={'8px'} marginLeft={'-6px'}>
        <Button variant='text' href='/news/docrecord-v6.6' >View Article</Button>
      </Box>


      <Typography variant='h5' sx={{ marginBottom: 2, marginTop: 6 }}>
        bizhub SECURE Alert by Prism Software wins Platinum Award for best MFP Protection
      </Typography>

      <Typography sx={{ textTransform: 'uppercase', marginBottom: 2 }}>
        December 18, 2019
      </Typography>

      <Typography variant='div'>
        bizhub SECURE Alert is the industry’s only low cost, all-inclusive way 
        to notify organizations when they’ve had a security breach at their MFP due 
        to unauthorized usage. It also provides a detailed and keyword searchable 
        archive (audit trail) of all MFP activities. Only Konica Minolta provides 
        this essential MFP document security.
      </Typography>

      <Box marginTop={'8px'} marginLeft={'-6px'}>
        <Button variant='text'
        href='https://finance.yahoo.com/news/konica-minolta-honored-four-2019-151315813.html?guccounter=1&guce_referrer=aHR0cHM6Ly9wcmlzbXNvZnR3YXJlLmNvbS8&guce_referrer_sig=AQAAAITccm1pmsmmf95qCu0kqixIM_C6cabFGeUWZiEJQWZTECYEeX8vX2Hr5CMfEZWnY2f40vnTJwwau2_DCHVY4v6eirzN3fxw6GtVYx31QOs5-BOuhoYJ4kiNrceWdFnBCH6E70a3ypm_q7dhvfl6UQRd3Yguiegn0Lzq_hPqH02t'
        target='_blank'
        style={{ textDecoration: 'none' }} >
          View Article
        </Button>
      </Box>

      <Typography variant='h5' sx={{ marginBottom: 2, marginTop: 6 }}>
        Prism Software's bizhub SECURE Alert wins top MFP security award
      </Typography>

      <Typography sx={{ textTransform: 'uppercase', marginBottom: 2 }}>
        November 3, 2019
      </Typography>

      <Typography variant='div'>
        bizhub SECURE Alert is powered by DocRecord, an application that 
        automatically processes Konica Minolta MFP activity records. This MFP 
        activity record, Image Log Transfer File (ILTF), is an inherent part 
        of nearly all of Konica Minolta MFPs &mdash; both A3 and A4 models. The only 
        remaining MFP security vulnerability that is not addressed by most MFP 
        companies is unauthorized usage at the MFP by an authorized user.
      </Typography>

      <Box marginTop={'8px'} marginLeft={'-6px'}>
        <Button variant='text'
        href='https://americansecuritytoday.com/konica-minolta-scores-four-finalist-spots-in-2019-astors/'
        target='_blank'
        style={{ textDecoration: 'none' }} >
          View Article
        </Button>
      </Box>

      <Typography variant='h5' sx={{ marginBottom: 2, marginTop: 6 }}>
        Prism Software Announces the Release of DocRecord v6.5
      </Typography>

      <Typography sx={{ textTransform: 'uppercase', marginBottom: 2 }}>
        August 12, 2019
      </Typography>

      <Typography variant='div'>
        DocRecord v6.5 introduces an industry-first with advanced document security for Konica Minolta MFP 
        (multifunction printers). MFP document security by DocRecord allows organizations to secure their documents 
        and data by tracking Konica Minolta MFPs usage &mdash; who used, when and for what. Konica Minolta has 
        separately launched this capability under their product name bizhub SECURE Alert.
      </Typography>
      
      <Box marginTop={'8px'} marginLeft={'-6px'}>
        <Button variant='text' href='/news/docrecord-v6.5' >View Article</Button>
      </Box>

    </Container>      
    </Box>
  );
};

export default Content;
