import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'
import Container from 'components/Container';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import capture from '../../svg/prism/landing-capture-1.svg';
import rpa from '../../svg/prism/landing-rpa-icon-1.svg';
import integrations from '../../svg/prism/landing-integrations-icon-1.svg';
import ecm from '../../svg/prism/landing-ecm-icon-1.svg';
import MediaQuery from 'react-responsive';
import Routes from '../../Routes.js';

function MediaSolutions() {
    return (
        <Box>
        <MediaQuery maxDeviceWidth={639}>
            <Typography variant='h4' sx={{ fontWeight: 600, textAlign: 'center', marginBottom: 2, marginTop: 5, fontSize: '20px' }}>
            Solutions for improving how you work {'&'} lowering your costs
        </Typography>
        <Container>
        
            <Grid container columnSpacing={5} rowSpacing={5}>
           
                <Grid item container xs={12} justifyContent='center' >
                <Card
                    data-aos={'fade-up'}
                    data-aos-delay={0 * 100}
                    data-aos-offset={100}
                    data-aos-duration={600}
                    flexDirection={'column'}
                    
                    sx={{ boxShadow: '1px 1px 9px 5px #f2f2f2', width: '325px', height: '290px' }}
                >
                    <CardContent
                    sx={{
                        cursor: "pointer"
                    }}
                    >
                        <Grid container rowSpacing={6}>
                        <Grid item container>
                            <img src={ecm} style={{ maxWidth: '80px', marginTop: 10 }}/>
                        </Grid>
                        <Grid item container>
                            <Typography sx={{ fontSize: '20px', fontWeight: 600, textAlign: 'left' }}>
                                ECM with Work Process Automation
                            </Typography>
                            <Button variant='text' size="small" href='/products/docrecord' endIcon={<ArrowForwardIcon />} sx={{ marginLeft: -0.7, marginTop: 1 }}>Learn more about Prism DocRecord</Button>
                        </Grid>
                    </Grid>
                    </CardContent>
                </Card>
                </Grid>
                <Grid item container xs={12} justifyContent='center' >
                <Card
                    data-aos={'fade-up'}
                    data-aos-delay={0 * 100}
                    data-aos-offset={100}
                    data-aos-duration={600}
                    flexDirection={'column'}
                    
                    sx={{ boxShadow: '1px 1px 9px 5px #f2f2f2', width: '325px', height: '290px' }}
                >
                    <CardContent
                    sx={{
                        cursor: "pointer"
                    }}
                    >
                        <Grid container rowSpacing={6}>
                            <Grid item container>
                                <img src={rpa} style={{ maxWidth: '80px', marginTop: 10 }}/>
                            </Grid>
                            <Grid item container>
                                <Typography sx={{ fontSize: '20px', fontWeight: 600, textAlign: 'left' }}>
                                    Advanced Work Process Automation with RPA {'&'} ETL
                                </Typography>
                                <Button variant='text' size="small" href='/products/workpath' endIcon={<ArrowForwardIcon />} sx={{ marginLeft: -0.7, marginTop: 1 }}>Learn more about Prism WorkPath</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                </Grid>
         
                <Grid item container xs={12} justifyContent='center'>
                <Card
                    data-aos={'fade-up'}
                    data-aos-delay={0 * 100}
                    data-aos-offset={100}
                    data-aos-duration={600}
                    flexDirection={'column'}
                    
                    sx={{ boxShadow: '1px 1px 9px 5px #f2f2f2', width: '325px', height: '290px' }}
                >
                    <CardContent
                    sx={{
                        cursor: "pointer"
                    }}
                    >
                        <Grid container rowSpacing={6}>
                        <Grid item container>
                            <img src={integrations} style={{ maxWidth: '80px', marginTop: 10 }}/>
                        </Grid>
                        <Grid item container>
                            <Typography sx={{ fontSize: '20px', fontWeight: 600, textAlign: 'left' }}>
                                Intelligent Integrations for Data {'&'} Applications
                            </Typography>
                            <Button variant='text' size="small" href='/products/workpath-connect' endIcon={<ArrowForwardIcon />} sx={{ marginLeft: -0.7, marginTop: 1 }}>Learn more about Prism WorkPath Connect</Button>
                        </Grid>
                    </Grid>
                    </CardContent>
                </Card>
                </Grid>
                <Grid item container xs={12} justifyContent='center'>
                <Card
                    data-aos={'fade-up'}
                    data-aos-delay={0 * 100}
                    data-aos-offset={100}
                    data-aos-duration={600}
                    flexDirection={'column'}
                    
                    sx={{ boxShadow: '1px 1px 9px 5px #f2f2f2', width: '325px', height: '290px' }}
                >
                    <CardContent
                    sx={{
                        cursor: "pointer"
                    }}
                    >
                        <Grid container rowSpacing={6}>
                        <Grid item container>
                            <img src={capture} style={{ maxWidth: '80px', marginTop: 10 }}/>
                        </Grid>
                        <Grid item container>
                            <Typography sx={{ fontSize: '20px', fontWeight: 600, textAlign: 'left' }}>
                                Advanced Data Capture, Extraction {'&'} Automation
                            </Typography>
                            <Button variant='text' size="small" href='/products/capture' endIcon={<ArrowForwardIcon />} sx={{ marginLeft: -0.7, marginTop: 1 }}>Learn more about Prism Capture</Button>
                        </Grid>
                    </Grid>
                    </CardContent>
                </Card>
                </Grid>
              
            </Grid>
            </Container>
        </MediaQuery>
        <MediaQuery minDeviceWidth={640} maxDeviceWidth={1200}>
        <Typography variant='h4' sx={{ fontWeight: 600, textAlign: 'center', marginBottom: 2, marginTop: 5, fontSize: '20px' }}>
            Solutions for improving how you work {'&'} lowering your costs
        </Typography>
        <Container>
        
            <Grid container columnSpacing={5} rowSpacing={5}>
           
                <Grid item container xs={6} justifyContent='right' >
                <Card
                    data-aos={'fade-up'}
                    data-aos-delay={0 * 100}
                    data-aos-offset={100}
                    data-aos-duration={600}
                    flexDirection={'column'}
                    
                    sx={{ boxShadow: '1px 1px 9px 5px #f2f2f2', width: '325px', height: '290px' }}
                >
                    <CardContent
                    sx={{
                        cursor: "pointer"
                    }}
                    >
                        <Grid container rowSpacing={6}>
                        <Grid item container>
                            <img src={ecm} style={{ maxWidth: '80px', marginTop: 10 }}/>
                        </Grid>
                        <Grid item container>
                            <Typography sx={{ fontSize: '20px', fontWeight: 600, textAlign: 'left' }}>
                                ECM with Work Process Automation
                            </Typography>
                            <Button variant='text' size="small" href='/products/docrecord' endIcon={<ArrowForwardIcon />} sx={{ marginLeft: -0.7, marginTop: 1 }}>Learn more about Prism DocRecord</Button>
                        </Grid>
                    </Grid>
                    </CardContent>
                </Card>
                </Grid>
                <Grid item container xs={6} justifyContent='left' >
                <Card
                    data-aos={'fade-up'}
                    data-aos-delay={0 * 100}
                    data-aos-offset={100}
                    data-aos-duration={600}
                    flexDirection={'column'}
                    
                    sx={{ boxShadow: '1px 1px 9px 5px #f2f2f2', width: '325px', height: '290px' }}
                >
                    <CardContent
                    sx={{
                        cursor: "pointer"
                    }}
                    >
                        <Grid container rowSpacing={6}>
                            <Grid item container>
                                <img src={rpa} style={{ maxWidth: '80px', marginTop: 10 }}/>
                            </Grid>
                            <Grid item container>
                                <Typography sx={{ fontSize: '20px', fontWeight: 600, textAlign: 'left' }}>
                                    Advanced Work Process Automation with RPA {'&'} ETL
                                </Typography>
                                <Button variant='text' size="small" href='/products/workpath' endIcon={<ArrowForwardIcon />} sx={{ marginLeft: -0.7, marginTop: 1 }}>Learn more about Prism WorkPath</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                </Grid>
         
                <Grid item container xs={6} justifyContent='right'>
                <Card
                    data-aos={'fade-up'}
                    data-aos-delay={0 * 100}
                    data-aos-offset={100}
                    data-aos-duration={600}
                    flexDirection={'column'}
                    
                    sx={{ boxShadow: '1px 1px 9px 5px #f2f2f2', width: '325px', height: '290px' }}
                >
                    <CardContent
                    sx={{
                        cursor: "pointer"
                    }}
                    >
                        <Grid container rowSpacing={6}>
                        <Grid item container>
                            <img src={integrations} style={{ maxWidth: '80px', marginTop: 10 }}/>
                        </Grid>
                        <Grid item container>
                            <Typography sx={{ fontSize: '20px', fontWeight: 600, textAlign: 'left' }}>
                                Intelligent Integrations for Data {'&'} Applications
                            </Typography>
                            <Button variant='text' size="small" href='/products/workpath-connect' endIcon={<ArrowForwardIcon />} sx={{ marginLeft: -0.7, marginTop: 1 }}>Learn more about Prism WorkPath Connect</Button>
                        </Grid>
                    </Grid>
                    </CardContent>
                </Card>
                </Grid>
                <Grid item container xs={6} justifyContent='left'>
                <Card
                    data-aos={'fade-up'}
                    data-aos-delay={0 * 100}
                    data-aos-offset={100}
                    data-aos-duration={600}
                    flexDirection={'column'}
                    
                    sx={{ boxShadow: '1px 1px 9px 5px #f2f2f2', width: '325px', height: '290px' }}
                >
                    <CardContent
                    sx={{
                        cursor: "pointer"
                    }}
                    >
                        <Grid container rowSpacing={6}>
                        <Grid item container>
                            <img src={capture} style={{ maxWidth: '80px', marginTop: 10 }}/>
                        </Grid>
                        <Grid item container>
                            <Typography sx={{ fontSize: '20px', fontWeight: 600, textAlign: 'left' }}>
                                Advanced Data Capture, Extraction {'&'} Automation
                            </Typography>
                            <Button variant='text' size="small" href='/products/capture' endIcon={<ArrowForwardIcon />} sx={{ marginLeft: -0.7, marginTop: 1 }}>Learn more about Prism Capture</Button>
                        </Grid>
                    </Grid>
                    </CardContent>
                </Card>
                </Grid>
              
            </Grid>
            </Container>
            </MediaQuery>
        </Box>  
    )
}

export default MediaSolutions
