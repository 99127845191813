import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import capture from '../../../../images/capture.png'
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { grey, blue } from '@mui/material/colors';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import img1 from '../../../../images/workpath/wpcData.png'


const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[500]),
  backgroundColor: grey[50],
  '&:hover': {
    backgroundColor: grey[500],
  },
}));

const Connections = () => {
   const theme = useTheme()
    return (
        <Grid
        container 
        spacing={2}
        position={'relative'}
        sx={{
            backgroundColor: theme.palette.alternate.main,}}>

                <Grid 
                    container 
                    spacing={2}
                    position={'relative'}
                  
                >

                    <Grid item md={5}                     sx={{backgroundColor:'white'}}
>
                        <Container sx={{ justifyContent: 'center', textAlignVertical: 'center', marginLeft: '50px' }}>
                        <h4 style={{ fontWeight: 800 }}>Integrate with Cloud Storage</h4>
                        <p>
                            Prism WorkPath is already deeply integrated with many cloud storage providers making it easy to process data and documents and then automatically upload the results to your organization's provider such as SharePoint, OneDrive, Google Drive, Box, and Dropbox.
                        </p>
                        </Container>
                    </Grid>

                    <Grid item md={7} maxWidth='600px'>
                    <Container sx={{ justifyContent: 'center', textAlign: 'center' }}>
                        {/* <img style={{ maxWidth: '600px', left: 'auto', right: 'auto', textAlign: 'center' }} src={img1} /> */}
                    </Container>
                    </Grid>
                </Grid>

               

        </Grid>
    )
};

export default Connections;
