import React from 'react';
import { useTheme } from '@mui/material/styles';
import Typed from 'react-typed';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MediaQuery from 'react-responsive';

const Capabilities = () => {
    const theme = useTheme();
    return (
      <Box>
        <Box
              justifyContent='center'
              display={'block'}
              sx={{
                marginTop: '20px',
                marginBottom: '40px',
                marginLeft: 'auto',
                marginRight: 'auto',
                textDecoration: 'none',
                boxShadow: '3px 8px 8px 3px rgba(0,0,0,0.2)',
                borderRadius: '10px'
              }}
            >

        <Card
                justifyContent='center'
                data-aos={'fade-up'}
                data-aos-delay={1 * 100}
                data-aos-duration={600}
                flexDirection={'column'}
                display={'flex'}
              >
        
          <Box>
          <CardContent >
          
            <Box padding={4} marginBottom={2}>
              <Typography variant={'h5'} sx={{ fontWeight: 700, textAlign: 'center', marginBottom: '30px' }} gutterBottom>
                All the essential features of ScanPath
              </Typography>
                
            
            <Grid container spacing={1}>
              {[
                'Convert to PDF & PDF/A with full-text searchable OCR',
                'Convert to Word, Excel, & PowerPoint',
                'Scan-to-Folders',
                'Scan-to-Email (Integrates with Microsoft Exchange)',
                'Image clean up',
                'Individual multifunction printer profiles',
                'OCR for over 25 languages',
                'Localized interface in multiple languages',
                'Scan-to-Web Repositories',
                'Workflow and Zonal OCR',
                'FollowPrint and secure release printing',
                'Bates Stamping',
                'Scan-to-Barcode cover sheet',
                'FastLink for third-party software integration'
              ].map((item, i) => (
                <Grid item xs={12} sm={6} md={6} lg={6} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText 
                        disableTypography
                        primary={<Typography color='text.secondary' style={{ fontSize: 16 }}>{item}</Typography>} />
                  </Box>
                </Grid>
              ))}
            </Grid>
            </Box>
            </CardContent>
          </Box>

        </Card>
        </Box>
      </Box>
    )
};

export default Capabilities;