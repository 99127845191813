import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Meat from './components/Transform';
import Platform from './components/Platform';
import Features from './components/Uses';


const BenefitsDR = () => {
  const theme = useTheme();
  return (
    <Box>
        <Meat />
        <Platform />
        <Features />
    </Box>
  );
};

export default BenefitsDR;
