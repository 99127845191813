import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Helmet} from 'react-helmet'; 
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Content, Contact, ContactTemp } from './components';

const ScheduleDemo = () => {
  const theme = useTheme();

  return (
    <Main>
      <div>
        <Helmet>
            <script
                async type="text/javascript"
                src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=TPaD2m"
            ></script>
        </Helmet>
        <Box marginTop={{md: 1.8}}>
          <Box bgcolor={theme.palette.primary.main}>
            <Container paddingX={{ xs: 2, sm: 4 }}>
              <Typography
                variant={'h4'}
                gutterBottom
                sx={{
                  fontWeight: 700,
                  color: theme.palette.common.white,
                }}
              >
                We're here for you
              </Typography>
              <Typography
                gutterBottom
                sx={{  
                  color: theme.palette.common.white,
                }}
              >
                See how Prism Software can automate your organization.
              </Typography>
            </Container>
            <Box
              component={'svg'}
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
              width={1}
              marginBottom={-1}
            >
              <path
                fill={theme.palette.background.paper}
                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
              ></path>
            </Box>
          </Box>
          <Container
            paddingTop={'0 !important'}
            paddingX={{ xs: 5, sm: 4 }}
            position={'relative'}
            top={0}
          >
            <Box
              component={Grid}
              container
              spacing={1}
              flexDirection={{ xs: 'column', md: 'row' }}
            >

              {/* LEFT SIDE */}
              <Grid item 
                maxWidth={{sm: '800px', md: '21%', lg: '460px'}}
              >
                <Content />
              </Grid>

              {/* RIGHT SIDE */}
              <Grid item 
                marginTop={'50px'} 
                maxWidth={{sm: '800px', md: '79%', lg: '665px'}}
                // backgroundColor={{xs: 'pink', sm: 'red', md: 'blue', lg: 'green'}}
              >
                <ContactTemp />
              </Grid>

            </Box>
          </Container>
        </Box>
      </div>
    </Main>
  );
};

export default ScheduleDemo;
