import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Container from 'components/Container';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import Routes from '../../../../Routes.js';

const Content = () => {
  // mui xs, sm, md responsiveness
  return (
    <Box sx={{ justifyContent: 'center' }}>
    <Container>
      <Typography variant='h4' sx={{ textAlign: 'center', marginBottom: 3 }}>
        Transform your organization
      </Typography>

      <Typography color='text.secondary' sx={{ textAlign: 'center', marginBottom: 5 }}>
        We empower organizations to do more. Prism's solutions are used by leading 
        organizations worldwide. Our applications are essential for industries such as 
        healthcare, government, financial, insurance, defense, education, and corporations.
      </Typography>
      <Grid container spacing={5} >
      
        <Grid item container md={4} sm={6} xs={12} spacing={2} justifyContent='center' >
          <Grid item container justifyContent='center' >
            <Typography variant='h5' sx={{ textAlign: 'center', color: '#1976D2', fontWeight: 600 }}>
              DocRecord
            </Typography>
          </Grid>
          <Grid item container sx={{ maxWidth: '180px' }}>
            <Typography variant='div' sx={{ textAlign: 'center', fontWeight: 600 }}>
              ECM with Work Process Automation
            </Typography>
          </Grid>
          <Grid item container justifyContent='center' >
            <Button variant='outlined' href='/solutions/docrecord'>
              Learn More
            </Button>
          </Grid>
        </Grid>

        <Grid item container md={4} sm={6} xs={12} spacing={2} justifyContent='center'>
          <Grid item container justifyContent='center' >
            <Typography variant='h5' sx={{ textAlign: 'center', color: '#1976D2', fontWeight: 600 }}>
              Capture
            </Typography>
          </Grid>
          <Grid item container justifyContent='center' sx={{ width: '180px' }}>
            <Typography variant='div' sx={{ textAlign: 'center', fontWeight: 600 }}>
              Automated document capture and extraction
            </Typography>
          </Grid>
          <Grid item container justifyContent='center'>
            <Button variant='outlined' href='/solutions/capture'>
              Learn More
            </Button>
          </Grid>
        </Grid>

        <Grid item container md={4} sm={6} xs={12} spacing={2} justifyContent='center'>
          <Grid item container justifyContent='center'>
            <Typography variant='h5' sx={{ textAlign: 'center', color: '#1976D2', fontWeight: 600 }}>
              WorkPath
            </Typography>
          </Grid>
          <Grid item container sx={{ width: '180px' }}>
            <Typography variant='div' sx={{ textAlign: 'center', fontWeight: 600 }}>
              Work process automation
            </Typography>
          </Grid>
          <Grid item container justifyContent='center'>
            <Button variant='outlined' href='/solutions/workpath'>
              Learn More
            </Button>
          </Grid>
        </Grid>

        <Grid item container md={4} sm={6} xs={12} spacing={2} justifyContent='center'>
          <Grid item container justifyContent='center'>
            <Typography variant='h5' sx={{ textAlign: 'center', color: '#1976D2', fontWeight: 600 }}>
              ScanPath
            </Typography>
          </Grid>
          <Grid item container sx={{ width: '180px' }}>
            <Typography variant='div' sx={{ textAlign: 'center', fontWeight: 600 }}>
              Automated processing of scanned documents
            </Typography>
          </Grid>
          <Grid item container justifyContent='center'>
            <Button variant='outlined' href='/solutions/scanpath'>
              Learn More
            </Button>
          </Grid>
        </Grid>

        <Grid item container md={4} sm={6} xs={12} spacing={2} justifyContent='center'>
          <Grid item container justifyContent='center'>
            <Typography variant='h5' sx={{ textAlign: 'center', color: '#1976D2', fontWeight: 600 }}>
              WorkPath Connect
            </Typography>
          </Grid>
          <Grid item container sx={{ width: '180px' }}>
            <Typography variant='div' sx={{ textAlign: 'center', fontWeight: 600 }}>
              RESTful API integration
            </Typography>
          </Grid>
          <Grid item container justifyContent='center'>
            <Button variant='outlined' href='/solutions/workpath-connect'>
              Learn More
            </Button>
          </Grid>
        </Grid>

        <Grid item container md={4} sm={6} xs={12} spacing={2} justifyContent='center'>
          <Grid item container justifyContent='center'>
            <Typography variant='h5' sx={{ textAlign: 'center', color: '#1976D2', fontWeight: 600 }}>
              DocForm
            </Typography>
          </Grid>
          <Grid item container sx={{ width: '180px' }}>
            <Typography variant='div' sx={{ textAlign: 'center', fontWeight: 600 }}>
              Automated business communications
            </Typography>
          </Grid>
          <Grid item container justifyContent='center'>
            <Button variant='outlined' href='/solutions/docform'>
              Learn More
            </Button>
          </Grid>
        </Grid>

        <Grid item container md={4} sm={6} xs={12} spacing={2} justifyContent='center'>
          <Grid item container justifyContent='center'>
            <Typography variant='h5' sx={{ textAlign: 'center', color: '#1976D2', fontWeight: 600 }}>
              bizhub SECURE Alert
            </Typography>
          </Grid>
          <Grid item container sx={{ width: '180px' }} justifyContent='center'>
            <Typography variant='div' sx={{ textAlign: 'center', fontWeight: 600 }}>
              MFP security and breach alerts
            </Typography>
          </Grid>
          <Grid item container justifyContent='center'>
            <Button variant='outlined' href='/solutions/bizhub-secure-alert'>
              Learn More
            </Button>
          </Grid>
        </Grid>

        <Grid item container md={4} sm={6} xs={12} spacing={2} justifyContent='center'>
          <Grid item container justifyContent='center'>
            <Typography variant='h5' sx={{ textAlign: 'center', color: '#1976D2', fontWeight: 600 }}>
              Smartphone Forms
            </Typography>
          </Grid>
          <Grid item container justifyContent='center' sx={{ width: '180px' }}>
            <Typography variant='div' sx={{ textAlign: 'center', fontWeight: 600 }}>
              Smartphone forms with eSignatures
            </Typography>
          </Grid>
          <Grid item container justifyContent='center'>
            <Button variant='outlined' href='solutions/smartphone-forms'>
              Learn More
            </Button>
          </Grid>
        </Grid>

        <Grid item container md={4} sm={6} xs={12} spacing={2} justifyContent='center'>
          <Grid item container justifyContent='center'>
            <Typography variant='h5' sx={{ textAlign: 'center', color: '#1976D2', fontWeight: 600 }}>
              eForms
            </Typography>
          </Grid>
          <Grid item container sx={{ width: '180px' }} justifyContent='center'>
            <Typography variant='div' sx={{ textAlign: 'center', fontWeight: 600 }}>
              eForms and data collection
            </Typography>
          </Grid>
          <Grid item container justifyContent='center'>
            <Button variant='outlined' href='/solutions/eforms'>
              Learn More
            </Button>
          </Grid>
        </Grid>

      </Grid> 
    </Container>      
    </Box>
  );
};

export default Content;
