import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Container from 'components/Container';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';

const mock = [
  {
    title: 'Automate',
    subtitle:
      `Automate all types and complexities of work processes. It is designed to eliminate an organization's repetitive manual processes.`,
    icon: (
      <CheckCircleIcon fontSize='40px' />
    ),
  },
  {
    title: 'Simplify',
    subtitle:
      'Easily customize using an intuitive design interface for a wide range of requirements to automate data and documents.',
    icon: (
      <SettingsIcon fontSize='large' />
    ),
  },
  {
    title: 'Empower',
    subtitle:
      `Enable more efficient data and document processing in a way that's familiar and acceptable to your team.`,
    icon: (
      <BatteryChargingFullIcon fontSize='large' />
    ),
  },

];

const Advantages = () => {
  const theme = useTheme();

  return (
    <Box>
    
    <Container>
    <Card>
    <Container>
      <Box marginBottom={8}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
          align={'center'}
        >
          Enables the automation of complex work processes
        </Typography>
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Prism WorkPath
        </Typography>
      
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12} md={4}>
            <ListItem
              component="div"
              disableGutters
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                padding: 0,
              }}
            >
              <Box
                component={ListItemAvatar}
                marginBottom={1}
                minWidth={'auto !important'}
              >
                <Box color={theme.palette.primary.main}>{item.icon}</Box>
              </Box>
              <ListItemText
                primary={item.title}
                secondary={item.subtitle}
                primaryTypographyProps={{
                  variant: 'h6',
                  gutterBottom: true,
                  align: 'center',
                }}
                secondaryTypographyProps={{ align: 'center' }}
                sx={{
                  '& .MuiListItemText-primary': {
                    fontWeight: 700,
                  },
                  margin: 0,
                }}
              />
            </ListItem>
          </Grid>
        ))}
      </Grid>
                <CardActions sx={{ justifyContent: 'center', marginTop: 8 }}>
       
                    <Button size={'large'} variant={'contained'}>
                      Schedule a demo
                    </Button>
         
                </CardActions>
      </Container>
      </Card>
      </Container>
    </Box>
  );
};

export default Advantages;
