import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Helmet} from 'react-helmet'; 
import MediaQuery from 'react-responsive';
import {
  Hero,
} from './components';
import Categories from './components/NewComponents/Categories';
import Services from './Services';
import Contact from './Contact';
import InterestForm from './InterestForm'
import Solutions from './Solutions';
import MediaSolutions from './MediaSolutions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CommonProblems } from './components'; 
import XsProblems from './components/xsProblems.js';
import SmProblems from './components/smProblems.js';
import TempTabs from './components/tempTabs.js';
import ImprovementMob from './components/ImprovementMob.js'
import Improvement from './components/Improvement.js'
import Video from './components/Video.js'
import Organization from './components/Organization.js';

const HomePage = () => {
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main sx={{ overflow: 'hidden' }} >
      <Helmet>
        <script
            async type="text/javascript"
            src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=TPaD2m"
        ></script>
      </Helmet>
      <Box sx={{ overflow: 'hidden' }}>

          <Hero />
          <Box sx={{
            backgroundColor: theme.palette.alternate.main,
          }}
          >
            <Container display='flex' justifyContent='center' >
              {isMd ? <Improvement /> : <ImprovementMob />}
            </Container>
          </Box>
          <Container display='flex' justifyContent='center'>
            {isMd ? <CommonProblems /> : <TempTabs />}
          </Container>
          <Box
          position={'relative'}
          sx={{
            backgroundColor: theme.palette.alternate.main,
          }}
          >
            <Container>
              <Video />
            </Container>
            <Box
              component={'svg'}
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
              sx={{
                width: '100%',
                marginBottom: theme.spacing(-1),
              }}
            >
              <path
                fill={theme.palette.background.paper}
                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
              ></path>
            </Box>
          </Box>
          <Container display='flex' justifyContent='center'>
            <Organization />
          </Container>


          
{/*<MediaQuery minDeviceWidth={1200}>
      <Solutions style={{ marginTop: '50px', marginBottom: '50px'}} />
</MediaQuery>
      <MediaSolutions style={{ marginTop: '50px', marginBottom: '50px'}} />
        <Box
        position={'relative'}
        sx={{
          backgroundColor: theme.palette.alternate.main,
        }}
        >
          <Container>
            <Categories />
          </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: '100%',
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
      
      </Box>
      <Container style={{ justifyContent: 'center', marginTop: '-30px' }}>
            <Contact style={{ justifyContent: 'center' }} />
      </Container>

        {/******************** TODO POLYGON CALL TO ACTION *******************/}
        {/* <Box bgcolor='#158cc3' position={'relative'} marginBottom={5}>

        <Col >
        <Row >
        <div  style={{ marginLeft: '20%', marginTop: '90px', maxWidth: '60%', marginBottom: 50 }}>
          <Typography>
            <h3 style={{ color: '#fff', fontWeight: 600, marginBottom: 20 }}>Let us show you DocRecord</h3>
            <p style={{ color: '#fff', marginBottom: 50 }}>See how DocRecord can make your work easier</p>
          </Typography>

          <Button
            variant="contained"
            component="a"
            target="blank"
            href="http://prismsoftware.com/contact-us/"
            size="large"
            style={{ textAlign: 'center', justifyContent: 'center', backgroundColor: '#fff', color: '#1976d2' }}
            >
                Schedule a demo
            </Button>
        </div>
        </Row>
        <Row marginTop={20}>
            <Card
                sx={{
                  backgroundImage: 'none',
                  boxShadow: 4,
                  maxWidth: '60%',
                  bottom: '66px',
                  marginLeft: '20%',
                  marginBottom: '10%'
                }}
              >
                <CardContent>
                  <div class="klaviyo-form-TkMhwr"></div>
                </CardContent>
              </Card>

          </Row>
          <Container >
          <Box
            component={'svg'}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
            sx={{
              position: 'absolute',
              width: '100%',
              height: '20%',
              left: 15,
              bottom: -1,
              right: 0,
              zIndex: 1,
            }}
          >
            <polygon
              fill={theme.palette.background.paper}
              points="0,273 1921,273 1921,0 "
            />
          </Box>
          </Container>
          </Col>
        </Box> */}
        {/***********8** END CALL TO ACTION **************/}
        </Box>
      <InterestForm />

    </Main>
  );
};


export default HomePage;
