import React from 'react';
import Typed from 'react-typed';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import homeConnect from '../../../images/homepage/homeConnect.png';

const second = createTheme({
        components: {
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontFamily: 'Lato, sans-serif',
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontFamily: 'Lato, sans-serif',
                        fontSize: '15px',
                        borderRadius: '10px',
                        textTransform: 'none',
                        fontWeight: 'bold'
                    }
                }
            }
        },
        breakpoints: {
            values: {
            xs: 0,
            sm: 620,
            md: 1000,
            },
        },
    })

const Organization = () => {
    const theme = useTheme();

    
    return (
        <Box marginTop={-2}  sx={{ width: 0, flexGrow: 1, flex: 1, overflowX: 'hidden' }}>
        <ThemeProvider theme={second} >
        <Grid container
          data-aos={'fade-up'} direction='row' spacing={5}
        > 
          
          <Grid item container md={5} sm={8} justifyContent='center' alignItems='center' marginBottom={{ xs: -5, sm: -5, md: 0 }} marginLeft={{ xs: 0, sm: '20%', md: 0 }} marginRight={{ xs: 0, sm: '20%', md: 0 }}>
            <img src={homeConnect} style={{ maxWidth: '100%', borderRadius: '25px' }} />
          </Grid>
   
          <Grid item container md={7} sm={12} className='mt-5' >
          <Box justifyContent={'center'} marginLeft={{ xs: '10%', sm: '5%', md: 0 }} marginRight={{ xs: '10%', sm: '5%', md: 0 }} >
            <Box marginBottom={2}  justifyContent={'center'}>
              <Typography variant={'h4'}  sx={{ fontWeight: 700 }} gutterBottom>
                Connect to Your Organization's Applications
                </Typography>
  
              <Typography color="text.secondary" >
                Expand DocRecord's work process capabilities so it seamlessly sends data to your other applications such as accounting, CRM, ERP, Marketing {'&'} more.
              </Typography>
            </Box>
            <Grid container spacing={1}>
              {[
                "DocRecord can seamlessly send data in real time to your other applications through Prism's WorkPath Connect — whether in the cloud or on premise.",
                "The WorkPath Connect, a module of WorkPath, is a low cost and low-code application. It connects different applications' RESTful APIs for easy and accurate bidirectional data exchange - without the efforts of a software developer",
                'The WorkPath Connect enables all organizations in all industries to easily exchange data with their other line-of-business applications.',
              ].map((item, i) => (
                <Grid item xs={12} sm={12} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText primary={item} />
                  </Box>
                </Grid>
              ))}
              <Button
              variant="contained"
              color="primary"
              component="a"
              target="blank"
              href="/solutions/workpath-connect"
              size="medium"
              sx={{ textAlign: 'center', marginTop: '30px', display: { xs: 'none', sm: 'block' }}}
              >
               Learn More
            </Button>
            </Grid>
            
          </Box>
          </Grid>
        </Grid>
        </ThemeProvider>
        </Box>
    )
};

export default Organization;