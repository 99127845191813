import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Helmet} from 'react-helmet'; 
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Categories, Contact, SupportContact } from './components';

const About = () => {
  const theme = useTheme();

  return (
    <Main>
      <div>
        <Helmet>
            <script
                async type="text/javascript"
                src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=TPaD2m"
            ></script>
        </Helmet>
        <Box marginTop={1.8}>
          <Box bgcolor={theme.palette.primary.main}>
            <Container paddingX={{ xs: 2, sm: 4 }}>
              <Typography
                variant={'h4'}
                gutterBottom
                sx={{
                  fontWeight: 700,
                  color: theme.palette.common.white,
                }}
              >
                Browse our resources
              </Typography>
              <Typography
                gutterBottom
                sx={{  
                  color: theme.palette.common.white,
                }}
              >
                Take advantage of all the resources that Prism has to offer to <br/>
                maximize the value of your Prism Software solutions.
              </Typography>
            </Container>
            <Box
              component={'svg'}
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
              width={1}
              marginBottom={-1}
            >
              <path
                fill={theme.palette.background.paper}
                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
              ></path>
            </Box>
          </Box>
            <Container  marginBottom={5}>
                <Categories />
            </Container>

            <Container  marginBottom={8}>
                <SupportContact />
            </Container>
          <Box
                position={'relative'}
                sx={{
                  backgroundColor: theme.palette.alternate.main,
                }}
                >
                <Container>
                   <Contact />
                </Container>
                <Box
                  component={'svg'}
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 1920 100.1"
                  sx={{
                    width: '100%',
                    marginBottom: theme.spacing(0),
                  }}
                >
                  <path
                    fill={theme.palette.background.paper}
                    d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                  ></path>
                </Box>
              </Box>
        </Box>
      </div>
    </Main>
  );
};

export default About;
