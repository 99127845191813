import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import drc from '../../../../../images/drFeatures/DocRecord-Connect-Organization.jpg';
import Container from 'components/Container';
import MediaQuery from 'react-responsive';
import Routes from '../../../../../Routes.js'

const Integration = () => {
    const theme = useTheme()
    return (
        <Box>
        <Container>
        <Grid 
            container 
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="center">
            <Grid item container alignItems={'center'} xs={12} sm={12} md={6}>
            <Box>
                <Box marginBottom={2}>
                <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
                    Get seamless integration for your organization's application
                </Typography>
                <Typography color="text.secondary">
                    Expand DocRecord's work processes capabilities so it seamlessly sends data to your other applications such as accounting, CRM, ERP, Marketing {'&'} more.
                </Typography>
                </Box>
                <Grid container spacing={1}>
                {[
                    `DocRecord can seamlessly send data in real time to your other applications through 
                    Prism's WorkPath Connect - whether in the cloud or on premise.`,
                    `The WorkPath Connect, a module of WorkPath, is a low cost and low-code application. 
                    It connects different applications' RESTful APIs for easy and accurate bidirectional 
                    data exchange - without the efforts of a software developer.`,
                    `The WorkPath Connect enables all organizations in all industries to easily exchange 
                    data with their other line-of-business applications.`,
                ].map((item, i) => (
                    <Grid item xs={12} sm={12} key={i}>
                    <Box
                        component={ListItem}
                        disableGutters
                        width={'auto'}
                        padding={0}
                    >
                        <Box
                        component={ListItemAvatar}
                        minWidth={'auto !important'}
                        marginRight={2}
                        >
                        <Box
                            component={Avatar}
                            bgcolor={theme.palette.secondary.main}
                            width={20}
                            height={20}
                        >
                            <svg
                            width={12}
                            height={12}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            >
                            <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                            />
                            </svg>
                        </Box>
                        </Box>
                        <ListItemText primary={item} />
                    </Box>
                    </Grid>
                ))}
                <Box marginTop={2}>
                <Button variant="contained" href='/solutions/workpath-connect'>Get Started</Button>
                </Box>
                </Grid>
            </Box>
            </Grid>
            <Grid item container xs={12} sm={12} md={6} justifyContent='center'>
                <img justifyContent='center' alignItems='center' style={{ borderRadius: '20px', width: '100%', justifyContent: 'center', objectFit: 'contain', display: 'flex', flex: 1 }} src={drc} />
            </Grid>
        </Grid>
        </Container>
        </Box>
    )
};

export default Integration;