import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../../../svg/prism/prism-logo-svg.svg'
import Routes from '../../../../Routes.js';
import MediaQuery from 'react-responsive';
import Dropdown from './components/NavItem/NavItem.js';


import { NavItem, ThemeModeToggler } from './components';

const Topbar = ({ onSidebarOpen, pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const {
    products: productsPages,
    about: aboutPages,
    support: supportPages,

  } = pages;

  return (
    <Box >
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="PrismSoftware"
        width={{ xs: 100, md: 120 }}
        >

        <Box
          component={'img'}
          src={logo}
          height={1}
          width={1}
          />

      </Box>

      
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Box><Button href='/solutions' disableElevation={true} disableRipple={true} sx={{ '&hover': { backgroundColor: 'transparent', background: '#fff' } }}>
          <NavItem alignItems='center'
            title={'Products'}
            id={'products-pages'}
            items={productsPages}
          />
        </Button>
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Support'}
            id={'support-pages'}
            items={supportPages}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem 
            title={'About'}
            id={'about-pages'}
            items={aboutPages}
          />       
        </Box>
        
        <Box marginLeft={4}>
          <Button
            variant="contained"
            color="primary"
            component="a"
            href="http://prismsoftware.com/contact-us/"
            size="large"
          >
            Schedule a Demo
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
};

export default Topbar;