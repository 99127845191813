import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Typography from '@mui/material/Typography';
import automates from '../../../../../images/workpath/WorkPath-Automates.png';
import work from '../../../../../images/workpath/WorkPath-Work.png';
import cog from '../../../../../svg/workpath/Simplify-cog.svg';
import connection from '../../../../../images/workpathconnect/WorkPath-Connect-REST-OAuth-Connection-Window.gif'
import endpoints from '../../../../../images/workpathconnect/REST-Endpoint-WorkPath-Connect-Window.png'
import returns from '../../../../../images/workpathconnect/REST-Example-Endpoint-Editor-WorkPath-Connect.png'
import Button from '@mui/material/Button';
import MediaQuery from 'react-responsive';

const Intuitive = () => {
    const theme = useTheme();
    return (
        <Box sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>


<Grid justifyContent='center' >
    <Typography
        variant='h4'
        textAlign='center'
        marginTop='35px'
        fontWeight={700}
        textAlign='center'
        >
        Easy & Intuitive RESTful API Connection Building
    </Typography>
    <Container marginTop={{ xs: -1, sm: -4, md: -6 }} marginBottom={{ xs: -2, sm: -6, md: -2 }} sx={{ maxWidth: '580px' }}>
    <Typography
        flex={1}
        textAlign='center'
        justifyContent='center'
        color='text.secondary'
        >
        Easy no-code interface enables rapid design and implementation
    </Typography>
    </Container>

    <Box sx={{ backgroundColor: theme.palette.alternate.main }}>
    <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
        <Grid 
            container 
            spacing={2}
            position={'relative'}
            marginTop={-10}
            marginBottom={-10}
        >
        
            <Grid container item xs={12} md={6} justifyContent='center' >

                <Container sx={{ justifyContent: 'center', textAlignVertical: 'center' }}>
                <Typography variant='h4' marginTop={{ xs: 5, sm: 3, md: 2 }} style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Make a connection</Typography>
                <Typography color='text.secondary'>
                    Connect using Name, Passoword, License Keys or other required data.
                    <br/><br/>
                    No-code allows for fast and intuitive connection and authorization including support for OAuth.
                </Typography>
                </Container>
            </Grid>

            <Grid container item xs={12} md={6} justifyContent='center'>
            <Container marginTop={{ xs: -6, sm: -8, md: -6 }} marginBottom={{ xs: 2, sm: 0, md: 0 }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                <img style={{ display: 'block', maxWidth: '100%', left: 'auto', right: 'auto', textAlign: 'center', marginTop: -40, marginBottom: 40 }} src={connection} />
            </Container>
            </Grid>
        </Grid>
    </Container>
    </Box>

    <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
        <Grid container 
            spacing={2}
            position={'relative'}
            marginTop={-10}
            marginBottom={-10}
            direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}
        >

            <Grid container justifyContent='center' item md={6}>
                <Container marginTop={{ xs: -6, sm: -10, md: -6 }} marginBottom={{ xs: 8, sm: 5, md: 3 }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                    <img style={{ maxWidth: '100%', left: 'auto', right: 'auto' }} src={endpoints} />
                </Container>
            </Grid>

            <Grid item md={6}>
                <Container>
                <Typography variant='h4' marginTop={{ xs: 5, sm: 3, md: 0 }} style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Configure your endpoints</Typography>
                <Typography color='text.secondary'>
                    No-code for easy adding of multiple endpoints per connection and for endpoint configuration.
                    <br /><br />
                    Endpoints can be conditional based on data returned.
                </Typography>
                </Container>
            </Grid>
        </Grid>
    </Container>

    <Box sx={{ backgroundColor: theme.palette.alternate.main }}>
    <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
        <Grid 
            container 
            spacing={2}
            position={'relative'}
            marginTop={-10}
            marginBottom={-10}
        >
        
            <Grid container item xs={12} md={6} justifyContent='center' >

                <Container sx={{ justifyContent: 'center', textAlignVertical: 'center' }}>
                <Typography variant='h4' marginTop={{ xs: 5, sm: 3, md: 0 }} style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Process your returned data</Typography>
                <Typography color='text.secondary'>
                    No-code configuration for creating decisions and performing various functions based 
                    upon the returned data or documents.
                    <br/><br/>
                    <Button variant='contained' marginTop={10}>Get Started</Button>
                </Typography>
                </Container>
            </Grid>

            <Grid container item xs={12} md={6} justifyContent='center'>
            <Container marginTop={{ xs: -2, sm: -4, md: -6 }} marginBottom={{ xs: 2, sm: 1, md: 0 }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                <img style={{ maxWidth: '100%', left: 'auto', right: 'auto', textAlign: 'center', marginTop: -40, marginBottom: 40 }} src={returns} />
            </Container>
            </Grid>
        </Grid>
    </Container>
    </Box>
</Grid>
</Box>
    )
};

export default Intuitive;