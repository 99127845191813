import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Typography from '@mui/material/Typography';
import automates from '../../../../../images/workpath/WorkPath-Automates.png';
import work from '../../../../../images/workpath/WorkPath-Work.png';
import cog from '../../../../../svg/workpath/Simplify-cog.svg';
import Button from '@mui/material/Button';
import communicate from '../../../../../images/workpath/cloudBuilding.svg';
import MediaQuery from 'react-responsive';


const SeamlessConnection = () => {
    const theme = useTheme();
    return (
        <Box sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
            <MediaQuery maxDeviceWidth={570}>

                <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
                    <Grid>
                        <Grid container
                            spacing={2}
                            position={'relative'}
                        >

                            <Grid container justifyContent='center' item md={7}>
                                <Container sx={{ justifyContent: 'center', textAlign: 'center', }}>
                                    <img style={{  maxWidth: '355px'}} src={communicate} />
                                </Container>
                            </Grid>

                            <Grid container justifyContent='center' item md={5}>

                                <Container>
                                    <Typography variant='h5' style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Seamless connections between applications</Typography>
                                    <Typography color='text.secondary' marginBottom='30px'>
                                        Prism WorkPath can seamlessly exchange data in real time to your other
                                        applications through Prism's WorkPath Connect &mdash; whether in the cloud or on
                                        premise.
                                        <br />
                                        <br />
                                        Prims WorkPath Connect, a module WorkPath, is a low cost and no-code
                                        application. It connects different applications' RESTful APIs for easy and
                                        accurate bidirection data exchange - without the efort of a software
                                        developer.
                                        <br />
                                        <br />
                                        The WorkPath Connect enables all organizations in all industries is easily
                                        exchange data with their other line-of-business applications.
                                    </Typography>
                                </Container>
                            </Grid>
                        </Grid>



                    </Grid>
                </Container>

            </MediaQuery>
            
            <MediaQuery minDeviceWidth={571}>

                <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
                    <Grid>
                        <Grid container
                            spacing={2}
                            position={'relative'}
                        >

                            <Grid container justifyContent='center' item md={7}>
                                <Container sx={{ justifyContent: 'center', textAlign: 'center', marginTop: -2 }}>
                                    <img style={{ maxWidth: '606px', left: 'auto', right: 'auto', textAlign: 'center' }} src={communicate} />
                                </Container>
                            </Grid>

                            <Grid item md={5}>

                                <Container marginRight='70px'>
                                    <Typography variant='h5' style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px', marginTop: -70 }}>Seamless connections between applications</Typography>
                                    <Typography color='text.secondary' marginBottom='30px'>
                                        Prism WorkPath can seamlessly exchange data in real time to your other
                                        applications through Prism's WorkPath Connect &mdash; whether in the cloud or on
                                        premise.
                                        <br />
                                        <br />
                                        Prism WorkPath Connect, a module WorkPath, is a low cost and no-code
                                        application. It connects different applications' RESTful APIs for easy and
                                        accurate bidirection data exchange - without the efoort of a software
                                        developer.
                                        <br />
                                        <br />
                                        The WorkPath Connect enables all organizations in all industries is easily
                                        exchange data with their other line-of-business applications.
                                    </Typography>
                                </Container>
                            </Grid>
                        </Grid>



                    </Grid>
                </Container>

            </MediaQuery>
        </Box>
    )
};

export default SeamlessConnection;