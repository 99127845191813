import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

var productCounter = 0;

const NavItem = ({ title, items }) => {
  const theme = useTheme();
  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  const hasActiveLink = () => items.find(i => i.href === activeLink);

  const showSeparator = (currentItem, items) => {
    if (currentItem < items.length - 1){
      return(
        <hr></hr>
      );
    }
  }

  if(items.length > 3){
    return (
      <Box>
        <Accordion
          disableGutters
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: 0 }}
          >
            <Typography fontWeight={hasActiveLink() ? 600 : 400}>{title}</Typography>
          </AccordionSummary>
          
          <AccordionDetails sx={{ padding: 0 }}>
            <Grid container spacing={0.5}>
              {/* ROW 1 of 3 */}
              <Grid>
                {items.slice(0, items.length/3 + 1).map((p, i) => (
                  <Grid item key={i} xs={12}>
                    <Typography sx={{ fontWeight: 400, fontSize: '15px' }}>
                    {p.title.toUpperCase()}
                    </Typography>
                    <Button
                      size={'large'}
                      component={'a'}
                      href={p.href}
                      fullWidth
                      sx={{
                        justifyContent: 'flex-start',
                        fontSize: '14px',
                        color:
                          activeLink === p.href
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                        backgroundColor:
                          activeLink === p.href
                            ? alpha(theme.palette.primary.main, 0.1)
                            : 'transparent',
                        fontWeight: activeLink === p.href ? 600 : 400,
                      }}
                    >
                      {p.subtitle}
                    </Button>
                  </Grid>
                ))}
              </Grid>
              <hr width='96%' background-color='lightgray' height='1px'></hr>
              {/* ROW 2 of 3 */}
              <Grid>
                {items.slice(items.length/3 + 1, items.length/3*2 + 1).map((p, i) => (
                  <Grid item key={i} xs={12}>
                    <Typography sx={{ fontWeight: 400, fontSize: '15px' }}>
                    {p.title.toUpperCase()}
                    </Typography>
                    <Button
                      size={'large'}
                      component={'a'}
                      href={p.href}
                      fullWidth
                      sx={{
                        justifyContent: 'flex-start',
                        fontSize: '14px',
                        color:
                          activeLink === p.href
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                        backgroundColor:
                          activeLink === p.href
                            ? alpha(theme.palette.primary.main, 0.1)
                            : 'transparent',
                        fontWeight: activeLink === p.href ? 600 : 400,
                      }}
                    >
                      {p.subtitle}
                    </Button>
                  </Grid>
                ))}
              </Grid>
              <hr width='96%' background-color='lightgray' height='1px'></hr>
              {/* ROW 3 of 3 */}
              <Grid>
                {items.slice(items.length/3*2 + 1).map((p, i) => (
                  <Grid item key={i} xs={12}>
                    <Typography sx={{ fontWeight: 400, fontSize: '15px' }}>
                    {p.title.toUpperCase()}
                    </Typography>
                    <Button
                      size={'large'}
                      component={'a'}
                      href={p.href}
                      fullWidth
                      sx={{
                        justifyContent: 'flex-start',
                        fontSize: '14px',
                        color:
                          activeLink === p.href
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                        backgroundColor:
                          activeLink === p.href
                            ? alpha(theme.palette.primary.main, 0.1)
                            : 'transparent',
                        fontWeight: activeLink === p.href ? 600 : 400,
                      }}
                    >
                      {p.subtitle}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </AccordionDetails>

        </Accordion>
      </Box>
    );
  }
  else{
    return (
      <Box>
        <Accordion
          disableGutters
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: 0 }}
          >
            <Typography fontWeight={hasActiveLink() ? 600 : 400}>{title}</Typography>
          </AccordionSummary>
          
          <AccordionDetails sx={{ padding: 0 }}>
            <Grid container spacing={1}>
              {items.map((p, i) => (
                <Grid item key={i} xs={12}>
                  <Typography sx={{ fontWeight: 400, fontSize: '15px' }}>
                {p.title}
              </Typography>
                <Button
                  size={'large'}
                  component={'a'}
                  href={p.href}
                  fullWidth
                  sx={{
                    justifyContent: 'flex-start',
                    fontSize: '14px',
                    color:
                      activeLink === p.href
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                    backgroundColor:
                      activeLink === p.href
                        ? alpha(theme.palette.primary.main, 0.1)
                        : 'transparent',
                    fontWeight: activeLink === p.href ? 600 : 400,
                  }}
                >
                  
                  {p.subtitle}
                </Button>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>

        </Accordion>
      </Box>
    );
  }
};

NavItem.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default NavItem;
