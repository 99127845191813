import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Typography from '@mui/material/Typography';
import platform from '../../../../../../images/isometric-organization-1.png';
import cloud from '../../../../../../images/workpath/cloudBuilding.svg';
import home from '../../../../../../images/dr-home.png';
import OneDrive from '../../../../../../svg/workpath/onedrive.svg';
import GoogleDrive from '../../../../../../svg/workpath/googledrive.svg';
import Dropbox from '../../../../../../svg/workpath/dropbox.svg';
import BoxApp from '../../../../../../svg/workpath/box.svg';
import SharePoint from '../../../../../../svg/workpath/sharepoint.svg';
import MediaQuery from 'react-responsive';

const Organization = () => {
    const theme = useTheme();
    return (
    <Box>

        <Container>
        <Grid>
              <Grid 
                    container 
                    spacing={2}
                    position={'relative'}
                    marginTop={{ xs: 0, sm: 0, md: -10}}
                    marginBottom={-10}
                >

                    <Grid item container md={5} justifyContent='center' >
                        <Container sx={{ justifyContent: 'center', textAlignVertical: 'center' }}>
                        <Typography style={{ fontWeight: 300, fontSize: '18px', marginBottom: '10px' }}>ENABLE YOUR ORGANIZATION</Typography>
                        <Typography variant='h4' style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Integrate with Cloud Storage</Typography>
                        <Typography color='text.secondary'>
                            Prism WorkPath is already deeply integrated with many cloud storage 
                            providers making it easy to process data and documents and then 
                            automatically upload the results to your organization's provider 
                            such as SharePoint, OneDrive, Google Drive, Box, and Dropbox.
                        </Typography>
                        </Container>
                    </Grid>

                    <Grid item container xs={12} sm={12} md={7} maxWidth='700px' justifyContent='center' >
                    <Container marginTop={{ xs: -2, sm: -6, md: 10 }} marginBottom={{ xs: -12, sm: -20, md: 0 }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                        <Grid container>
                            <Grid item xs={2} sm={2} md={2}>
                            </Grid>

                            <Grid item xs={2} sm={2} md={2} >
                                <img src={SharePoint} width='60px' />
                            </Grid>

                            <Grid item xs={1} sm={1} md={1}>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} >
                                <img src={OneDrive} width='88px' />
                            </Grid>
                            
                            <Grid item xs={1} sm={1} md={1}>
                            </Grid>

                            <Grid item xs={2} sm={2} md={2} >
                                <img src={BoxApp} width='65px' />
                            </Grid>

                            <Grid item xs={2} sm={2} md={2}>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center' marginTop={{ xs: 4, sm: 4, md: 0 }}>
                            <Grid item xs={3} md={3}>
                            </Grid>

                            <Grid item xs={3} md={3} >
                                <img src={GoogleDrive} width='65px' />
                            </Grid>

                            

                            <Grid item xs={3} md={3} >
                                <img src={Dropbox} width='65px' />
                            </Grid>

                            <Grid item xs={3} md={3}>
                            </Grid>
                        </Grid>
                    </Container>
                    </Grid>
                </Grid>

                <Grid container 
                    spacing={2}
                    position={'relative'}
                    marginBottom={{ xs: 10, sm: 10, md: -15 }}
                    marginTop={{ xs: 22, sm: 24, md: 0 }}
                    direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}
                >

                    <Grid item xs={12} sm={12} md={7} marginTop={{ xs: -18, sm: -20, md: 0 }}>
                        <Container sx={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center'  }}>
                            <img style={{ maxWidth: '500px', left: 'auto', right: 'auto', alignItems: 'center', marginTop: 100 }} src={cloud} />
                        </Container>
                    </Grid>

                    <Grid item xs={12} sm={12} md={5} marginBottom={{ xs: 0, sm: 0, md: -3 }}>
                        <Container >
                        <Typography style={{ fontWeight: 300, fontSize: '18px', marginBottom: '10px' }}>EXPERIENCE EASY REST API CONNECTIONS</Typography>
                        <Typography variant='h4' style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Seamless connections between applications</Typography>
                        <Typography color='text.secondary'>
                            Prism WorkPath can seamlessly send data in real time to your other 
                            applications through the Prism WorkPath Connect - whether in the cloud 
                            or on premise.
                            <br/><br/>
                            Prism WorkPath Connect, a module of WorkPath, is a low cost and low-code 
                            application. It connects different applications' RESTful APIs for easy and 
                            accurate bidirectional data exchange &mdash; without the efforts of a software 
                            developer.
                            <br/><br/>
                            The WorkPath Connect enables all organizations in all industries 
                            to easily exchange data with their other line-of-business applications.
                        </Typography>
                        </Container>
                    </Grid>
                </Grid>

            
        </Grid>
        </Container>

    </Box>
    )
};

export default Organization;
