import React from 'react';
import { createTheme, ThemeProvider, experimental_sx as sx } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

const blue = {
    95: '#e8f2fc',
    90: '#d1e6fa',
    75: '#8dbff2',
    45: '#1976d2',
};

const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: 'Lato, sans-serif',
                    color: '#535b5f',
                    cursor: 'pointer',
                    fontSize: '14px',
                    backgroundColor: 'transparent',                  
                    border: 'none',
                    borderRadius: '8px',
                    textTransform: 'none',
                    indicator: {
                        backgroundColor: 'white'
                    },
                    "&:hover": {
                        backgroundColor: blue[95]
                        },
                    '&.Mui-selected': {
                        backgroundColor: blue[90],
                        color: '#474e52'
                    }
                },
            }, 
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    marginBottom: '16px',
                    display: 'flex',
                },
                indicator: {
                    backgroundColor: 'transparent'
                }
            },
        }
    }
})

{/*const TabUn = styled(TabUnstyled)`
  font-family: Lato, sans-serif;
  color: #535b5f;
  cursor: pointer;
  font-size: 0.875rem;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 4px 4px;
  border: none;
  border-radius: 8px;
  justify-content: center;

  &:hover {
    background-color: ${blue[95]};
  }


  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[90]};
    color: #474e52;
  }

`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  justify-content: center;
  font-family: Lato, sans-serif;
  font-size: 0.875rem;
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: hidden;
`;

const TabsList = styled(TabsListUnstyled)`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 16px;
  float: left;
  min-width: 450px;
  display: flex;
  scrollButtons;
  overflow-x: scroll;
  overflow-y: hidden;
  overflow: scroll;
  align-items: center;
  justify-content: center;
  align-content: space-evenly;
  scroll-buttons: true;
  allowScrollButtonsMobile;
`;*/}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


function TempTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box data-aos={'fade-up'} sx={{ width: 0, flexGrow: 1, flex: 1, overflowX: 'hidden' }}>
                    <Box sx={{ flex: 1, flexWrap: 'wrap' }}>
                        <Typography variant='h4' sx={{ fontWeight: 700, textAlign: 'center', marginBottom: 3}}>
                            Department and Industry Problems We Solve
                        </Typography>
                        <Typography  color='text.secondary' sx={{ textAlign: 'center', marginBottom: 5 }}>
                            Automate your work processes, eliminate manual 
                            processing, integrate applications, and greatly 
                            reduce costs.
                        </Typography>
          
                    <ThemeProvider theme={theme}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile>
                     
                                <Tab label='Accounting' {...a11yProps(0)} />
                                <Tab label='Human Resources' {...a11yProps(1)} />
                                <Tab label='Legal' {...a11yProps(2)}/>
                                <Tab label='Manufacturing' {...a11yProps(3)}/>
                                <Tab label='Healthcare' {...a11yProps(4)}/>
                                <Tab label='Education' {...a11yProps(5)}/>
                            
                        </Tabs>
                    </ThemeProvider> 

                    <Box  sx={{ justifyContent: 'center', left: '50%', right: '50%', }}>
                    <TabPanel value={value} index={0} >

                            <Typography variant='h5' fontWeight={700} marginBottom={4}>
                                Accelerate AP & AR Processing
                            </Typography>

                            <Typography color={'text.secondary'} fontSize='16px' marginBottom={-3} lineHeight={'26px'}>
                                Organizations require a wide range of custom accounting process automations that are nearly instant, 
                                error-free, and reduce costs. With Prism's applications, these automated custom and complex processes 
                                are easily built and affordable.
                                <br />
                                <br />
                                These automated accounting processes include data extraction from invoices and other documents, database 
                                lookups and validation, payment exceptions determination, intelligent workflows that include routing 
                                rules and approval guidelines, automated notifications, purchase order and check creations, sending 
                                data to line-of-business application, and much more.
                            </Typography>
                        
                        </TabPanel>

                        <TabPanel value={value} index={1} >
                             
                            <Typography variant='h5' fontWeight={700} marginBottom={4}>
                                Streamline Application & Onboarding Processes
                            </Typography>

                            <Typography color={'text.secondary'} fontSize='16px' marginBottom={-3} lineHeight={'26px'}>
                                New hire applications and onboarding, as well as current employee record keeping, require 
                                comprehensive and custom workflows and employee communications.
                                <br />
                                <br />
                                Prism provides affordable and customized eForms and workflows for the collection of data and documents 
                                for applicants, and the automated dissemination of documents for new hires. Existing employees require 
                                customized workflows utilizing eForms, custom employee communications, employee records archiving, and much more.
                            </Typography>

                        </TabPanel>

                        <TabPanel value={value} index={2} >

                            <Typography variant='h5' fontWeight={700} marginBottom={4}>
                                Enable Instant Document Access & Automated Workflows
                            </Typography>

                            <Typography color={'text.secondary'} fontSize='16px' marginBottom={-3} lineHeight={'26px'}>
                                Law firms and legal departments require automated operational improvements that help minimize labor costs 
                                and speed response and communication times while substantially improving digital record keeping and 
                                remote retrieval.
                                <br />
                                <br />
                                Prism's DocRecord provides this wide range of affordable automations including document versioning, 
                                document check-in and check-out, intelligent custom workflows, automated email notifications, eForms, 
                                document retention policies, case management, data extraction from documents, and much more.
                            </Typography>

                        </TabPanel>

                        <TabPanel value={value} index={3}>

                            <Typography variant='h5' fontWeight={700} marginBottom={4}>
                                Lower Cost of Goods Sold & Improve Manufacturing Times
                            </Typography>

                            <Typography color={'text.secondary'} fontSize='16px' marginBottom={-3} lineHeight={'26px'}>
                                Manufacturing operations require highly customized work automation processes that lower costs, 
                                speed production times, reduce errors, improve record keeping, and provide accurate and timely 
                                communications to all stakeholders.
                                <br />
                                <br />
                                Prism provides this affordable and highly customized set of automated processes and communications. 
                                This automation includes extracting data from incoming sales orders, creating manufacturing and 
                                supporting documents, intelligent workflows with multi-stage approvals, auto-generation of 
                                manufacturing and compliance documents, routing status and other data to other departments, and much more.
                            </Typography>
                            
                        </TabPanel>

                        <TabPanel value={value} index={4} >

                            <Typography variant='h5' fontWeight={700} marginBottom={4}>
                                Significantly Improve Data Gathering & Communications
                            </Typography>

                            <Typography color={'text.secondary'} fontSize='16px' marginBottom={-3} lineHeight={'26px'}>
                                Healthcare organizations require a wide range of automations that help improve patient care and response times, 
                                greatly speed data collection, and enable instant record retrieval.
                                <br />
                                <br />
                                Prism provides affordable, custom, and complex automations for every department in healthcare. These automations 
                                include data gathering eForms, intelligent workflows with multi-level and conditional approvals, discharge 
                                alerts, patient notifications, accounting data, and much more.
                            </Typography>
                            
                        </TabPanel>

                        <TabPanel value={value} index={5}>

                            <Typography variant='h5' fontWeight={700} marginBottom={4}>
                                Significantly Improve Student & Faculty Operations
                            </Typography>

                            <Typography color={'text.secondary'} fontSize='16px' marginBottom={-3} lineHeight={'26px'}>
                                Education organizations require a uniquely wide range of automations that greatly ease and improve 
                                all aspects of campus life, for both students and faculty. These process automations include 
                                enrollments and admissions, financial aid, accounting, HR, student records, faculty records, healthcare, 
                                and many other aspects. 
                                <br />
                                <br />
                                Prism provides affordable, custom, and complex automations for many campus data and document work processes. 
                                These automations include data gathering eForms, intelligent workflows with conditional routing, student 
                                notifications, routing data to other campus applications, automated delectronic document and booklet 
                                creations, email notifications, data extraction, document portfolio assembly, document retention policy, 
                                remote access to archives, and much more.
                            </Typography>
                            
                        </TabPanel>
                    </Box>
            </Box>
        </Box>
    )
}

export default TempTabs;
