import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles'
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MediaQuery from 'react-responsive';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: "none"
  },
  popoverContent: {
    pointerEvents: "auto"
  }
}));

const NavItem = ({ title, subtitle, id, items }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(false);
  const [openedPopoverId, setOpenedPopoverId] = useState(false);

  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(false);
    setOpenedPopoverId(false);
  };

  const handleOpen = (event, popoverId) => {
    if (anchorEl !== event.currentTarget){
      setAnchorEl(event.currentTarget);
      setOpenedPopoverId(true);
    }
  };

  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  const showProducts = (start, end) => {
    return(
      items.slice(start, end).map((p, i) => (
      <Grid item key={i} xs={items.length > 10 ? 12 : 12}>
        <Typography sx={{ padding: '10px', fontWeight: 400, fontSize: '15px' }}>
          {p.title.toUpperCase()}
        </Typography>
          <Button
            size={'large'}
            component={'a'}
            href={p.href}
            fullWidth
            sx={{
              justifyContent: 'flex-start',
              fontSize: '14px',
              padding: '8px',
              paddingLeft: '20px',
              color:
                activeLink === p.href
                  ? theme.palette.primary.main
                  : theme.palette.text.primary,
              backgroundColor:
                activeLink === p.href
                  ? alpha(theme.palette.primary.main, 0.1)
                  : 'transparent',
              fontWeight: activeLink === p.href ? 600 : 400,
            }}
          >
            {p.subtitle}
          </Button>
        </Grid>
      ))
    );
  }
  
  const showHorizontalSeparator = (currentItem, items) => {
    if (currentItem < items.length - 1){
      return(
        <hr></hr>
      );
    }
  }

  // 3 COLUMN NAV BAR
  if(items.length > 3){
    return (
      <Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          aria-owns={anchorEl ? id : undefined}
          sx={{ cursor: 'pointer'}}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
        >
          <Typography color={openedPopoverId ? 'primary' : 'text.primary'}>
            {title}
          </Typography>
        </Box>
          <Popover
              elevation={10}
              id={id}
              anchorEl={anchorEl.current}
              open={openedPopoverId}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              className={classes.popover}
              classes={{
                paper: classes.popoverContent
              }}
              PaperProps={{onMouseEnter: handleOpen, onMouseLeave: handleClose}}
              sx={{
                '.MuiPaper-root': {
                  width: 0.8,
                  maxWidth: 1205,
                  minWidth: 850,
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 2,
                  paddingRight: 1,
                  marginLeft:  '-1vw',
                  marginTop: 7,
                  borderRadius: 2,
                },
              }}
            >
              <Grid container spacing={0.5} paddingLeft={'0%'}>
                {/* COLUMN 1 OF 3 */}
                <Grid width={'31%'}>
                  {showProducts(0, items.length/3 )}
                </Grid>
                <Grid width={'1px'} color={'rgba(0,0,0,0)'} backgroundColor='lightgray' marginLeft= '2%' marginRight='2%'>.</Grid>
                {/* COLUMN 2 OF 3 */}
                <Grid width={'31%'}>
                  {showProducts(items.length/3 , items.length/3*2)}
                </Grid>
                <Grid width={'1px'} color={'rgba(0,0,0,0)'} backgroundColor='lightgray' marginLeft='2%' marginRight='2%'>.</Grid>
                {/* COLUMN 3 OF 3 */}
                <Grid width={'29%'}>
                  {showProducts(items.length/3*2)}
                </Grid>
              </Grid>
            </Popover>
      </Box>
    );
  }
  // ONE COLUMN NAV BAR
  else{
    return (
      <Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          aria-owns={anchorEl ? id : undefined}
          sx={{ cursor: 'pointer'}}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
        >
          <Typography color={openedPopoverId ? 'primary' : 'text.primary'}>
            {title}
          </Typography>
        </Box>
        <Popover
          elevation={10}
          id={id}
          anchorEl={anchorEl}
          open={openedPopoverId}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={handleClose}
          className={classes.popover}
          classes={{
            paper: classes.popoverContent
          }}
          PaperProps={{onMouseEnter: handleOpen, onMouseLeave: handleClose}}
          sx={{
            '.MuiPaper-root': {
              maxWidth: 250,
              padding: 2,
              marginTop: 6,
              borderRadius: 2,
            },
          }}
        >
          <Grid container spacing={0.5}>
            {items.map((p, i) => (
              <Grid item key={i} xs={items.length > 10 ? 12 : 12}>
              <Typography sx={{ fontWeight: 400, fontSize: '15px' }}>
                {p.title}
              </Typography>
                <Button
                  size={'large'}
                  component={'a'}
                  href={p.href}
                  fullWidth
                  sx={{
                    justifyContent: 'flex-start',
                    fontSize: '14px',
                    color:
                      activeLink === p.href
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                    backgroundColor:
                      activeLink === p.href
                        ? alpha(theme.palette.primary.main, 0.1)
                        : 'transparent',
                    fontWeight: activeLink === p.href ? 600 : 400,
                  }}
                >
                  {p.subtitle}
                </Button>
                {showHorizontalSeparator(i, items)}
              </Grid>
            ))}
          </Grid>
        </Popover>
      </Box>
    );
  }
};

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

export default NavItem;
