import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import cs_benefits1 from 'images/cs_benefits1.jpg';
import cs_benefits2 from 'images/cs_benefits2.jpg';

const Benefits = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const LeftSide = () => (
    <Grid container spacing={2}>
      <Grid item>
        <Box
          sx={{ display: { xs: 'none', sm: 'block' } }}
          width={1}
        >
          <img src={cs_benefits1} style={{ maxWidth: '380px', borderRadius: '25px' }} />
        </Box>
      </Grid>
      <Grid item>
        <Box
          display={'block'}
          width={1}
          sx={{
            transform: 'translateX(140px)',
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <img src={cs_benefits2} style={{ maxWidth: '380px', borderRadius: '25px' }} />
        </Box>
      </Grid>
    </Grid>
  );

  const RightSide = () => (
    <Box>
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 300,
            color: '#555555'
          }}
          gutterBottom
          color={'text.secondary'} // TODO change color
        >
          ACCESS ALL BENEFITS
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
          Enable productivity and assessibility
        </Typography>
        <Typography color="text.secondary" variant={'h6'}>
          All extracted data needs to be validated and, when necessary, corrected. Prism Capture makes data correction and
          validation easy by having pre-build rules that examine and correct the data.
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {[
          'Speed up data extraction with pre-build rules replacing the need for users to build their own validation and correction rules.',
          'Efficiently edit unrecognizable or incorrectly extracted data with a quick edit and validate document\'s values with a simple click.',
          'Integrate seamlessly with DocRecord, enterprise content management application, for a more all-inclusive and full-featured solution.',
        ].map((item, i) => (
          <Grid item xs={12} key={i}>
            <Box component={ListItem} disableGutters width={'auto'} padding={0}>
              <Box
                component={ListItemAvatar}
                minWidth={'auto !important'}
                marginRight={2}
              >
                <Box
                  component={Avatar}
                  bgcolor={theme.palette.secondary.main}
                  width={20}
                  height={20}
                >
                  <svg
                    width={12}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Box>
              </Box>
              <ListItemText primary={item} />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box
        marginTop={4}
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'flex-start' }}
      >
        <Button
          component={'a'}
          variant="contained"
          color="primary"
          size="large"
          fullWidth={isMd ? false : true}
          href={
            'https://prismsoftware.com/contact-us/'
          }
        >
          Learn More
        </Button>
        {/* <Box
          marginTop={{ xs: 2, sm: 0 }}
          marginLeft={{ sm: 2 }}
          width={{ xs: '100%', md: 'auto' }}
        >
          <Button
            component={'a'}
            href={'/docs/introduction'}
            variant="outlined"
            color="primary"
            size="large"
            fullWidth={isMd ? false : true}
          >
            Download Datasheet      {/**TODO add download arrow *
          </Button>
        </Box> */}
      </Box>
    </Box>
  );

  return (
    <Grid container spacing={4}>
      <Grid
        item
        xs={12}
        md={6}
      >
        <LeftSide />
      </Grid>
      <Grid item container alignItems={'center'} xs={12} md={6}>
        <RightSide />
      </Grid>
    </Grid>
  );
};

export default Benefits;
