import React from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import wpa from './images/WPA.png';
import capture from './images/Capture.png';
import ecm from './images/ECM.png';
import data from './images/VariableData.png';
import api from './images/RestApi.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Routes from '../../../Routes.js';

const blue = {
    95: '#e8f2fc',
    90: '#d1e6fa',
    75: '#8dbff2',
    45: '#1976d2',
};

const Tab = styled(TabUnstyled)`
  font-family: Lato, sans-serif;
  color: #535b5f;
  cursor: pointer;
  font-size: 0.875rem;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  justify-content: center;

  indicator {
      backgroundColor: white;
  }

  &:hover {
    background-color: ${blue[95]};
  }


  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[90]};
    color: #474e52;
  }

`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  justify-content: center;
  font-family: Lato, sans-serif;
  font-size: 0.875rem;
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: hidden;
`;

const TabsList = styled(TabsListUnstyled)`
  width: 100%;
  min-width: 450px;
  backgroundColor: transparent;                
  border: none;
  display: flex;
  scrollButtons;
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  align-content: space-evenly;
  
  indicator {
    backgroundColor: transparent;
  }
`;


function CommonProblems() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box data-aos={'fade-up'} sx={{ width: 0, flexGrow: 1, flex: 1 }}>
                    <Box sx={{ flex: 1, flexWrap: 'wrap' }}>
                        <Typography variant='h4'  sx={{ fontWeight: 700, textAlign: 'center', marginBottom: 3}}>
                            Solutions for Improving How You Work & Lowering Your Costs
                        </Typography>
            
                        <TabsUnstyled defaultValue={0}>
               
                        <TabsList
                        
                            value={value}
                            justifyContent='center'
                            onChange={handleChange}
                            overflow='scroll'
                            scrollButtons
                            >
                            <Tab value={0}>ECM with Work Process Automation</Tab>
                            <Tab value={1}>Advanced Work Process Automation</Tab>
                            <Tab value={2}>Easy REST API Integration</Tab>
                            <Tab value={3}>Advanced Data Capture</Tab>
                            <Tab value={4}>Automated Business Communications</Tab>
                        </TabsList>
      
                            
                   
                        <TabPanel value={0} >
                            <Grid container spacing={6}>
                            <Grid item container md={3} justifyContent='center' alignItems='center' marginTop={5}>
                               <img src={ecm} style={{ borderRadius: '20px', maxWidth: '200px' }} />
                            </Grid>
                            <Grid item container md={9}>
                            <Typography variant='h5' fontWeight={700} marginTop={4} marginBottom={4}>
                                ECM For the Whole Organization
                            </Typography>

                            <Typography color={'text.secondary'} fontSize='16px' lineHeight={'26px'}>
                                DocRecord is an affordable, comprehensive, and all-inclusive ECM 
                                (enterprise content management) platform that enables the automation 
                                of complex work processes throughout the whole organization. 
                                <br />
                                <br />
                                DocRecord includes advanced data capture from documents, advanced workflow 
                                with RPA (robotic process automation) and ETL (extract, transform, load), 
                                eForms, and automated document creation &mdash; all based on a powerful content/document 
                                repository system.
                            </Typography>
                            <Button variant='text' href='/solutions/docrecord' endIcon={<ArrowForwardIcon />} sx={{ marginTop: '15px', right: '6px' }}>Learn more</Button>
                            </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={1} >
                             <Grid container spacing={6}>
                             <Grid item container md={3} justifyContent='center' alignItems='center' marginTop={5}>
                                <img src={api} style={{ borderRadius: '20px', maxWidth: '200px' }} />
                            </Grid>
                             <Grid item container md={9}>
                             <Typography variant='h5' fontWeight={700} marginTop={4} marginBottom={4}>
                                Data & Document Processing Automation
                             </Typography>

                            <Typography color={'text.secondary'} fontSize='16px'  lineHeight={'26px'}>
                                Prism WorkPath is an affordable and easy-to-use platform for the rapid creation of 
                                complex work process automations for data and documents that enable organizations 
                                to eliminate their manual processes.
                                <br />
                                <br />
                                Prism WorkPath workflows utilize both RPA (robotic process automation) and ETL 
                                (extract, transform, load) capabilities for automating repetitive tasks. 
                                WorkPath's module &mdash; REST API Connector &mdash; enables seamless bidirectional 
                                communication between applications.
                            </Typography>
                            <Button variant='text' href='/solutions/workpath' endIcon={<ArrowForwardIcon />} sx={{ marginTop: '15px', right: '6px' }}>Learn more</Button>
                            </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={2} >
                            <Grid container spacing={6}>
                            <Grid item container md={3} justifyContent='center' alignItems='center' marginTop={5}>
                               <img src={wpa} style={{ borderRadius: '20px', maxWidth: '150px' }} />
                            </Grid>
                            <Grid item container md={9}>
                            <Typography variant='h5' fontWeight={700} marginTop={4} marginBottom={4}>
                                Rapid REST API Data Integrations
                            </Typography>

                            <Typography color={'text.secondary'} fontSize='16px' lineHeight={'26px'}>
                                Prism WorkPath’s REST API Connector is an affordable, no-code 
                                connector that enables the bidirectional data exchange between the 
                                REST APIs of different applications.
                                <br />
                                <br />
                                Easily and quickly enable enterprise application integration and data 
                                exchange between different REST APIs without requiring developer resources. 
                                Achieve faster data integrations with significantly less work and at a significantly 
                                lower cost.
                            </Typography>
                            <Button variant='text' href='/solutions/workpath-connect' endIcon={<ArrowForwardIcon />} sx={{ marginTop: '15px', right: '6px' }}>Learn more</Button>
                            </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={3}>
                            <Grid container spacing={6}>
                            <Grid item container md={3} justifyContent='center' alignItems='center' marginTop={5}>
                               <img src={capture} style={{ borderRadius: '20px', maxWidth: '200px' }} />
                            </Grid>
                            <Grid item container md={9}>
                            <Typography variant='h5' fontWeight={700} marginTop={4} marginBottom={4} >
                                Automatically & Intelligently Extract Data
                            </Typography>

                            <Typography color={'text.secondary'} fontSize='16px' lineHeight={'26px'}>
                                Prism Capture is an affordable and easy-to-use platform to accurately capture and 
                                extract data from electronic documents. Once extracted, Prism Capture can perform 
                                database lookups for data verification.
                                <br />
                                <br />
                                Eliminate tedious manual data extraction for both line-item and key data. 
                                Automatically route extracted data to line-of-business application, 
                                SharePoint, or other applications. Prism Capture is built for heavy volume 
                                and complex documents.
                            </Typography>
                            <Button variant='text' href='/solutions/capture' endIcon={<ArrowForwardIcon />} sx={{ marginTop: '15px', right: '6px' }}>Learn more</Button>
                            </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={4} >
                            <Grid container spacing={6}>
                            <Grid item container md={3} justifyContent='center' alignItems='center' marginTop={5}>
                               <img src={data} style={{ borderRadius: '20px', maxWidth: '200px' }} />
                            </Grid>
                            <Grid item container md={9}>
                            <Typography variant='h5' fontWeight={700} marginTop={4} marginBottom={4}>
                                High Volume Personalized Communications
                            </Typography>

                            <Typography color={'text.secondary'} fontSize='16px'  lineHeight={'26px'}>
                                DocForm quickly creates high volume personalized and complex business communications such as statements, 
                                invoices, notifications, explanation-of-benefits (EOB), booklets, and more. Process nested-XML 
                                streams to create data-nested statements used in financial and insurance requirements.
                                <br />
                                <br />
                                Create comprehensive, variable data-driven documents from multiple data sources including databases, 
                                images, XML streams, and print queues. Personalize images, messaging, calculations, and more on 
                                each unique document.
                            </Typography>
                            <Button variant='text' href='/solutions/docform' endIcon={<ArrowForwardIcon />} sx={{ marginTop: '15px', right: '6px' }}>Learn more</Button>
                            </Grid>
                            </Grid>
                        </TabPanel>
              
                        </TabsUnstyled>
            </Box>
        </Box>
    )
}

export default CommonProblems;
