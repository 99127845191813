import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {Helmet} from 'react-helmet'; 
import { grey } from '@mui/material/colors';

const InterestForm = () => {
    return (
        <div>
        <Helmet>
            <script
                async type="text/javascript"
                src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=TPaD2m"
            ></script>
        </Helmet>
        <Container sx={{ justifyContent: 'center' }}>
                <div style={{ zIndex: 2, position: 'relative' }}>
                
                    <Card sx={{ display: 'flex', maxWidth: '1200px', height: '280px', justifyContent: 'center', textAlign: 'center', zIndex: 2, boxShadow: '3px 6px 6px 5px rgba(0,0,0,0.2)', alignItems: 'center' }}>
                    
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" fontWeight={600} fontSize={'32px'} marginBottom={'15px'}>
                            Let us show you eForms
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div" marginBottom={'15px'}>
                            See how our product can make your work easier.
                            
                        </Typography>
                        <div class="klaviyo-form-VhaspP"></div>
                        </CardContent>

                    </Box>
                    </Card>
                </div>
        </Container>
        <div style={{ zIndex: 1 }}>
        <Box
            position={'relative'}
            sx={{
            backgroundColor: grey[800],
            justifyContent: 'center',
            height: '300px',
            zIndex: 1,
            marginTop: '-220px',
            marginBottom: -1
            }}
            >
        </Box>
        </div>
        </div>
    )
};

export default InterestForm;