import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from 'components/Container';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import card1 from '../../../../../images/dr/card1.jpg';
import card2 from '../../../../../images/dr/card2.jpg';
import card3 from '../../../../../images/dr/card3.jpg';
import card4 from '../../../../../images/dr/card4.jpg';
import card5 from '../../../../../images/dr/card5.jpg';
import card6 from '../../../../../images/dr/card6.jpg';
import MediaQuery from 'react-responsive';

const Cards = () => {
    const theme = useTheme();
    return (
        <Box>
        
        <Box>
            <Container>
                <Typography variant='h4' marginBottom={8} sx={{ textAlign: 'center', fontWeight: 700 }}>
                    For Every Organization, Department {'&'} Industry
                </Typography>
            <Grid container rowSpacing={8} columnSpacing={6}>
                <Grid container item xs={12} sm={6} md={4} justifyContent='center'>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            component="img"
                            height="225"
                            image={card1}
                            alt="accounting"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Accounting
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Automate A/P and A/R processes with Intelligent and accurate 
                            data extraction from invoices, statements, and more. 
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={12} sm={6} md={4} justifyContent='center'>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            component="img"
                            height="225"
                            image={card2}
                            alt="healthcare"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Healthcare
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Automated eForms and document workflows ensuring patient 
                            privacy, HIPAA compliance, and secure records archiving. 
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={12} sm={6} md={4} justifyContent='center'>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            component="img"
                            height="225"
                            image={card3}
                            alt="legal"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Legal
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Document versioning, intelligent custom workflows, secure 
                            archiving, and remote access to records. 
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={12} sm={6} md={4} justifyContent='center'>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            component="img"
                            height="225"
                            image={card4}
                            alt="education"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Education
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Automate student records, data collection, intelligent eForms, 
                            communications, data extraction from documents and more. 
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={12} sm={6} md={4} justifyContent='center'>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            component="img"
                            height="225"
                            image={card5}
                            alt="government"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Government
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Secure archive of public records, automate inter-departmental 
                            workflows, email notifications, retention policies, and more. 
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={12} sm={6} md={4} justifyContent='center'>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            component="img"
                            height="225"
                            image={card6}
                            alt="manufacturing"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Manufacturing
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Eliminate manual processes with automated document creation, 
                            workflows, approvals, eSign, and more. 
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            </Container>
        </Box>
        </Box>
    )
};

export default Cards;