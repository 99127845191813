/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import MouseIcon from '@mui/icons-material/Mouse';
import LaptopIcon from '@mui/icons-material/Laptop';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import PsychologyIcon from '@mui/icons-material/Psychology';

const mock = [
  {
    title: 'Email Support',
    icon: (
      <QuestionMarkIcon fontSize='large' />
    ),
    link: 'mailto:support@prismsoftware.com',
  },
  {
    title: 'Get Remote Support',
    icon: (
      <MouseIcon fontSize='large'/>
    ),
    link: 'https://get.teamviewer.com/prismsoftware',
  },
  {
    title: 'Product Documentation',
    icon: (
      <LaptopIcon fontSize='large'/>
    ),
    link: 'https://prismsupport.com/documentation/Content/A_IntroTopics/PrismDocumentation-Home.htm',
  },
  {
    title: 'Product Training Videos',
    icon: (
      <VideoLibraryIcon fontSize='large'/>
    ),
    link: 'https://www.prismsupport.com/documentation/Content/A_IntroTopics/Video-Library.htm',
  },
  {
    title: 'Technical Knowledge Base',
    icon: (
      <PsychologyIcon fontSize='large' />
    ),
    link: 'https://prismsoftware.atlassian.net/wiki/spaces/PS/overview?mode=global',
  },
];

const Categories = () => {
  const theme = useTheme();
  return (
    <Box>

      <Box>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            
            <Grid item xs={12} sm={6} md={2.4} key={i}>
    
              <Box
                display={'block'}
                width={1}
                height={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  },
                }}
              >

                <Box
                  component={Card}
                  onClick={() => window.open(item.link, "_self")}
                  padding={4}
                  borderRadius={4}
                  width={1}
                  height={1}
                  bgcolor={'alternate.main'}
                  data-aos={'fade-up'}
                  data-aos-delay={i * 100}
                  data-aos-offset={100}
                  data-aos-duration={600}
                  sx={{ cursor: 'pointer' }}
                >
                
                  <Box
                    position={'relative'}
                    display={'flex'}
                    justifyContent={'center'}
                  >
                    <Box
                      width={50}
                      height={50}
                      borderRadius={'100%'}
                      sx={{
                        transform: `translate(${theme.spacing(
                          1,
                        )}, ${theme.spacing(-0.5)})`,
                        marginTop: 2,
                      }}
                    />
                    <Box
                      sx={{
                        color: 'primary.dark',
                        position: 'absolute',
                        bottom: 0,
                      }}
                    >
                      {item.icon}
                    </Box>
                  </Box>
                  
                  <Typography
                    variant={'subtitle1'}
                    align={'center'}
                    sx={{ fontWeight: 500, marginTop: 2 }}
                  >
                    {item.title}
                  </Typography>
                  
                </Box>
         
              </Box>

            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Categories;
