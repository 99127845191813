import React, { useState } from 'react';
import { useTheme, createTheme, ThemeProvider  } from '@mui/material/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import useMediaQuery from '@mui/material/useMediaQuery';


import Container from 'components/Container';

const blueTheme = createTheme({ 
  palette: { 
    primary: {
      main: '#3F50B5'
      }
  } 
})

const mock = [
  {
    header: 'ONE-TIME PURCHASE',
    title: 'Desktop',
    subtitle: 'Recommended for desktop users and teams to easily edit and organize documents',
    price: { monthly: '$20' },
    features: ['Feature', 
      'Desktop Application', 
      'Easily integrate with DocRecord'
    ],
    isHighlighted: false,
  },
  {
    header: 'ONE-TIME PURCHASE',
    title: 'Standard',
    subtitle: 'Easily automate your most complex processes',
    price: { monthly: '$167' },
    features: [
      'Feature',
      'Feature',
      'Feature',
    ],
    isHighlighted: false,
  },
  {
    header: 'SUBSCRIPTION',
    title: 'Advanced',
    subtitle: 'Get advanced features for work process automation and more',
    price: { monthly: '$167' },
    features: [
      'Feature',
      'Feature',
      'Feature',
    ],
    isHighlighted: true,
  },
  {
    header: 'SUBSCRIPTION',
    title: 'Connect',
    subtitle: 'Powerful module to enable seamless REST API connections',
    price: { monthly: '$150' },
    features: [
      'Feature',
      'Feature',
      'Feature',
    ],
    isHighlighted: false,
  },
];

const Main = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [pricingOption, setPricingOption] = useState('monthly');

  const handleClick = (event, newPricingOption) => {
    setPricingOption(newPricingOption);
  };

  const renderToggler = () => (
    <Box display={'flex'} justifyContent={'center'} marginBottom={4}>
      <ToggleButtonGroup value={pricingOption} exclusive onChange={handleClick}>
        <ToggleButton
          value="annual"
          size={isMd ? 'large' : 'small'}
          sx={{
            backgroundColor:
              pricingOption === 'annual'
                ? `${theme.palette.primary.light} !important`
                : 'transparent',
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color:
                pricingOption === 'annual' ? 'common.white' : 'text.primary',
            }}
          >
            Annual
          </Typography>
        </ToggleButton>
        <ToggleButton
          value="monthly"
          size={isMd ? 'large' : 'small'}
          sx={{
            backgroundColor:
              pricingOption === 'monthly'
                ? `${theme.palette.primary.light} !important`
                : 'transparent',
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              color:
                pricingOption !== 'annual' ? 'common.white' : 'text.primary',
            }}
          >
            Monthly
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );

  return (
    <Box>
      
      <Container>

        <Typography variant='h4' marginTop='-50px' textAlign='center' fontWeight={700} marginBottom='50px'>
          Affordable Pricing for All Work Process Automations
        </Typography>

        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid item xs={12} md={3} key={i}>
              <Box
                component={Card}
                height={1}
                display={'flex'}
                flexDirection={'column'}
                variant={'outlined'}
              >
                <CardContent
                  sx={{
                    padding: 4,
                  }}
                >
                  <Box marginBottom={2} height='150px'>
                    <Typography variant={'h7'} fontWeight={600} gutterBottom color='#3F50B5'>
                      {item.header}
                    </Typography>
                    <Typography variant={'h4'} fontWeight={600} gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography color={'text.secondary'}>
                      {item.subtitle}
                    </Typography>
                  </Box>
                  <Box
                    display={'flex'}
                    alignItems={'baseline'}
                    marginBottom={2}
                  >
                    <Typography variant={'h3'} fontWeight={700}>
                      {pricingOption === 'annual'
                        ? item.price.annual
                        : item.price.monthly}
                    </Typography>
                    <Typography
                      variant={'subtitle1'}
                      color={'text.secondary'}
                      fontWeight={700}
                    >
                      {pricingOption === 'annual' ? '/y' : '/mo'}
                    </Typography>
                  </Box>

                  <Typography color='text.secondary' marginBottom='10px'>
                      Includes:
                  </Typography>
                  <Grid container spacing={1}>
                    
                    {item.features.map((feature, j) => (
                      <Grid item xs={12} key={j}>
                        <Box
                          component={ListItem}
                          disableGutters
                          width={'auto'}
                          padding={0}
                        >
                        
                          <Box
                            component={ListItemAvatar}
                            minWidth={'auto !important'}
                            marginRight={2}
                          >
               
                            <Box
                              component={Avatar}
                              bgcolor='#3F50B5'
                              width={20}
                              height={20}
                            >
                              <svg
                                width={12}
                                height={12}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </Box>
                           
                          </Box>
                          <ListItemText primary={feature} />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions sx={{ justifyContent: 'flex-end', padding: 4 }}>
                  <ThemeProvider theme={blueTheme}>
                    <Button size={'large'} variant={'contained'}>
                      Request a demo
                    </Button>
                  </ThemeProvider>
                </CardActions>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Main;
