import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Container from 'components/Container';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import Contact from '../../Contact/Contact.js';
import Main from 'layouts/Main';
import { useTheme } from '@mui/material/styles';
import Routes from '../../../../../../Routes.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const May10 = () => {
  const theme = useTheme();
  // mui xs, sm, md responsiveness
  return (
    <Main>
    
    <Box marginTop={{md: 1.8}}>
      <Box bgcolor={theme.palette.primary.main}>
        <Container paddingX={{ xs: 2, sm: 4 }}>
          <Typography
            variant={'h4'}
            gutterBottom
            sx={{
              fontWeight: 700,
              color: theme.palette.common.white,
            }}
          >
            News
          </Typography>
          <Typography
            gutterBottom
            sx={{  
              color: theme.palette.common.white,
            }}
          >
            Latest news of Prism Software
          </Typography>
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          width={1}
          marginBottom={-1}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
      <Container
        paddingTop={'0 !important'}
        paddingX={{ xs: 5, sm: 4 }}
        position={'relative'}
        top={0}
      >
        <Box
          component={Grid}
          container
          spacing={4}
          flexDirection={{ xs: 'column', md: 'row' }}
        >

    <Box sx={{ justifyContent: 'center' }}>
    <Container>
      <Box marginLeft={'-6px'} marginBottom={'15px'}>
        <Button href='/news' startIcon={<ArrowBackIcon/>} >Back</Button>
      </Box>

      <Typography variant='h5' sx={{ marginBottom: 2 }}>
        Prism Software Announces the Release of DocRecord v7
      </Typography>

      <Typography color='text.secondary' sx={{ textTransform: 'uppercase', marginBottom: 2 }}>
        May 10, 2021
      </Typography>

      <Typography variant='div' marginBottom={2}>
      <span style={{ fontWeight: 'bold' }}>About Major Update: </span> 
        DocRecord's new version 7 greatly expands the traditional concept of ECM (electronic content management) by 
        introducing powerful new features that enable advanced and comprehensive work process automation.
        <br/><br/>
        Building upon DocRecord's existing smart workflow capabilities, these new features include RPA (robotic process automation) 
        and ETL (extract, transform, load) capabilities that allow organizations to build even more complex work automation processes that 
        eliminate repetitive manual processing tasks. These expanded and intelligent workflows can perform advanced conditional processing 
        of an organization's data and documents.
        <br/><br/>
      </Typography>
      
      <Typography variant='div' marginBottom={2}>
      <span style={{ fontWeight: 'bold' }}>About DocRecord: </span> 
        DocRecord is a very affordable, all-inclusive electronic content management (ECM) application designed for the midsized 
        organization. It is priced simply by the concurrent user and not by each feature. Its all-inclusive feature set contains: 
        eForms, automated workflows, advanced data capture, ETL (extract, transform, load), RPA (robotic process automation), content 
        archiving, automated personalized document creation, network folder migration, and much more. DocRecord also optionally 
        enables MFP document security that provides real-time monitoring of user activities at Konica Minolta MFPs (multifunction printers) 
        by providing security breach alerts and user audit trails. DocRecord's all-inclusive architecture ensures a scalable and low-cost 
        platform that easily grows as a customer's organization and processing requirements grow. For more information, please visit
        <a href='https://www.prismsoftware.com/solutions/docrecord' style={{ textDecoration: 'none', marginRight: '3px', marginLeft: '3px' }}>https://www.prismsoftware.com/solutions/docrecord</a>  
        and <a href='https://www.prismsoftware.com/solutions/bizhub-secure-alert' style={{ textDecoration: 'none', marginLeft: '3px' }}>https://www.prismsoftware.com/solutions/bizhub-secure-alert</a>.   
        <br/><br/>
      </Typography>

      <Typography variant='div' marginBottom={2}>
      <span style={{ fontWeight: 'bold' }}>About Prism Software: </span> 
        Prism provides software solutions that greatly improve and automate how work is done while substantially lowering costs. 
        Prism specializes in: ECM with work process automation, advanced work process automation, easy no-code REST API integrations 
        and connections, advanced data capture, and automated business connections. 
        Learn more at <a href='/' style={{ textDecoration: 'none' }}>www.prismsoftware.com</a>.
      </Typography>

    </Container>      
    </Box>
            </Box>
          </Container>
          <Contact />
        </Box>

    </Main>
  );
};

export default May10;
