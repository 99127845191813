import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Platform from './Features/Platform.js';
import Intuitive from './Features/Intuitive.js';
import InterestForm from './Features/InterestForm.js';
import SeamlessConnect from './Features/SeamlessConnection'

const FeaturesWPConnect = () => {
    const theme = useTheme();
    
    return (
        <Box>

            <Platform />
            <Box
                position={'relative'}
                paddingTop={1}
                sx={{
                backgroundColor: theme.palette.alternate.main,
                }}
            >
                <Intuitive />

         
            </Box>
            <SeamlessConnect/>
            {/* <PriceTable /> */}



            {/*<InterestForm />*/}
        </Box>
    )
};

export default FeaturesWPConnect;
