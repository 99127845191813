import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from 'components/Container';

import Main from 'layouts/Main';
import Hero from './components/Hero';
import Features from './components/Automate';
import Benefits from './components/Customize';
import More from './components/More';
import HowItWorks from './components/HowItWorks';
import InterestForm from './components/InterestForm';
import Capabilities from './components/Features';
import Automation from './components/Carousel';

const Bizhub = () => {
  const theme = useTheme();
  return (
    <Main>
<Box
        position={'relative'}
        sx={{
          backgroundColor:
            theme.palette.mode === 'light'
              ? theme.palette.primary.main
              : theme.palette.alternate.main,
        }}
      >
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
          sx={{
            display: 'flex',
            position: 'absolute',
            width: '100%',
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 1,
            marginBottom: -0.2,
            height: '35%',
          }}
        >
          <polygon
            fill={theme.palette.background.paper}
            points="0,273 1921,273 1921,0 "
          />
        </Box>
        <Container>
          <Hero />
        </Container>
      </Box>
      <Container>
        <Features />
      </Container>
      <Box paddingTop={{ md: 8 }}>
        <Box bgcolor={'alternate.main'}>
          <Container>
            <Benefits />
          </Container>
        </Box>
      </Box>
      <Container>
        <More />
      </Container>
      <Box bgcolor={'alternate.main'}>
        <Container>
          <HowItWorks />
        </Container>
      </Box>

      <Box bgcolor={'alternate.main'}>
          <InterestForm />
      </Box>     
    </Main>
  );
};

export default Bizhub;