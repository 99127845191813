import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Container from 'components/Container';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import employees from '../svg/employees.svg';
import building from '../svg/building.svg';
import Routes from '../../../../Routes.js'

const Content = () => {
  
  return (
    <Box>
     
      <Grid 
        container 
        position={'relative'}
      >
          <Grid item container sx={{ justifyContent: 'center'}} >
            <Container>
                <Typography variant='h4' style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px'}}>Got Questions?</Typography>
                <Typography color='text.secondary'>
                    Prism Software can provide you solutions that fulfill your organization's operational requirements.
                    <br></br>
                    <br></br>
                    Complete this form and let us know how we can assist you.
                </Typography>
            </Container>
          </Grid>
      </Grid>

      <Grid container 
        position={'relative'}
      >
          <Grid item container sx={{ justifyContent: 'center' }}>
            <Container>
              <Typography variant='h4' style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Need Support?</Typography>
              <Typography color='text.secondary'>
                For technical and product assistance and questions, please visit our  <a href="/support" style={{color: 'primary', textDecoration: "none", PointerEvent: "cursor"}}><b>Support Page</b></a>.
              </Typography>
            </Container>
          </Grid>
      </Grid>
        
    </Box>
  );
};

export default Content;
