import React from 'react';
import Typed from 'react-typed';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import {Helmet} from 'react-helmet'; 
import Typography from '@mui/material/Typography';
import ReactPlayer from 'react-player';

const Video = () => {
    return (
        <Box data-aos={'fade-up'} className='justify-content-center' sx={{ display: 'inline-block', textAlign: 'center' }}>
            <Box marginLeft={{ xs: 'none', sm: '10%', md: '15%' }} marginRight={{ xs: 'none', sm: '10%', md: '15%' }} maxWidth={{ xs: '100%', sm: '80%', md: '70%' }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                <Typography data-aos={'fade-up'} data-aos-delay={100} data-aos-offset={100} className='justify-content-center' variant='h4' marginBottom={{ xs: 2, sm: 5, md: 10 }} sx={{ fontWeight: 700, textAlign: 'center', justifyContent: 'center'  }}>
                    Work Process Automation and Enterprise Content Management with DocRecord
                </Typography>
            </Box>
            <ReactPlayer 
                data-aos={'fade-up'} data-aos-delay={200} data-aos-offset={100}
                width='100%'
                controls='true'
                url="https://player.vimeo.com/video/640477870?h=d9ca313c3a&badge=0&autopause=0&player_id=0&app_id=58479" 
                style={{marginBottom:'50px'}}
            />
        </Box>
    )
};

export default Video;