import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Platform from './Features/Platform.js';
import Content from './Features/Content.js'
import Automation from './Features/Automation.js';
import Support from './Features/Support.js';
import Integration from './Features/Integration.js'
import InterestForm from './Features/InterestForm.js';
import VideoInquiry from './Features/VideoInquiry.js';


const FeaturesDR = () => {
    const theme = useTheme();
    
    return (
        <Box>
            
            <Platform />
           <Box
                position={'relative'}
                sx={{
                backgroundColor: theme.palette.alternate.main,
                }}
            >
                <Content />
                <Box
                    component={'svg'}
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 1920 100.1"
                    sx={{
                        width: '100%',
                        marginBottom: theme.spacing(-1),
                    }}
                >
                <path
                    fill={theme.palette.background.paper}
                    d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                ></path>
                </Box>
            </Box>
            <Automation />
            <Box
                position={'relative'}
                sx={{
                backgroundColor: theme.palette.alternate.main,
                }}
            >
                {/*<Support />*/}
            </Box>
            <Integration />
            {/*<InterestForm />*/}
            <VideoInquiry />
        </Box>
    )
};

export default FeaturesDR;
