import React from 'react';
import Box from '@mui/material/Box';
import { useTheme} from '@mui/material/styles';
import Container from 'components/Container';
import {Helmet} from 'react-helmet'; 

const ContactTemp = () => {
    const theme = useTheme();

    return (
        <div>
            <Helmet>
                <script
                    async
                    type="text/javascript"
                    src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=TPaD2m"
                ></script>
            </Helmet>

            <div class="klaviyo-form-WDG8pq"></div>
        </div>
    )
};

export default ContactTemp;