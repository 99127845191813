import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import capture from '../../../../../images/dr/gifs/capture.gif';
import eform from '../../../../../images/dr/gifs/eform.gif';
import docform from '../../../../../images/dr/gifs/docform.gif';
import network from '../../../../../images/dr/gifs/network.gif';
import workflow from '../../../../../images/dr/gifs/workflow.gif';
import MediaQuery from 'react-responsive';

const Content = () => {
    const theme = useTheme();

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box>
        
        <Box sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>

            <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
            <Typography 
                variant='h4'
                align={'center'}
                data-aos={'fade-up'}
                gutterBottom
                sx={{
                    fontWeight: 700,
                }}>
                Your Content &mdash; Workflow, Archive &amp; Access
            </Typography>
             <Typography
                variant="h6"
                align={'center'}
                color={'text.secondary'}
                data-aos={'fade-up'}
                fontSize='14px'
                marginBottom={10}
                >
                Enabling your organization &mdash; from anywhere
            </Typography>
                <Accordion defaultExpanded expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ left: 'auto', right: 'auto', justifyContent: 'center' }} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ padding: { xs: '8px', sm: '10px', md: '20px' }, marginLeft: { xs: '12px', sm: '20px', md: '60px' } }}
                       
                        >
                            <Typography variant='h6' fontWeight='bold'>Intelligent data capture</Typography>
                        </AccordionSummary>
                        
                        <AccordionDetails > 
                        <Grid container spacing={3}>
                            <Grid item container xs={12} sm={12} md={4} padding={8} justifyContent='left' sx={{ marginLeft: { xs: '12px', sm: '20px', md: '60px' }, marginBottom: { xs: -4, sm: -6, md: 0 } }}>
                                <Typography color="text.secondary" >
                                    Many organizations manually enter data from paper and electronic documents 
                                    into their accounting, ERP, and other applications. DocRecord replaces this 
                                    manual data entry process by automatically extracting 
                                    and entering this data for them.
                                </Typography>
                            </Grid>
                            
                            <Grid item container xs={12} sm={12} md={7} justifyContent='right'>
                                <img src={capture}  style={{ maxWidth: '100%', display: 'flex', flex: 0, padding: 0 }} />
                            </Grid>
                        </Grid>
                        </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ left: 'auto', right: 'auto' }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1b-content"
                        id="panel1b-header"
                        sx={{ padding: { xs: '8px', sm: '10px', md: '20px' }, marginLeft: { xs: '12px', sm: '20px', md: '60px' } }}
                    >
                        <Typography variant='h6' fontWeight='bold'>Content creation with eForms</Typography>
                    </AccordionSummary>
                    <AccordionDetails > 
                    <Grid container spacing={3}>
                            <Grid item container xs={12} sm={12} md={4} padding={8} justifyContent='left' sx={{ marginLeft: { xs: '12px', sm: '20px', md: '60px' }, marginBottom: { xs: -4, sm: -6, md: 0 } }}>
                            <Typography color="text.secondary">
                                Easily create eForms that replace manual forms and 
                                data collection. eForms can include advanced workflows with conditional 
                                processing and triggers, data reviews and approvals, eSigning, document 
                                uploads, and much more. 
                            </Typography>
                        </Grid>
                            
                        <Grid item container xs={12} sm={12} md={7} justifyContent='right'>
                                <img src={eform}  style={{ maxWidth: '100%', display: 'flex', flex: 0, padding: 0 }} />
                        </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ left: 'auto', right: 'auto' }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                        sx={{ padding: { xs: '8px', sm: '10px', md: '20px' }, marginLeft: { xs: '12px', sm: '20px', md: '60px' } }}
                    >
                        <Typography variant='h6' fontWeight='bold'>Communications creation</Typography>
                    </AccordionSummary>
                    <AccordionDetails > 
                    <Grid container spacing={3}>
                            <Grid item container xs={12} sm={12} md={4} padding={8} justifyContent='left' sx={{ marginLeft: { xs: '12px', sm: '20px', md: '60px' }, marginBottom: { xs: -4, sm: -6, md: 0 } }}>
                            <Typography color="text.secondary">
                                Create personalized and automated communications to customers and 
                                others with complex variable data from multiple data sources. Communications 
                                are part of automated workflows and can include images, conditional text, calculations, 
                                graphs and much more. 
                            </Typography>
                        </Grid>
                            
                        <Grid item container xs={12} sm={12} md={7} justifyContent='right'>
                            <img src={docform}  style={{ maxWidth: '100%', display: 'flex', flex: 0, padding: 0 }} />
                        </Grid>
                    </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{ left: 'auto', right: 'auto' }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        sx={{ padding: { xs: '8px', sm: '10px', md: '20px' }, marginLeft: { xs: '12px', sm: '20px', md: '60px' } }}
                    >
                        <Typography variant='h6' fontWeight='bold'>Eliminate network folders</Typography>
                    </AccordionSummary>
                    <AccordionDetails > 
                    <Grid container spacing={3}>
                        <Grid item container xs={12} sm={12} md={4} padding={8} justifyContent='left' sx={{ marginLeft: { xs: '12px', sm: '20px', md: '60px' }, marginBottom: { xs: -4, sm: -6, md: 0 } }}>
                            <Typography color="text.secondary">
                                Your organization likely has multiple network folder systems that contain 
                                important, but unorganized, documents. You can easily integrate these legacy network 
                                folders and their content into DocRecord allowing for immediate access and security. 
                            </Typography>
                        </Grid>
                            
                        <Grid item container xs={12} sm={12} md={7} justifyContent='right'>
                            <img src={network}  style={{ maxWidth: '100%', display: 'flex', flex: 0, padding: 0 }} />
                        </Grid>
                    </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} sx={{ left: 'auto', right: 'auto' }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1e-content"
                        id="panel1e-header"
                        sx={{ padding: { xs: '8px', sm: '10px', md: '20px' }, marginLeft: { xs: '12px', sm: '20px', md: '60px' } }}
                    >
                        <Typography variant='h6' fontWeight='bold'>Secure access from anywhere</Typography>
                    </AccordionSummary>
                    <AccordionDetails > 
                    <Grid container spacing={3}>
                        <Grid item container xs={12} sm={12} md={4} padding={8} justifyContent='left' sx={{ marginLeft: { xs: '12px', sm: '20px', md: '60px' }, marginBottom: { xs: -4, sm: -6, md: 0 } }}>
                            <Typography color="text.secondary">
                                Your organization has a lot of content - DocRecord enables you to access 
                                it from anywhere at anytime - through a desktop app or through your browser. You can 
                                also access your workflows and work process automations from anywhere. 
                            </Typography>
                        </Grid>
                            
                        <Grid item container xs={12} sm={12} md={7} justifyContent='right'>
                            <img src={workflow}  style={{ maxWidth: '100%', display: 'flex', flex: 0, padding: 0 }} />
                        </Grid>
                    </Grid>
                    </AccordionDetails>
                </Accordion>
            </Container>

        </Box>

        </Box>

        
    )
};

export default Content;