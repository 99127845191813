import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import printer from './Printer.svg';

const Features = () => {
  const theme = useTheme();

  const RightSide = () => (
    <Grid container spacing={1}>
      <Grid container item style={{alignItems: 'center', justifyContent: 'center'}}>
        <Box
          width={{ xs: 1, sm: 0.8, md: 1 }}
          height={{ xs: 1, sm: 0.8, md: 1 }}
          marginBottom={{ xs: 0, sm: 5, md: 0 }}
          alignItems='center'
          justifyContent='center'
        >
          <img src={printer} style={{ borderRadius: '25px' }} />
        </Box>
      </Grid>
    </Grid>
  );

  const LeftSide = () => (
    <Box>
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
            color: '#555555'
          }}
          gutterBottom
        >
          All-Inclusive MFP Security
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
          Know who used the MFP for what and when
        </Typography>
        <Typography variant={'p'} sx={{ lineHeight: 1.8 }}>
          Nearly all multifunctional printers (MFPs) have a security risk. Organizations don't know who used the MFP for what and when. 
          This leaves them potentially liable for data breaches that occur at their MFPs. Data breaches at the MFP may expose an 
          organization's important trade secrets and intellectual property information as well as customer, patient, and employee data. 
          Konica Minolta bizhub MFPs don't have this security risk. Select Konica Minolta bizhub MFPs offer an additional level of 
          document and data security.
        </Typography>
      </Box>

      <Box marginTop={4}>
        <Button
          variant={'contained'}
          size={'large'}
          href={
            'https://prismsoftware.com/contact-us/'
          }
        >
          Get started
        </Button>
      </Box>
    </Box>
  );

  return (
    <Grid container spacing={4}>
      <Grid
        item
        container
        justifyContent='center'
        xs={12}
        md={7}
      >
        <LeftSide />
      </Grid>
      <Grid item container alignItems={'center'} justifyContent='center' xs={12} md={5}>
        <RightSide />
      </Grid>
    </Grid>
  );
};

export default Features;
