import * as React from 'react';
import MenuUnstyled from '@mui/base/MenuUnstyled';
import Box from '@mui/material/Box';
import MenuItemUnstyled, {
  menuItemUnstyledClasses,
} from '@mui/base/MenuItemUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import InputBase from '@mui/material/InputBase';
import NativeSelect from '@mui/material/NativeSelect';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SelectUnstyled, { selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import { styled } from '@mui/system';


const blue = {
    95: '#e8f2fc',
    90: '#d1e6fa',
    75: '#8dbff2',
    45: '#1976d2',
};

const grey = {
    100: '#f2f2f2',
    200: '#808080',
    300: '#595959',
};

//TAB STYLING

const Tab = styled(TabUnstyled)`
  font-family: Lato, sans-serif;
  color: #535b5f;
  cursor: pointer;
  font-size: 0.875rem;
  background-color: transparent;
  width: 100%;
  padding: 12px 12px;
  margin: 4px 4px;
  margin-right: 4px;
  border: none;
  border-radius: 8px;
  justify-content: center;

  &:hover {
    background-color: ${blue[95]};
  }


  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[90]};
    color: #474e52;
  }

`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  justify-content: center;
  font-family: Lato, sans-serif;
  font-size: 0.875rem;
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: hidden;
`;

const TabsList = styled(TabsListUnstyled)`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
  align-content: space-evenly;
`;


//TRIGGER BUTTON STYLING

const StyledButton = styled('button')(
  ({ theme }) => `
  font-family: Lato, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width: 200px;
  background: #fff;
  border: 1px solid ${grey[300]};
  border-radius: 0.75em;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  justify-content: center;
  line-height: 1.5;
  color: ${grey[300]};

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : blue[95]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &.${buttonUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[95]};
  }

  &::after {
    content: '▾';
    float: right;
  }
  `,
);


//DROPDOWN MENU STYLING

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-family: Lato, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 2px 3px;
  margin: 10px 0px;
  min-width: 200px;
  background: #fff;
  border: 1px solid ${ grey[300]};
  width: 100%;
  color: ${grey[300]};
  outline: 0px;
  display: flex;
  flex-direction: column;
  `,
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${menuItemUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : blue[95]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : blue[95]};
  }

  &.${menuItemUnstyledClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${menuItemUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : blue[95]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }
  `,
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const Paragraph = styled('p')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  margin: 10px 0;
  color: ${theme.palette.mode === 'dark' ? grey[400] : grey[700]};
  `,
);

const Pre = styled('pre')(
  ({ theme }) => `
  font-family: monospace;
  font-size: 0.875rem;
  margin: 0px;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: ${
    theme.palette.mode === 'dark' ? 'rgba(0, 30, 60, 0.5)' : grey[50]
  };
  color: ${theme.palette.mode === 'dark' ? grey[400] : grey[700]};
  `,
);

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

CustomSelect.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   */
  components: PropTypes.shape({
    Listbox: PropTypes.elementType,
    Popper: PropTypes.func,
    Root: PropTypes.elementType,
  }),
};

function TabPanelMobile(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelMobile.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const categories = [
    { name: "Accounting" },
    { name: "Human Resources" },
    { name: "Legal" },
    { name: "Manufacturing" },
    { name: "Healthcare" },
    { name: "Education" },
];

export default class SmProblems extends React.Component {
    constructor() {
        super();

        this.state = {
            value: 0,
            content: "one",
            name: "Accounting",
            anchorEl: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
    }

    handleChange(event, value) {
        this.setState({ value });
    }

    handleClick(event) {
        event.stopPropagation();
        this.setState({
        anchorEl: event.currentTarget
        });
    }

    handleClose(event) { 
        this.setState({
            anchorEl: null
        });
    }

    handleMenuClick(menuItem) {
        this.handleClose();
        this.setState({
            name: menuItem,
            content: menuItem,
            value: 'more'
        });
    }

    render() {
        const { value } = this.state;
        const open = Boolean(this.state.anchorEl);
        const { classes } = this.props;
        return(
        <div>
            <TabsUnstyled defaultValue={0}>
                    <TabsList
                      orientation="vertical"
                      value={value}
                      onChange={this.handleChange}
                    >
                            
                        <Tab 
                          value={0} 
                          name="Accounting"
                          onClick={this.handleClick}
                          onClick={() => this.setState({ content: "one" })} />
                        
                        <Popover
                          open={open}
                          anchorEl={this.state.anchorEl}
                          onClose={this.state.handleClose}
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center'
                          }}
                          transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center'
                          }}>
                            <StyledOption onClick={() => this.handleMenuClick("Human Resources")}>
                                Human resources
                            </StyledOption>
                            <StyledOption onClick={() => this.handleMenuClick("Legal")}>
                                Legal
                            </StyledOption>
                            <StyledOption onClick={() => this.handleMenuClick("Manufacturing")}>
                                Manufacturing
                            </StyledOption>
                            <StyledOption onClick={() => this.handleMenuClick("Healthcare")}>
                                Healthcare
                            </StyledOption>
                            <StyledOption onClick={() => this.handleMenuClick("Education")}>
                                Education
                            </StyledOption>
                        </Popover>
                        
                    </TabsList>
            </TabsUnstyled>
        </div>
    )
    }
}