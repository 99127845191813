import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ReactPlayer from 'react-player';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MediaQuery from 'react-responsive';
     
const Video = () => {
    return (
    <Box>
    <MediaQuery maxDeviceWidth={700}>
        <Box 
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'stretched', sm: 'flex-start' }}
            justifyContent={'center'}
            marginTop={5}
            >
         <Grid container>
            <Grid item container xs={1}>
            </Grid>
            <Grid item justifyContent='center' container xs={10}>
                <Typography width={'100%'} variant={'h4'} sx={{ fontWeight: 700, textAlign: 'center', justifyContent: 'center', fontSize: '20px' }} gutterBottom>
                        Discover DocRecord to enable work process automation and enterprise content management
                </Typography>
                <ReactPlayer 
                    justifyContent={"center"} 
                    maxWidth={'300px'} 
                    controls='true' 
                    url="https://player.vimeo.com/video/640477870?h=d9ca313c3a&badge=0&autopause=0&player_id=0&app_id=58479" 
                />
            </Grid>
            <Grid item container xs={1}>
            </Grid>
        </Grid>
        </Box>
    </MediaQuery>

    <MediaQuery minDeviceWidth={701}>
        <Box 
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'stretched', sm: 'flex-start' }}
            justifyContent={'center'}
            marginTop={5}
            >
         <Grid container>
            <Grid item container xs={1}>
            </Grid>
            <Grid item justifyContent='center' container xs={10}>
                <Typography width={'100%'} variant={'h4'} sx={{ fontWeight: 700, textAlign: 'center', justifyContent: 'center', marginBottom: '50px' }} gutterBottom>
                        Discover DocRecord to enable work process automation and enterprise content management
                </Typography>
                <ReactPlayer 
                    justifyContent={"center"} 
                    maxWidth={'300px'} 
                    controls='true' 
                    url="https://player.vimeo.com/video/640477870?h=d9ca313c3a&badge=0&autopause=0&player_id=0&app_id=58479" 
                />
            </Grid>
            <Grid item container xs={1}>
            </Grid>
        </Grid>
        </Box>
    </MediaQuery>
    </Box>
    )
};

export default Video;

            
            