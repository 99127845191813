import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import customize from './Customize.gif';
import cs_benefits2 from 'images/cs_benefits2.jpg';

const Benefits = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const LeftSide = () => (
    <Grid container spacing={2} >
      <Grid container item sx={{ alignItems: 'center', display: 'relative' }}>
        <Box
          sx={{ alignItems: 'center' }}
        >
          <img src={customize} style={{ width: '100%', height: '100%', marginTop: 100 }} />
        </Box>
      </Grid>
    </Grid>
  );

  const RightSide = () => (
    <Box>
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
            color: '#555555'
          }}
          gutterBottom
          color={'text.secondary'} // TODO change color
        >
          Powered by DocRecord
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
          Transform any forms and accelerate processing times
        </Typography>
        <Typography color="text.secondary" variant={'h6'}>
          Transform any form using DocRecord's eForm Designer. eForm Designer is part of 
          DocRecord's all-inclusive capabilities. DocRecord users can build a wide range of eForms 
          to collect data from both mobile and desktop platforms.
        </Typography>
      </Box>
        <Typography variant={'h6'} marginBottom={1}>
          DocRecord users can experience all the features of eForm Designer including: 
        </Typography>
      <Grid container spacing={1}>
        {[
          'eSignatures',
          'Workflow and data routing',
          'eForm and data archiving',
          'And many more'
        ].map((item, i) => (
          <Grid item xs={12} key={i}>
            <Box component={ListItem} disableGutters width={'auto'} padding={0}>
              <Box
                component={ListItemAvatar}
                minWidth={'auto !important'}
                marginRight={2}
              >
                <Box
                  component={Avatar}
                  bgcolor={theme.palette.secondary.main}
                  width={20}
                  height={20}
                >
                  <svg
                    width={12}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Box>
              </Box>
              <ListItemText primary={item} />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box
        marginTop={4}
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'flex-start' }}
      >
        <Button
          component={'a'}
          variant="contained"
          color="primary"
          size="large"
          href={
            'https://prismsoftware.com/contact-us/'
          }
        >
          Learn More
        </Button>
        {/* <Box
          marginTop={{ xs: 2, sm: 0 }}
          marginLeft={{ sm: 2 }}
          width={{ xs: '100%', md: 'auto' }}
        >
          <Button
            component={'a'}
            href={'/docs/introduction'}
            variant="outlined"
            color="primary"
            size="large"
            fullWidth={isMd ? false : true}
          >
            Download Datasheet      {/**TODO add download arrow *
          </Button>
        </Box> */}
      </Box>
    </Box>
  );

  return (
    <Grid container spacing={6}>
      <Grid
        item
        xs={12}
        md={6}
      >
        <LeftSide />
      </Grid>
      <Grid item container justifyContent='center' alignItems={'center'} xs={12} md={6}>
        <RightSide />
      </Grid>
    </Grid>
  );
};

export default Benefits;
