import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import FormControlLabel from '@mui/material/FormControlLabel';
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Page from '../../components/Page';
import Main from 'layouts/Main';

const Review = () => {
  return (
    <Main>
      <Page>
        <Box>
          <Box
            display={'flex'}
            alignItems={'baseline'}>
            <GroupIcon fontSize='large' marginRight={'20px'} sx={{ position: 'relative', bottom: -9, marginRight: 2 }} />
            <Typography variant='h5' fontWeight={600} marginBottom={2}>
                Multi-person review-and-approve intelligent workflows
            </Typography>
          </Box>

          <Typography color={'text.secondary'} fontSize='16px' marginBottom={-3} lineHeight={'26px'}>
              <Box maxWidth={620}>
                  Review-and-approve workflows vary substantially in complexity and purpose. DocRecord can 
                  create these intelligent workflows based on a wide range of requirements.
              </Box>
              <br />
                  Many of these intelligent workflows include:
          </Typography>
          <Typography color='text.secondary' fontSize='16px' lineHeight={'36px'} marginLeft={3}>
            <br />
            <ul>
              <li>
                Conditional processing based on a wide range of variables,
              </li>

              <li>
                Database lookups for data verification,
              </li>

              <li>
                Out-of-office tests and conditional routing,
              </li>

              <li>
                Customized workflow action-logging supports review and audit activities,
              </li>

              <li>
                Scheduled data exports (csv, txt, json, xml) of approved/rejected items,
              </li>

              <li>
                Intelligent and conditional team-voting system for item approval/rejection,
              </li>

              <li>
                Active Directory group dynamic/conditional task assignment.
              </li>
            </ul>
          </Typography>
          <Box paddingY={4}>
            <Divider />
          </Box>
          <Box
            display={'flex'}
            alignItems={'baseline'}>
            <AccessTimeIcon fontSize='large' marginRight={'20px'} sx={{ position: 'relative', bottom: -9, marginRight: 2 }} />
            <Typography variant='h5' fontWeight={600} marginBottom={2}>
              Automation speeds processing and greatly reduces costs
            </Typography>
          </Box>

          <Typography color={'text.secondary'} fontSize='16px' maxWidth={650} lineHeight={'26px'}>
            DocRecord is designed to automate work processes to eliminate 
            most of the manual processing, manual data entry, and paper-based 
            activities. This automation greatly speeds the processing times allowing 
            your team to get more done, in less time, with less work.
            <br />
            <br />
            Best of all, workflows can be accessed and processed remotely allowing 
            your team to work from anywhere at any time.
          </Typography>
        </Box>
      </Page>
    </Main>
  );
};

export default Review;
