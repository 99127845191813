import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import capture from '../../svg/prism/landing-capture-1.svg';
import rpa from '../../svg/prism/landing-rpa-icon-1.svg';
import integrations from '../../svg/prism/landing-integrations-icon-1.svg';
import ecm from '../../svg/prism/landing-ecm-icon-1.svg';
import etl from '../../images/ETL-WorkPath-Screenshot.png';
import workflow from '../../images/DocRecord-Workflow.jpg';
import drECM from '../../images/DocRecord-desktop-mobile.jpg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MediaQuery from 'react-responsive';
import integrationsPic from '../../images/integrations.png';
import capturePic from '../../images/capture.png';

// TODO: change media queries to only support sizes where needed to reduce redundancy

const LinkTab = (props) => {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
      />
  );
}

const HomePageIcon = (props) => {
  const theme = useTheme();
  return (
    <Box
      component={'a'}
      display={'block'}
      width={1}
      height={1}
      sx={{
        textDecoration: 'none',
        transition: 'all .2s ease-in-out',
        '&:hover': {
          transform: `translateY(-${theme.spacing(1 / 2)})`,
        },
      }}
    >
      <Box
        component={Card}
        width={1}
        height={1}
        data-aos={'fade-up'}
        data-aos-delay={0 * 100}
        data-aos-offset={100}
        data-aos-duration={600}
        flexDirection={'column'}
        display={'flex'}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            cursor: "pointer"
          }}
        >
          <Box
            component={Avatar}
            width={{ xs: 60, md: 80 }}
            height={{ xs: 60, md: 80 }}
            marginBottom={2}
            src={props.src}
          />

            <Typography
                variant={'h6'}
                gutterBottom

                sx={{ fontWeight: 600, color: '#2d3748' }}
              >
              {props.label}
              </Typography>
        </CardContent>
      </Box>
    </Box>
  );
}

const HomePageTabPanel = (props) => {
  return (
    <TabPanel value={props.value}>
        {/* TODO: refactor into <Grid>/mui styling instead of <Row> and <Col> */}
        <div className='container' style={{ justifyContent: 'center' }}>
          <Row style={{ justifyContent: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: 50 }}>
                  <Col sm={5} style={{ maxWidth: '500px', justifyContent: 'center' }}>

                    <h3>{props.header}</h3>
                    <p style={{ paddingTop: '20px', paddingBottom: '40px' }} >
                      {props.info1}
                      <br/><br/>
                      {props.info2}
                    </p>
                      <Button
                        variant="contained"
                        color="primary"
                        component="a"
                        target="blank"
                        href={props.href}
                        size="large"
                        style={{ textAlign: 'center', justifyContent: 'center' }}
                      >
                        Learn More
                      </Button>
        
                  </Col>
                  <Col sm={5} style={{ justifyContent: 'center' }}>
                  <MediaQuery minDeviceWidth={1399}>
                    <img src={props.src} style={{ justifyContent: 'center', maxWidth: '500px' }} />
                  </MediaQuery>
                  <MediaQuery minDeviceWidth={1188} maxDeviceWidth={1398}>
                    <img src={props.src} style={{ justifyContent: 'center', maxWidth: '455px' }} />
                  </MediaQuery>
                  <MediaQuery minDeviceWidth={992} maxDeviceWidth={1187}>
                    <img src={props.src} style={{ justifyContent: 'center', maxWidth: '380px' }} />
                  </MediaQuery>
                  <MediaQuery minDeviceWidth={800} maxDeviceWidth={992}>
                    <img src={drECM} style={{ justifyContent: 'center', maxWidth: '280px' }} />
                  </MediaQuery>
                  </Col>
              </div>
          </Row>
        </div>
      </TabPanel>
  );
}

const HomePageTabContext = () => {
  const[value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
    <TabContext value={value}>
      <Box style={{ justifyContent: 'center' }}>
       <h2 style={{ justifyContent: 'center', textAlign: 'center', paddingBottom: '15px', paddingTop: '45px', fontSize: '30px' }}>Solutions for improving how you work {'&'} lowering your costs</h2>
       <TabList
        centered
        onChange={handleChange} 
        aria-label="homepage-tabs" 
        style={{ justifyContent: 'center', fontSize: '20px', paddingBottom: '40px' }}
        TabIndicatorProps={{style: {backgroundColor:"white"}}}
        >
            <LinkTab 
              aria-label="ecm" 
              href="https://prismsoftware.com/products/ecm/" 
              value={0}
              style={{ padding: '20px', maxWidth: '300px' }}
              icon={<HomePageIcon src={ecm} label="ECM with Work Process Automation"/>}
            />
  
            <LinkTab 
              aria-label="rpa" 
              href="https://prismsoftware.com/products/prism-workpath/" 
              value={1}
              style={{ padding: '20px', maxWidth: '300px' }}
              icon={<HomePageIcon src={rpa} label="Advanced Work Process Automation with RPA & ETL" />}
            />
  
            <LinkTab 
              aria-label="data" 
              href="http://prismsoftware.com/contact-us/" 
              value={2}
              style={{ padding: '20px', maxWidth: '300px' }}
              icon={<HomePageIcon src={integrations} label="Intelligent Integrations for Data & Applications" />}
            />
  
            <LinkTab 
              aria-label="capture" 
              href="https://prismsoftware.com/products/prism-capture/" 
              value={3}
              style={{ padding: '20px', maxWidth: '300px' }}
              icon={<HomePageIcon src={capture} label="Advanced Data Capture, Extraction & Automation" />}
            />
        </TabList>
        </Box>
        <HomePageTabPanel 
          value={0}
          header="ECM For the Whole Organization"
          info1="DocRecord is an affordable, comprehensive, and all-inclusive ECM 
            (enterprise content management) platform that enables the automation of 
            complex work processes throughout the whole organiziation."
          info2="DocRecord includes advanced data capture from documents,
            advanced workflow with RPA (robotic process automation) and
            ETL (extract, transform, load), eForms, and automated
            document creation - all based on a powerful content/document
            repository system."
          src={drECM}
        />
  
        <HomePageTabPanel 
          value={1}
          header="Data & Document Processing Automation"
          info1="Prism WorkPath is an affordable and easy-to-use platform for the rapid creation of
            complex work process automations for data and documents that enable
            organizations to eliminate their manual processes."
          info2="Prism WorkPath workflows utilize both RPA (robotic process automation) and
            ETL (extract, transform, load) capabilities for automating repetitive tasks.
            WorkPath's module - REST API Connector - enables seamless bidirectional 
            communication between applications."
          src={etl}
        />
  
        <HomePageTabPanel 
          value={2}
          header="Rapid REST API Data Integrations"
          info1="Prism WorkPath's REST API Connector is an affordable, low-code 
          connector that enables the bidrectional data exchange between the REST APIs
          of different applications."
          info2="Easily and quickly enable enterprise application integration and 
          data exchange between different REST APIs without requiring developer resources. 
          Achieve faster data integrations with significantly less work and at significantly less cost."
          src={integrationsPic}
        />
  
        <HomePageTabPanel
          value={3}
          header="Automatically & Intelligently Extract Data"
          info1="Prism Capture is an affordable and easy-to-use platform to accurately capture 
          and extract data from electronic documents. Once extracted, Prism Capture can perform 
          database lookups for data varifications."
          info2="Eliminate tedious manual data extraction for both line-item and key data. 
          Automatically route extracted data to line-of-business application, 
          SharePoint, or other applications. Prism Capture is built for heavy volume and complex 
          documents."
          src={capturePic}
        />
    </TabContext>
    </Box>
  );
}

const Services = () => {
  const theme = useTheme();

  return (
    <div>
    
  {/*FOR MOBILE DEVICES*/}

      <MediaQuery minDeviceWidth={300} maxDeviceWidth={1000}>
        <HomePageTabContext/>
      </MediaQuery>

  {/*FOR BETWEEN DEVICES*/}

      <MediaQuery minDeviceWidth={801} maxDeviceWidth={886}>
        <HomePageTabContext/>
      </MediaQuery>

  {/*FOR DESKTOP DEVICES*/}

      <MediaQuery minDeviceWidth={887} >
        <HomePageTabContext/>
      </MediaQuery>

    </div>
  );
};

export default Services;

