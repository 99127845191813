import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Cards from './industry/Cards.js'
import Main from 'layouts/Main';
import Container from 'components/Container';
import CommonProblems from './industry/IndustryProblems.js'
import Video from './industry/Video.js'
import InterestForm from '../FeaturesDR/Features/InterestForm.js';
import TempTabs from './industry/tempTabs.js';
import useMediaQuery from '@mui/material/useMediaQuery';

const IndustryDR = () => {
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>

      <Cards />
      <Box
         position={'relative'}
         sx={{
         backgroundColor: theme.palette.alternate.main,
         }}
      >
        <Container display='flex' justifyContent='center'>
            {isMd ? <CommonProblems /> : <TempTabs />}
          </Container>
      </Box>
      {/*<Video />*/}
      {/*<InterestForm />*/}
    </Box>
  );
};

export default IndustryDR;
