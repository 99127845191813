import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import automate from './Automate.svg';

const Features = () => {
  const theme = useTheme();

  const RightSide = () => (
    <Grid container spacing={1}>
      <Grid container item style={{ alignItems: 'center', justifyContent: 'center'}}>
        <Box
          alignItems='center'
          width={{ xs: 1, sm: 0.8, md: 1 }}
          height={{ xs: 1, sm: 0.8, md: 1 }}
          justifyContent='center'
        >
          <img src={automate} style={{ alignItems: 'center', borderRadius: '25px', justifyContent: 'center'}} />
        </Box>
      </Grid>
    </Grid>
  );

  const LeftSide = () => (
    <Box>
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
            color: '#555555'
          }}
          gutterBottom
        >
          Connect with your customers
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
          Enable eForms with QR codes
        </Typography>
        <Typography color="text.secondary" variant={'h6'} >
          With Smartphone Forms, your organization can enable electronic Waivers of Liability forms 
          with QR codes. Customers can easily view the electronic form (eForm) with their mobile 
          device. Their browser will display your eForm to capture their acknowledgement of the waiver.
        </Typography>
      </Box>
      <Typography variant={'h6'} marginBottom={1}>
         Create any type of eForm:
      </Typography>
      <Grid container spacing={2}>
        {[
          'Waivers of Liability',
          'Self-Check Form',
          'Registration Forms'
        ].map((item, i) => (
          <Grid item xs={12} key={i}>
            <Box component={ListItem} disableGutters width={'auto'} padding={0}>
              <Box
                component={ListItemAvatar}
                minWidth={'auto !important'}
                marginRight={2}
              >
                <Box
                  component={Avatar}
                  bgcolor={theme.palette.secondary.main}
                  width={20}
                  height={20}
                >
                  <svg
                    width={12}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Box>
              </Box>
              <ListItemText primary={item} />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box marginTop={4}>
        <Button
          variant={'contained'}
          size={'large'}
          href={
            'https://prismsoftware.com/contact-us/'
          }
        >
          Get started
        </Button>
      </Box>
    </Box>
  );

  return (
    <Grid container spacing={4}>
      <Grid
        item
        xs={12}
        md={7}
      >
        <LeftSide />
      </Grid>
      <Grid item container alignItems={'center'} xs={12} md={5}>
        <RightSide />
      </Grid>
    </Grid>
  );
};

export default Features;
