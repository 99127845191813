import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Typography from '@mui/material/Typography';
import img from '../DocRecord-organizational-benefits-team-2.svg';

const Meat = () => {
    const theme = useTheme();
    return (
    <Box>
        <Box sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>

        <Grid justifyContent='center'>
            <Typography
                variant='h4'
                textAlign='center'
                marginTop='40px'
                fontWeight={700}
                textAlign='center'
                paddingLeft={{ xs: 3, sm: 3, md: 0 }}
                paddingRight={{ xs: 3, sm: 3, md: 0 }}
                marginBottom={{ xs: 3, sm: 1, md: 0 }}
                zIndex={1}
                position='relative'
                >
                DocRecord Digitially Transforms Your Organization
            </Typography>
        
            <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
                <Grid container 
                    spacing={2}
                    position={'relative'}
                    marginTop={-10}
                    marginBottom={-10}
                    direction={{ xs: 'column', sm: 'column', md: 'row' }}
                >

                    <Grid item xs={12} sm={12} md={6}>
                        <Container>
                        <Typography color='text.secondary' lineHeight={'28px'}>
                            DocRecord transforms an organization's manual and paper-based work processes, data capture and extraction, document and records archiving, 
                            and data collection to electronic and automated.
                            <br/>
                            <br/>
                            It enables organizations to work much more effectively at a lower cost while improving internal and customer communications.
                        </Typography>
                        </Container>
                    </Grid>

                    <Grid container item justifyContent='center' alignContent='center' xs={12} sm={12} md={6} >
                        <Box
                        width={{ xs: 1, sm: 0.8, md: 0.8 }}
                        height={{ xs: 1, sm: 0.8, md: 0.8 }}
                        marginBottom={{ xs: 0, sm: 0, md: 0 }}
                        justifyContent='center'
                        zIndex={0}
                        marginTop={0}
                        >
                        <img src={img} />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
        </Box>
    </Box>
    )
};

export default Meat;
