import React from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InterestForm from './InterestForm.js';
import Main from 'layouts/Main';
import MediaQuery from 'react-responsive';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function CommonProblems() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box>
        <Main>
            <Grid container marginTop={8}>
                <Grid item container xs={3}>
                    <Box>
                        <Button variant='text' size='small' href='/solutions/docrecord' startIcon={<ArrowBackIcon/>} sx={{ marginLeft: 2, marginBottom: 1 }}>Back</Button>
                        <Divider variant='middle'/>
                        <Typography sx={{ textAlign: 'center', marginBottom: 3, marginTop: 3 }}>
                            Common Problems
                        </Typography>
                        <Tabs
                            orientation="vertical"
                            value={value}
                            onChange={handleChange}
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            
                            <Tab label="Accounting automation" {...a11yProps(0)} />
                            <Tab label="Multi-person review & Workflow" {...a11yProps(1)} />
                            <Tab label="eForms and Workflow" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                </Grid>
                <Grid item container xs={9}>
                    <Box marginBottom={8}>
                        <Box>
                            <Typography variant='h4' fontWeight={700} marginBottom={4}>
                                Common problems we solve
                            </Typography>
                            <Typography color={'text.secondary'} fontSize='16px' marginBottom={4}>
                                DocRecord's work process automation enables cost-effective solutions to common problems
                            </Typography>
                            <Divider variant='middle' width={'100%'} />
                        </Box>

                        <TabPanel value={value} index={0}>
                            <Box
                                display={'flex'}
                                alignItems={'baseline'}>
                                <SettingsIcon fontSize='large' marginRight={'20px'} sx={{ position: 'relative', bottom: -9, marginRight: 2 }} />

                                <Typography variant='h5' fontWeight={600} marginBottom={2}>
                                    Automating unique accounting processes
                                </Typography>
                            </Box>

                            <Typography color={'text.secondary'} fontSize='16px' marginBottom={-3} lineHeight={'26px'}>
                                <Box maxWidth={620}>
                                Accounting processes vary widely by organizations. DocRecord 
                                can create work processes that automate much of your current 
                                manual processing.
                                </Box>
                                <br />
                                While yours will likely vary, many automated accounting 
                                processes include:
                            </Typography>
                            <Typography color='text.secondary' fontSize='16px' lineHeight={'36px'} marginLeft={3}>
                                <br />
                                -    Automatic data capture from emailed and other PDFs such as invoices<br />
                                -    Auto-validating and correcting this extracted data with a database<br />
                                -    Comparing the invoice against open purchase orders<br />
                                -    Routing for payment approvals based on vendor and total amounts<br />
                                -    Emailing the vendor as to the status of their invoice<br />
                                -    Scheduling of payment and check runs <br />
                                -    Automating check printing and accompanying remittance letter<br />
                                -    Archiving all documents in a single, secure repository for instant retrieval<br /><br />
                            </Typography>

                            <Box
                                display={'flex'}
                                alignItems={'baseline'}>
                                <AttachMoneyIcon fontSize='large' marginRight={'20px'} sx={{ position: 'relative', bottom: -9, marginRight: 2 }} />
                                <Typography variant='h5' fontWeight={600} marginBottom={2}>
                                    Automation reduces labor costs and eliminates errors
                                </Typography>
                            </Box>

                            <Typography color={'text.secondary'} fontSize='16px' maxWidth={650} lineHeight={'26px'}>
                                DocRecord is designed to automate work processes to eliminate 
                                most of the manual processing, manual data entry, and paper-based 
                                activities. This automation greatly speeds the processing times allowing 
                                your team to get more done, in less time, with less work.
                                <br />
                                <br />
                                Best of all, workflows can be accessed and processed remotely allowing 
                                your team to work from anywhere at any time.
                            </Typography>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                             <Box
                                display={'flex'}
                                alignItems={'baseline'}>
                                <GroupIcon fontSize='large' marginRight={'20px'} sx={{ position: 'relative', bottom: -9, marginRight: 2 }} />
                                <Typography variant='h5' fontWeight={600} marginBottom={2}>
                                    Multi-person review-and-approve intelligent workflows
                                </Typography>
                            </Box>

                            <Typography color={'text.secondary'} fontSize='16px' marginBottom={-3} lineHeight={'26px'}>
                                <Box maxWidth={620}>
                                Review-and-approve workflows vary substantially in complexity and purpose. DocRecord can 
                                create these intelligent workflows based on a wide range of requirements.
                                </Box>
                                <br />
                                Many of these intelligent workflows include:
                            </Typography>
                            <Typography color='text.secondary' fontSize='16px' lineHeight={'36px'} marginLeft={3}>
                                <br />
                                -    Conditional processing based on a wide range of variables<br />
                                -    Database lookups for data verification<br />
                                -    Out-of-office tests and conditional routing<br />
                                -    Customized workflow action-logging supports review and audit activities<br />
                                -    Scheduled data exports (csv, txt, json, xml) of approved/rejected items<br />
                                -    Intelligent and conditional team-voting system for item approval/rejection <br />
                                -    Active Directory group dynamic/conditional task assignment<br /><br />
                            </Typography>

                            <Box
                                display={'flex'}
                                alignItems={'baseline'}>
                                <AccessTimeIcon fontSize='large' marginRight={'20px'} sx={{ position: 'relative', bottom: -9, marginRight: 2 }} />
                                <Typography variant='h5' fontWeight={600} marginBottom={2}>
                                    Automation speeds processing and greatly reduces costs
                                </Typography>
                            </Box>

                            <Typography color={'text.secondary'} fontSize='16px' maxWidth={650} lineHeight={'26px'}>
                                DocRecord is designed to automate work processes to eliminate 
                                most of the manual processing, manual data entry, and paper-based 
                                activities. This automation greatly speeds the processing times allowing 
                                your team to get more done, in less time, with less work.
                                <br />
                                <br />
                                Best of all, workflows can be accessed and processed remotely allowing 
                                your team to work from anywhere at any time.
                            </Typography>
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                             <Box
                                display={'flex'}
                                alignItems={'baseline'}>
                                <MenuBookIcon fontSize='large' marginRight={'20px'} sx={{ position: 'relative', bottom: -9, marginRight: 2 }} />
                                <Typography variant='h5' fontWeight={600} marginBottom={2}>
                                    Complex multi-page intelligent eForms
                                </Typography>
                            </Box>

                            <Typography color={'text.secondary'} fontSize='16px' marginBottom={-3} lineHeight={'26px'}>
                                <Box maxWidth={620}>
                                eForms vary substantially in complexity, requirements, and purpose. DocRecord can 
                                create a wide range of intelligent eForms for all needs. eForms - single page or complex 
                                multi-page - can be stand-alone, part of a comprehensive workflow, embedded within a website, 
                                or displayed on mobile devices.
                                </Box>
                                <br />
                                Many of these intelligent eForms include:
                            </Typography>
                            <Typography color='text.secondary' fontSize='16px' lineHeight={'36px'} marginLeft={3}>
                                <br />
                                -    Conditional fields displayed and based upon data entry<br />
                                -    Database lookups for conditional data display<br />
                                -    eSignatures<br />
                                -    Image and document uploading<br />
                                -    Desktop and mobile enabled<br />
                                -    Searchable eForms library <br />
                                -    Part of comprehensive work processing workflow<br />
                                -    Can include multi-signature workflow<br /><br />
                            </Typography>

                            <Box
                                display={'flex'}
                                alignItems={'baseline'}>
                                <WorkspacesIcon fontSize='large' marginRight={'20px'} sx={{ position: 'relative', bottom: -9, marginRight: 2 }} />
                                <Typography variant='h5' fontWeight={600} marginBottom={2}>
                                    eForms increase data accuracy, speed processing, and reduce costs
                                </Typography>
                            </Box>

                            <Typography color={'text.secondary'} fontSize='16px' maxWidth={650} lineHeight={'26px'}>
                                DocRecord allows the design of intelligent, complex, and conditional 
                                eForms - for all organizations across all industries. DocRecord greatly 
                                improves data gathering and processing times allowing your 
                                team to get more done, in less time, with less work.
                                <br />
                                <br />
                                eForms power workflows, manufacturing processes, patient and student 
                                data gathering, HR applications, and much more.
                            </Typography>
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>
            <InterestForm />
            </Main>
        </Box>
    )
}

export default CommonProblems
