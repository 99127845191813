import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Container from 'components/Container';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Contact from '../../Contact/Contact.js';
import CircleIcon from '@mui/icons-material/Circle';
import Main from 'layouts/Main';
import { useTheme } from '@mui/material/styles';
import Routes from '../../../../../../Routes.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Jan11WPA = () => {
  const theme = useTheme();
  // mui xs, sm, md responsiveness
  return (
    <Main>
    
        <Box marginTop={{md: 1.8}}>
          <Box bgcolor={theme.palette.primary.main}>
            <Container paddingX={{ xs: 2, sm: 4 }}>
              <Typography
                variant={'h4'}
                gutterBottom
                sx={{
                  fontWeight: 700,
                  color: theme.palette.common.white,
                }}
              >
                News
              </Typography>
              <Typography
                gutterBottom
                sx={{  
                  color: theme.palette.common.white,
                }}
              >
                Latest news of Prism Software
              </Typography>
            </Container>
            <Box
              component={'svg'}
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
              width={1}
              marginBottom={-1}
            >
              <path
                fill={theme.palette.background.paper}
                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
              ></path>
            </Box>
          </Box>
          <Container
        paddingTop={'0 !important'}
        paddingX={{ xs: 5, sm: 4 }}
        position={'relative'}
        top={0}
          >
            <Box
              component={Grid}
              container
              spacing={4}
              flexDirection={{ xs: 'column', md: 'row' }}
            >
    <Box sx={{ justifyContent: 'center' }}>
    <Container>
      <Box marginLeft={'-6px'} marginBottom={'15px'}>
        <Button href='/news' startIcon={<ArrowBackIcon/>} >Back</Button>
      </Box>
      <Typography variant='h5' sx={{ marginBottom: 2 }}>
        Prism Software Announces the Release of WorkPath Advanced
      </Typography>

      <Typography color='text.secondary' sx={{ textTransform: 'uppercase', marginBottom: 2 }}>
        January 11, 2021
      </Typography>

      <Typography variant='div' marginBottom={2}>
      <span style={{ fontWeight: 'bold' }}>About Release: </span> 
        Prism Software announces the release of WorkPath Advanced. It is designed for organizations that have 
        complex data and document work process automation requirements.
        <br/><br/>
        WorkPath Advanced builds upon WorkPath Standard but includes a wider range of additional input, 
        processing, and output capabilities. <span style={{ fontStyle: 'italic', marginRight: '3px' }}>Inputs: </span> Its 
        expanded input capabilities include web pages, databases, JSON, HTML, and other data sources. 
        <span style={{ fontStyle: 'italic', marginRight: '3px', marginLeft: '3px' }}>Processing: </span> Its added processing capabilities 
        include data extraction, correction, and validation. Additionally, it can map HTML web page data and 
        perform data clean up.
        <span style={{ fontStyle: 'italic', marginRight: '3px', marginLeft: '3px' }}>Outputs: </span> Extensive integration with 
        SharePoint, Google Drive, One Drive, Dropbox, Box, and more. This output includes file manipulations 
        such as uploading, downloading, moving, deleting, renaming, and many, many more. Expanded output 
        options also include data types, databases, JSON, and many more.
        <br/><br/>
      </Typography>

      <Typography variant='div' marginBottom={2}>
      <span style={{ fontWeight: 'bold' }}>About Prism WorkPath: </span> 
        WorkPath is a low cost, advanced work process automation application. It's designed to eliminate an organization's complex 
        repetitive manual processes. WorkPath utilizes both RPA (robotic process automation) and ETL (extract-transform-load) capabilities 
        enabling the design and automation of intelligent and comprehensive work processes. WorkPath greatly reduces an organization's 
        costs by reducing labor, significantly improving processing speeds, and eliminating errors. WorkPath Standard is designed for 
        document-centric work processes and Workpath Advanced is for both data-centric and document-centric requirements. WorkPath Connect 
        is a low cost, no-code module that easily and rapidly enables the bidirectional exchange of data between applications' REST APIs. 
        Learn more at 
        <a href='https://www.prismsoftware.com/solutions/workpath' style={{ textDecoration: 'none', marginRight: '3px', marginLeft: '3px' }}>https://www.prismsoftware.com/solutions/workpath</a>  
        and <a href='https://www.prismsoftware.com/solutions/workpath-connect' style={{ textDecoration: 'none', marginLeft: '3px' }}>https://www.prismsoftware.com/solutions/workpath-connect</a>.   
        <br/><br/>
      </Typography>

      <Typography variant='div' marginBottom={2}>
      <span style={{ fontWeight: 'bold' }}>About Prism Software: </span> 
        Prism provides software solutions that greatly improve and automate how work is done while substantially lowering costs. 
        Prism specializes in: ECM with work process automation, advanced work process automation, easy no-code REST API integrations 
        and connections, advanced data capture, and automated business connections. 
        Learn more at <a href='/' style={{ textDecoration: 'none' }}>www.prismsoftware.com</a>.
      </Typography>

    </Container>      
    </Box>
            </Box>
          </Container>
          <Contact />
        </Box>

    </Main>
  );
};

export default Jan11WPA;
