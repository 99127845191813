import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import logo from '../../../../../../svg/prism/prism-logo-svg.svg'
import Routes from '../../../../../../Routes.js';
import NavItem from './components/NavItem';

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    products: productsPages,
    about: aboutPages,
    support: supportPages,
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/homepage"
          title="PrismSoftware"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={logo}
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={'Products'} items={productsPages} />
        </Box>
        <Box>
          <NavItem title={'Support'} items={supportPages} />
        </Box>
        <Box>
          <NavItem title={'About'} items={aboutPages} />
        </Box>

        <Box marginTop={1}>
          <Button
            size={'large'}
            variant="contained"
            color="primary"
            fullWidth
            component="a"
            target="blank"
            href="http://prismsoftware.com/contact-us/"
          >
            Schedule a Demo
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
