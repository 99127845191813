import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  IndexView,
  FeaturesWPConnect as FeaturesWPConnect,
  HomePage as HomePageView,
  OverviewCS as OverviewCSView,
  PrivacyPolicy as PrivacyPolicyView,
  NotFound as NotFoundView,
  WorkPathConnect as WorkPathConnect,
  WorkPath as WorkPathView,
  DocRecord as DocRecordView,
  About as AboutView,
  Accessibility as AccessibilityView,
  ProductsLanding as ProductsLandingView,
  News as NewsView,
  ScanPath as ScanPathView,
  Support as SupportView,
  DocForm as DocFormView,
  Eforms as EformsView,
  CPAccounting as CPAccountingView,
  CPReview as CPReviewView,
  CPWorkflow as CPWorkflowView,
  Smartphone as SmartphoneView,
  Bizhub as BizhubView,
  ScheduleDemo as ScheduleDemoView,
  Careers as CareersView,
} from './views';

import {
  FeaturesWP as FeaturesWPView,
  OverviewWP as OverviewWPView,
  PricingWP as PricingWPView,
} from './views/WorkPath/AllPages';

import {
  Aug12 as Aug12View,
  Aug17 as Aug17View,
  Feb10 as Feb10View,
  Jan11WPA as Jan11WPAView,
  Jan11WPC as Jan11WPCView,
  May10 as May10View,
  Nov15 as Nov15View,
  May3 as May3View,
  May26 as May26View,
} from './views/News/SinglePage/components/Content';

import {
  FeaturesDR as FeaturesDRView,
  OverviewDR as OverviewDRView,
  IndustryDR as IndustryDRView,
  CommonProblems as CommonProblemsView,
} from './views/DocRecord/AllPages';


// Documentation pages
import {
  Introduction as IntroductionView,
  QuickStartReactScripts as QuickStartReactScriptsView,
  QuickStartGatsbyJS as QuickStartGatsbyJSView,
  Colors as ColorsView,
  Layouts as LayoutsView,
  Icons as IconsView,
} from './views/docs';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" render={() => <HomePageView />} />

      <Route exact path="/about" render={() => <AboutView />} />

      <Route exact path="/about/" render={() => <AboutView />} />

      <Route exact path="/news" render={() => <NewsView />} />

      <Route exact path="/news/" render={() => <NewsView />} />
      <Route exact path="/news/workpath-standard-v4.1" render={() => <Nov15View />} />
      <Route exact path="/news/docrecord-v7" render={() => <May10View />} />
      <Route exact path="/news/workpath-connect-release" render={() => <Jan11WPCView />} />
      <Route exact path="/news/workpath-advanced-release" render={() => <Jan11WPAView />} />
      <Route exact path="/news/workpath-standard-v4" render={() => <Aug17View />} />
      <Route exact path="/news/docrecord-v6.6" render={() => <Feb10View />} />
      <Route exact path="/news/docrecord-v6.5" render={() => <Aug12View />} />
      <Route exact path="/news/docrecord-v7.1" render={() => <May3View />} />
      <Route exact path="/news/workpath-v4.2" render={() => <May26View />} />

      <Route exact path="/product" render={() => <ProductsLandingView />} />

      <Route exact path="/product/" render={() => <ProductsLandingView />} />

      <Route exact path="/solutions" render={() => <ProductsLandingView />} />

      <Route exact path="/solutions/" render={() => <ProductsLandingView />} />

      <Route exact path="/privacy" render={() => <PrivacyPolicyView />} />

      <Route exact path="/privacy/" render={() => <PrivacyPolicyView />} />

      <Route exact path="/support" render={() => <SupportView />} />

      <Route exact path="/support/" render={() => <SupportView />} />

      <Route exact path="/careers" render={() => <CareersView />} />

      <Route exact path="/careers/" render={() => <CareersView />} />

      <Route exact path="/accessibility" render={() => <AccessibilityView />} />

      <Route exact path="/accessibility/" render={() => <AccessibilityView />} />

      <Route exact path="/solutions/scanpath" render={() => <ScanPathView />} />

      <Route exact path="/solutions/scanpath/" render={() => <ScanPathView />} />

      <Route exact path="/products/scanpath" render={() => <ScanPathView />} />

      <Route exact path="/products/scanpath/" render={() => <ScanPathView />} />

      <Route exact path="/solutions/docform" render={() => <DocFormView />} />

      <Route exact path="/solutions/docform/" render={() => <DocFormView />} />

      <Route exact path="/products/docform" render={() => <DocFormView />} />

      <Route exact path="/products/docform/" render={() => <DocFormView />} />

      <Route exact path="/solutions/smartphone-forms" render={() => <SmartphoneView />} />

      <Route exact path="/solutions/smartphone-forms/" render={() => <SmartphoneView />} />

      <Route exact path="/products/smartphone-forms" render={() => <SmartphoneView />} />

      <Route exact path="/products/smartphone-forms/" render={() => <SmartphoneView />} />

      <Route exact path="/solutions/eforms" render={() => <EformsView />} />

      <Route exact path="/solutions/eforms/" render={() => <EformsView />} />

      <Route exact path="/products/eforms" render={() => <EformsView />} />

      <Route exact path="/products/eforms/" render={() => <EformsView />} />

      <Route exact path="/solutions/electronic-forms" render={() => <EformsView />} />

      <Route exact path="/solutions/electronic-forms/" render={() => <EformsView />} />

      <Route exact path="/products/electronic-forms" render={() => <EformsView />} />

      <Route exact path="/products/electronic-forms/" render={() => <EformsView />} />

      <Route exact path="/solutions/bizhub-secure-alert" render={() => <BizhubView />} />

      <Route exact path="/solutions/bizhub-secure-alert/" render={() => <BizhubView />} />

      <Route exact path="/products/bizhub-secure-alert" render={() => <BizhubView />} />

      <Route exact path="/products/bizhub-secure-alert/" render={() => <BizhubView />} />

      <Route exact path="/solutions/bizhub" render={() => <BizhubView />} />

      <Route exact path="/solutions/bizhub/" render={() => <BizhubView />} />

      <Route exact path="/products/bizhub" render={() => <BizhubView />} />

      <Route exact path="/products/bizhub/" render={() => <BizhubView />} />

      <Route exact path="/solutions/docaudit" render={() => <BizhubView />} />

      <Route exact path="/solutions/docaudit/" render={() => <BizhubView />} />

      <Route exact path="/products/docaudit" render={() => <BizhubView />} />

      <Route exact path="/products/docaudit/" render={() => <BizhubView />} />

      <Route exact path="/solutions/mfp-document-security" render={() => <BizhubView />} />

      <Route exact path="/solutions/mfp-document-security/" render={() => <BizhubView />} />

      <Route exact path="/products/mfp-document-security" render={() => <BizhubView />} />

      <Route exact path="/products/mfp-document-security/" render={() => <BizhubView />} />

      <Route exact path="/solutions/workpath" render={() => <WorkPathView />} />

      <Route exact path="/solutions/workpath/" render={() => <WorkPathView />} />

      <Route exact path="/products/workpath" render={() => <WorkPathView />} />

      <Route exact path="/products/workpath/" render={() => <WorkPathView />} />

      <Route exact path="/workpath/overview-wp" render={() => <OverviewWPView />} />

      <Route exact path="/workpath/pricing-wp" render={() => <PricingWPView />} />

      <Route exact path="/workpath/features-wp" render={() => <FeaturesWPView />} />

      <Route exact path="/solutions/docrecord" render={() => <DocRecordView />} />

      <Route exact path="/solutions/docrecord/" render={() => <DocRecordView />} />

      <Route exact path="/products/docrecord" render={() => <DocRecordView />} />

      <Route exact path="/products/docrecord/" render={() => <DocRecordView />} />

      <Route exact path="/solutions/docrecord/accounting" render={() => <CPAccountingView />} />

      <Route exact path="/products/docrecord/accounting" render={() => <CPAccountingView />} />

      <Route exact path="/solutions/docrecord/workflow" render={() => <CPWorkflowView />} />

      <Route exact path="/products/docrecord/workflow" render={() => <CPWorkflowView />} />

      <Route exact path="/solutions/docrecord/review" render={() => <CPReviewView />} />

      <Route exact path="/products/docrecord/review" render={() => <CPReviewView />} />

      <Route exact path="/docrecord/features-dr" render={() => <FeaturesDRView />} />

      <Route exact path="/docrecord/industry-dr" render={() => <IndustryDRView />} />

      <Route exact path="/docrecord/overview-dr" render={() => <OverviewDRView />} />

      <Route exact path="/solutions/docrecord/common-problems" render={() => <CommonProblemsView />} />

      <Route exact path="/solutions/docrecord/common-problems/" render={() => <CommonProblemsView />} />

      <Route exact path="/products/docrecord/common-problems" render={() => <CommonProblemsView />} />

      <Route exact path="/products/docrecord/common-problems/" render={() => <CommonProblemsView />} />
      
      <Route exact path="/solutions/workpath-connect" render={() => <WorkPathConnect />} />

      <Route exact path="/solutions/workpath-connect/" render={() => <WorkPathConnect />} />

      <Route exact path="/products/workpath-connect" render={() => <WorkPathConnect />} />

      <Route exact path="/products/workpath-connect/" render={() => <WorkPathConnect />} />

      <Route exact path="/solutions/capture" render={() => <OverviewCSView />} />

      <Route exact path="/solutions/capture/" render={() => <OverviewCSView />} />

      <Route exact path="/products/capture" render={() => <OverviewCSView />} />

      <Route exact path="/products/capture/" render={() => <OverviewCSView />} />

      <Route exact path="/products/prism-capture" render={() => <OverviewCSView />} />

      <Route exact path="/products/prism-capture/" render={() => <OverviewCSView />} />

      <Route exact path="/solutions/prism-capture" render={() => <OverviewCSView />} />

      <Route exact path="/solutions/prism-capture/" render={() => <OverviewCSView />} />

      <Route exact path="/contact" render={() => <ScheduleDemoView />} />

      <Route exact path="/contact/" render={() => <ScheduleDemoView />} />

      <Route exact path="/contact-us" render={() => <ScheduleDemoView />} />

      <Route exact path="/contact-us/" render={() => <ScheduleDemoView />} />

      <Route exact path="/schedule-a-demo" render={() => <ScheduleDemoView />} />

      <Route exact path="/schedule-a-demo/" render={() => <ScheduleDemoView />} />

      <Route exact path="/not-found" render={() => <NotFoundView />} />

      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
