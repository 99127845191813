import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import computer from '../../../../images/dr/cp/computer.jpg';
import papers from '../../../../images/dr/cp/papers.jpg';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { grey, blue } from '@mui/material/colors';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MediaQuery from 'react-responsive';
import { CardActionArea } from '@mui/material';
import Routes from '../../../../Routes.js';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[500]),
  backgroundColor: grey[50],
  '&:hover': {
    backgroundColor: grey[500],
  },
}));

const CommonProbs = () => {
   const theme = useTheme()
   const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

    return (
    <Box>
        <Box
            position={'relative'}
            sx={{
            backgroundColor: theme.palette.alternate.main,
            justifyContent: 'center'
            }}
        >
        <Container>
            <Typography variant='h4' sx={{ textAlign: 'center', justifyContent: 'center', fontWeight: 700, marginBottom: 3 }}>
                Common problems we solve
            </Typography>
            <Typography color="text.secondary" sx={{ textAlign: 'center', justifyContent: 'center', fontSize: '16px' }}>
                DocRecord's work process automation enables cost-effective solutions to common problems
            </Typography>

            <Grid container>
                <Grid item container xs={12} sm={12} md={8} marginBottom={{ xs: 0, sm: 0, md: -8 }}>
                <Container >
                
                    <Card marginBottom={{ xs: 0, sm: 0, md: -2 }} sx={{ display: 'flex', maxWidth: '750px' }}>
                        {isMd && 
                        <CardMedia
                        component="img"
                        sx={{ width: 300 }}
                        image={computer}
                        />
                        }
                    
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h5">
                            Automating unique accounting processes
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                            Accounting processes vary widely by organizations. DocRecord can create work processes that automate much of your current manual processing.
                            
                        </Typography>
                        <Button variant="text" href='/products/docrecord/accounting' color='primary' sx={{ justifyContent: 'right' }}>Read More</Button>
                        </CardContent>
                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                        
                        </Box>
                    </Box>
                    </Card>
                    </Container>
                    <Container marginTop={{ xs: 0, sm: 0, md: -8 }}>
                    <Card sx={{ display: 'flex', maxWidth: '750px' }}>
                    
                    {isMd && 
                        <CardMedia
                        component="img"
                        sx={{ width: 300 }}
                        image={papers}
                        />
                        }
                    
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h5">
                            Automation reduces labor costs and eliminates errors
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                            DocRecord is designed to automate work processes to eliminate most of the manual processing, manual data entry, and paper-based activities.
                            
                        </Typography>
                        <Button variant="text" href='/products/docrecord/accounting' color='primary' sx={{ justifyContent: 'right' }}>Read More</Button>
                        </CardContent>
                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                        
                        </Box>
                    </Box>
                    </Card>
                    </Container>
                </Grid>
                <Grid item container  xs={12} sm={12} md={4} justifyContent={{ xs: 'center', sm: 'center', md: 'right' }}>
                <Container>
                <Card sx={{ display: 'flex', maxWidth: '300px', height: '300px', backgroundColor: '#1976d2' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '25px' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h5" color='white' sx={{ fontWeight: 600 }}>
                            Problems we solve
                        </Typography>
                        <hr style={{ fontColor: '#fff', backgroundColor: '#fff', opacity: 2 }} />
                        <Typography variant="subtitle1" color="white" component="div" marginBottom='20px'>
                            Discover more common problems DocRecord can solve!
                        </Typography>
                        <ColorButton variant="contained" href='/products/docrecord/accounting' endIcon={<ArrowForwardIcon color='primary' />} ><Typography color='primary' fontWeight={600}>View all</Typography></ColorButton>
                        </CardContent>
                    
                    </Box>
                    </Card>

                
                </Container>
            </Grid>
            </Grid>

            </Container>
        </Box>

    </Box>
    )
};

export default CommonProbs;
