import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Typography from '@mui/material/Typography';
import people from './WorkPath-Connect-Apps-people-1.svg'
import app from './WorkPath-Automates-Home-1 (1).svg';
import data from './WorkPath-Connect-Data-Integration-Home-1.svg';
import cog from '../../../../svg/workpath/Simplify-cog.svg';
import cloud from '../../../../images/workpath/cloudBuilding.svg';

const Meat = () => {
    const theme = useTheme();
    return (
    <Box>
        <Box sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>

        <Grid>
            <Typography
                variant='h4'
                textAlign='center'
                marginTop='40px'
                fontSize='26px'
                fontWeight={700}
                >
                Prism WorkPath Connect
            </Typography>
            <Typography
                variant='h5'
                textAlign='center'
                fontStyle='italic'
                fontWeight={600}
                >
                a WorkPath module
                </Typography>
            <Container marginBottom={{ xs: 5, sm: -5, md: -20 }} sx={{ maxWidth: '640px', marginTop: '-30px' }}>
            <Typography
                flex={1}
                textAlign='center'
                justifyContent='center'
                color='text.secondary'
                >
                Intelligent, low cost, no-code application integrations through RESTful APIs &mdash; a module to the Prism 
                WorkPath work process automation platform.
            </Typography>
            </Container>

            <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto', marginTop: -13 }}>
                <Grid container 
                    spacing={2}
                    position={'relative'}
                    marginBottom={-6}
                >

                   <Grid item md={6} >
                   <Container>
                        <Typography variant='h4' style={{ fontWeight: 700, marginBottom: '30px' }}>Seamless exchange of data</Typography>
                        <Typography color='text.secondary'>
                            Automate data exchange between both on-prem and cloud applications. 
                            With WorkPath Connect, you can seamlessly exchange data in real 
                            time between your applications &mdash; whether in the cloud or on premise.
                        </Typography>
                    </Container>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} marginTop={{ xs: -4, sm: -8, md: 5 }} marginBottom={{ xs: 4, sm: 4, md: 0 }}>
                        <Container sx={{ justifyContent: 'center', textAlign: 'center',  }}>
                            <img style={{ maxWidth: '350px', left: 'auto', right: 'auto' }} src={cloud} />
                        </Container>
                    </Grid>

                </Grid>

                <Container>
                <Grid container display='flex' spacing={6} marginBottom={-5}>
                        <Grid item container md={4}>
                            <Typography variant='h5' style={{ fontWeight: 700, marginBottom: '20px' }}>Easy to configure</Typography>
                            <Typography color='text.secondary'>
                                Prism WorkPath Connect is a low cost and no-code connector 
                                designed to easily connect RESTful APIs together to allow 
                                the bidirectional exchange of data.
                            </Typography>
                        </Grid>

                        <Grid item container md={4}>
                            <Typography variant='h5' style={{ fontWeight: 700, marginBottom: '20px' }}>For your enterprise system</Typography>
                            <Typography color='text.secondary'>
                                Easily pass data between your CRM, ERP, accounting, HR, ECM, shopping, 
                                payment processing, messaging, and other applications. 
                            </Typography>
                        </Grid>

                        <Grid item container md={4}>
                            <Typography variant='h5' style={{ fontWeight: 700, marginBottom: '20px' }}>Integrate with Cloud</Typography>
                            <Typography color='text.secondary'>
                                Seamlessly exchange data in real time between your applications &mdash; whether in the cloud or on premise.
                            </Typography>
                        </Grid>
                </Grid>
                </Container>

            </Container>


            <Box>
        <Box sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>

        <Grid justifyContent='center'>

            <Box sx={{ backgroundColor: theme.palette.alternate.main }}>
            <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
                <Grid 
                    container 
                    spacing={2}
                    position={'relative'}
                    marginTop={-10}
                    marginBottom={-10}
                >
                
                    <Grid container item xs={12} md={6} justifyContent='center' >

                        <Container sx={{ justifyContent: 'center', textAlignVertical: 'center' }}>
                        <Typography variant='h4' marginTop={{ xs: 5, sm: 3, md: 0 }} style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Apps and people need to communicate</Typography>
                        <Typography color='text.secondary'>
                            It's difficult for people in your organization to easily communicate with each other &mdash; 
                            or with customers &mdash; if their applications don't seamlessly communicate.
                            <br />
                            <br />
                            Prism WorkPath Connect enables your different applications to easily communicate with 
                            each other and exchange data.
                        </Typography>
                        </Container>
                    </Grid>

                    <Grid container item xs={12} md={6} justifyContent='center'>
                    <Container marginTop={{ xs: 0, sm: -2, md: 8 }} marginBottom={{ xs: 2, sm: 0, md: 0 }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                        <img style={{ maxWidth: '407px', left: 'auto', right: 'auto', textAlign: 'center', marginTop: -40, marginBottom: 40 }} src={people} />
                    </Container>
                    </Grid>
                </Grid>
            </Container>
            </Box>

            <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
                <Grid container 
                    spacing={2}
                    position={'relative'}
                    marginTop={-10}
                    marginBottom={-10}
                    direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}
                >

                    <Grid container justifyContent='center' item md={6}>
                        <Container marginTop={{ xs: -9, sm: -14, md: -8 }} marginBottom={{ xs: 0, sm: -2, md: 3 }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                            <img style={{ maxWidth: '400px', left: 'auto', right: 'auto' }} src={app} />
                        </Container>
                    </Grid>

                    <Grid item md={6}>
                        <Container>
                        <Typography variant='h4' marginTop={{ xs: 5, sm: 3, md: 6 }} style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Easily connect applications and APIs in days</Typography>
                        <Typography color='text.secondary'>
                            Since Prism WorkPath Connect is no-code, you can easily connect your other applications 
                            within days &mdash; saving you both time and expense.
                        </Typography>
                        </Container>
                    </Grid>
                </Grid>
            </Container>

            <Box sx={{ backgroundColor: theme.palette.alternate.main }}>
            <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
                <Grid 
                    container 
                    spacing={2}
                    position={'relative'}
                    marginTop={-10}
                    marginBottom={-20}
                >
                
                    <Grid container item xs={12} md={6} justifyContent='center' >

                        <Container marginTop={{ xs: 0, sm: 0, md: -2 }}  sx={{ justifyContent: 'center', textAlignVertical: 'center' }}>
                        <Typography variant='h4' marginTop={{ xs: 5, sm: 3, md: 6 }} style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Data integration for your enterprise system</Typography>
                        <Typography color='text.secondary'>
                            Prism WorkPath Connect enables all the applications in your enterprise system to seamlessly communicate.
                            <br /><br />
                            Easily pass data between your CRP, ERP, accounting, HR, ECM, shopping, payment processing, 
                            messaging, and other applications.
                        </Typography>
                        </Container>
                    </Grid>

                    <Grid container item xs={12} md={6} justifyContent='center'>
                    <Container marginTop={{ xs: -8, sm: -14, md: -2 }} marginBottom={{ xs: 2, sm: -2, md: 0 }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                        <img style={{ maxWidth: '450px', left: 'auto', right: 'auto', textAlign: 'center', marginTop: -40, marginBottom: 40 }} src={data} />
                    </Container>
                    </Grid>
                </Grid>
            </Container>
            </Box>
        </Grid>
        </Box>
    </Box>

        </Grid>
        </Box>
    </Box>
    )
};

export default Meat;
