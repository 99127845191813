import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Container from 'components/Container';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import Contact from '../../Contact/Contact.js';
import Main from 'layouts/Main';
import { useTheme } from '@mui/material/styles';
import Routes from '../../../../../../Routes.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const May3 = () => {
  const theme = useTheme();
  // mui xs, sm, md responsiveness
  return (
    <Main>
    
    <Box marginTop={{md: 1.8}} sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
      <Box bgcolor={theme.palette.primary.main}>
        <Container >
          <Typography
            variant={'h4'}
            gutterBottom
            sx={{
              fontWeight: 700,
              color: theme.palette.common.white,
            }}
          >
            News
          </Typography>
          <Typography
            gutterBottom
            sx={{  
              color: theme.palette.common.white,
            }}
          >
            Latest news of Prism Software
          </Typography>
        </Container>
        
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          width={1}
          marginBottom={-1}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
      <Container
        paddingTop={'0 !important'}
        paddingX={{ xs: 5, sm: 4 }}
        position={'relative'}
        top={0}
      >
        <Box
          component={Grid}
          container
          spacing={4}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
    <Box sx={{ justifyContent: 'center' }}>
    <Container>
      <Box marginLeft={'-6px'} marginBottom={'15px'}>
        <Button href='/news' startIcon={<ArrowBackIcon/>} >Back</Button>
      </Box>

      <Typography variant='h5' sx={{ marginBottom: 2 }}>
        Prism Software Releases Latest DocRecord; Affordable All-Inclusive ECM with Advanced 
        Work Process Automation
      </Typography>

      <Typography color='text.secondary' sx={{ textTransform: 'uppercase', marginBottom: 2 }}>
        May 3, 2022
      </Typography>

      <Typography variant='div' marginBottom={2}>
      <span>IRVINE, CA &mdash; </span> 
        DocRecord's new v7.1, by Prism Software, continues expanding the capabilities and value of ECM 
        (electronic content management) with intelligent work process automation to midsize organizations. 
        It introduces new enhancements and features that further enable the custom design and rapid 
        deployment of advanced work process automations.
        <br/><br/>
        Previously, DocRecord v7 introduced RPA (robotic process automation) and ETL (extract, transform, load) 
        capabilities as part of its core all-inclusive platform. These capabilities allow organizations to build 
        comprehensive work automation processes that eliminate repetitive manual processing tasks. A quick video 
        highlights DocRecord's all-inclusive capabilities: <a href='https://vimeo.com/640477870/d9ca313c3a' style={{ textDecoration: 'none', marginRight: '3px', marginLeft: '3px' }}>https://vimeo.com/640477870/d9ca313c3a</a>.
        <br/><br/>
        The v7.1 update enhaces DocRecord's ability to design these intelligent and complex work process 
        automations for an organization's data and documents. This update also continues feature additions 
        and refinements to both the web and desktop user-interfaces as well as ease-of-use workflow design.
        <br /><br />
      </Typography>
      
      <Typography variant='div' marginBottom={2}>
      <span style={{ fontWeight: 'bold' }}>About DocRecord: </span> 
        DocRecord is a low cost, all-inclusive electronic content management (ECM) and work process automation 
        application designed for the requirements of the midsized organization. It is priced simply by the 
        concurrent user and not by each feature. Its all-inclusive feature set contains: eForms, automated workflows, 
        advanced data capture, RPA (robotic process automation), ETL (extract, transform, load) content archiving, automated 
        personalized document creation based on variable data, network folder migration, and much more. DocRecord's 
        all-inclusive architecture ensures a scalable and low-cost platform that easily grows as a customer's 
        organization and processing requirements grow. For more information, please visit: 
        <a href='https://www.prismsoftware.com/solutions/docrecord' style={{ textDecoration: 'none', marginRight: '3px', marginLeft: '3px' }}>https://www.prismsoftware.com/solutions/docrecord</a>.

        DocRecord also optionally enables document security for Konica Minolta's MFPs (multifunction printers) with 
        real-time monitoring of user activities. This industry-unique capability lets organizations know who used 
        the MFP for what and when, provides security breach alerts, and keeps user-activity audit trails. For more 
        information, please visit:
        <a href='https://www.prismsoftware.com/solutions/bizhub-secure-alert' style={{ textDecoration: 'none', marginLeft: '3px' }}>https://www.prismsoftware.com/solutions/bizhub-secure-alert</a>.   
        <br/><br/>
      </Typography>

      <Typography variant='div' marginBottom={2}>
      <span style={{ fontWeight: 'bold' }}>About Prism Software: </span> 
        Prism provides software solutions that greatly improve and automate how work is done while substantially lowering costs. 
        Prism specializes in: ECM with work process automation, advanced work process automation, easy no-code REST API integrations 
        and connections, advanced data capture, and automated business connections. 
        Learn more at <a href='/' style={{ textDecoration: 'none' }}>www.prismsoftware.com</a>.
        <br/><br/>
        <a target="_blank" href='https://www.prnewswire.com/news-releases/prism-software-releases-latest-docrecord-affordable-all-inclusive-ecm-with-advanced-work-process-automation-301537763.html?tc=eml_cleartime' style={{ textDecoration: 'none' }}>Read more on PR Newswire</a>
      </Typography>
        
      <Typography style={{ fontWeight: 'bold', marginTop: '30px' }}>Media contact: </Typography>   
      <Typography variant='div' marginBottom={2}>
        CJ Park 
        <br />
        +1 949.771.9854
        <br/>
        <a href="mailto:cpark@prismsoftware.com" style={{ textDecoration: 'none' }}>cpark@prismsoftware.com</a>  
      </Typography>
     
      <Typography style={{ fontWeight: 'bold', marginTop: '30px' }}>Related links: </Typography> 
      <Typography variant='div' marginBottom={2}>
        <a href='/' style={{ textDecoration: 'none' }}>www.prismsoftware.com</a>
        <br/>
        <a href='https://vimeo.com/640477870/d9ca313c3a' style={{ textDecoration: 'none' }}>https://vimeo.com/640477870/d9ca313c3a</a>
      </Typography>

    </Container>      
    </Box>
            </Box>
          </Container>
          <Contact />
        </Box>

    </Main>
  );
};

export default May3;
