import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Helmet} from 'react-helmet'; 
import Main from 'layouts/Main';
import Container from 'components/Container';
import { ContactCard, Content, Contact } from './components';

const About = () => {
  const theme = useTheme();

  return (
    <Main>
      <div>
        <Helmet>
            <script
                async type="text/javascript"
                src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=TPaD2m"
            ></script>
            <script src="https://prismsoftware.bamboohr.com/js/embed.js" type="text/javascript" async defer
            ></script>
        </Helmet>
        <Box marginTop={{md: 1.8}}>
          <Box bgcolor={theme.palette.primary.main}>
            <Container paddingX={{ xs: 2, sm: 4 }}>
              <Typography
                variant={'h4'}
                gutterBottom
                sx={{
                  fontWeight: 700,
                  color: theme.palette.common.white,
                }}
              >
                About Us
              </Typography>
              <Typography
                gutterBottom
                sx={{  
                  color: theme.palette.common.white,
                }}
              >
                We are Prism Software
              </Typography>
            </Container>
            <Box
              component={'svg'}
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
              width={1}
              marginBottom={-1}
            >
              <path
                fill={theme.palette.background.paper}
                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
              ></path>
            </Box>
          </Box>
          <Container
            paddingTop={'0 !important'}
            paddingX={{ xs: 5, sm: 4 }}
            position={'relative'}
            top={0}
          >
            <Box
              component={Grid}
              container
              spacing={4}
              flexDirection={{ xs: 'column', md: 'row' }}
            >

                <Content />
                <Contact />
            </Box>
          </Container>
          <Box
                position={'relative'}
                sx={{
                  backgroundColor: theme.palette.alternate.main,
                }}
                >
                <Container>
                  <Typography variant='h5' sx={{ textAlign: 'center', marginBottom: 3, fontWeight: 600 }}>
                    Can't find the answer you need?
                  </Typography>
                  <Typography sx={{ textAlign: 'center', marginBottom: -10 }}>
                    Let us know how we can help. Our team will respond within 24 hours.
                  </Typography>
                </Container>
                  <Container>
                    <div class="klaviyo-form-R2f9GT"></div>
                  </Container>
                <Box
                  component={'svg'}
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 1920 100.1"
                  sx={{
                    width: '100%',
                    marginBottom: theme.spacing(0),
                  }}
                >
                  <path
                    fill={theme.palette.background.paper}
                    d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                  ></path>
                </Box>
              </Box>
        </Box>
      </div>
    </Main>
  );
};

export default About;
