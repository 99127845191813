import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Typography from '@mui/material/Typography';
import home from '../../../../../../images/dr-home.png'
import automates from './WorkPath-Automates-Home-1.svg';
import work from './WorkPath-Work-Home-1.svg';
import cog from '../../../../../../svg/workpath/Simplify-cog.svg';
import MediaQuery from 'react-responsive';

const Meat = () => {
    const theme = useTheme();
    return (
    <Box>
        <Box sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>

        <Grid justifyContent='center'>
            <Typography
                variant='h4'
                textAlign='center'
                marginTop='40px'
                fontWeight={700}
                textAlign='center'
                >
                Advanced Work Process Automation with RPA {'&'} ETL
            </Typography>
            <Container sx={{ maxWidth: '580px', marginTop: {xs: 0, sm: '-20px', md: '-40px'}, marginBottom: '-20px' }}>
            <Typography
                flex={1}
                textAlign='center'
                justifyContent='center'
                color='text.secondary'
                >
                All-inclusive platform with robotic process automation and 
                extract-transform-load capabilities
            </Typography>
            </Container>

            <Box sx={{ backgroundColor: theme.palette.alternate.main }}>
            <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
                <Grid 
                    container 
                    spacing={2}
                    position={'relative'}
                    marginTop={-10}
                    marginBottom={-10}
                >
                
                    <Grid container item xs={12} md={6} justifyContent='center' >

                        <Container sx={{ justifyContent: 'center', textAlignVertical: 'center' }}>
                        <Typography variant='h4' marginTop={{ xs: 5, sm: 3, md: 0 }} style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Automates your work processes</Typography>
                        <Typography color='text.secondary'>
                            Prism WorkPath automates all types and complexities of work processes. 
                            It is designed to eliminate an organization's repetitive manual processes.<br/><br/>
                            Automating these tedious processes reduces workforce labor, greatly speeds processing 
                            speeds, eliminates paper, and eliminates errors &mdash; all significant cost reductions.
                        </Typography>
                        </Container>
                    </Grid>

                    <Grid container item xs={12} md={6} justifyContent='center'>
                    <Container marginTop={{ xs: 0, sm: -2, md: 4 }} marginBottom={{ xs: 2, sm: 0, md: 0 }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                        <img style={{ maxWidth: '407px', left: 'auto', right: 'auto', textAlign: 'center', marginTop: -40, marginBottom: 40 }} src={automates} />
                    </Container>
                    </Grid>
                </Grid>
            </Container>
            </Box>

            <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
                <Grid container 
                    spacing={2}
                    position={'relative'}
                    marginTop={-10}
                    marginBottom={-10}
                    direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}
                >

                    <Grid container justifyContent='center' item md={6}>
                        <Container marginTop={{ xs: -6, sm: -10, md: -3 }} marginBottom={{ xs: 8, sm: 5, md: 3 }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                            <img style={{ maxWidth: '243px', left: 'auto', right: 'auto' }} src={cog} />
                        </Container>
                    </Grid>

                    <Grid item md={6}>
                        <Container>
                        <Typography variant='h4' marginTop={{ xs: 5, sm: 3, md: 0 }} style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Simplify your process automations</Typography>
                        <Typography color='text.secondary'>
                            Work process automations for both data and documents are simple to create with 
                            Prism WorkPath. It has an intuitive design interface that is easily customized 
                            for a wide range of requirements.
                        </Typography>
                        </Container>
                    </Grid>
                </Grid>
            </Container>

            <Box sx={{ backgroundColor: theme.palette.alternate.main }}>
            <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
                <Grid 
                    container 
                    spacing={2}
                    position={'relative'}
                    marginTop={-10}
                    marginBottom={-10}
                >
                
                    <Grid container item xs={12} md={6} justifyContent='center' >

                        <Container sx={{ justifyContent: 'center', textAlignVertical: 'center' }}>
                        <Typography variant='h4' marginTop={{ xs: 5, sm: 3, md: 0 }} style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Empower how you work with automation</Typography>
                        <Typography color='text.secondary'>
                            Design custom process automations that work the way your organization works. Enable more 
                            efficient data and document processing in a way that's familiar and acceptable to your team.
                        </Typography>
                        </Container>
                    </Grid>

                    <Grid container item xs={12} md={6} justifyContent='center'>
                    <Container marginTop={{ xs: -2, sm: -4, md: 4 }} marginBottom={{ xs: 2, sm: 1, md: 0 }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                        <img style={{ maxWidth: '407px', left: 'auto', right: 'auto', textAlign: 'center', marginTop: -40, marginBottom: 40 }} src={work} />
                    </Container>
                    </Grid>
                </Grid>
            </Container>
            </Box>
        </Grid>
        </Box>
    </Box>
    )
};

export default Meat;
