import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Grid from '@mui/material/Grid';
import Container from 'components/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import interfaceWPC from '../../../../../images/workpath-connect/interface.svg';
import easyEndpoint from '../../../../../images/workpath-connect/easyEndpoint.svg';
import multiEndpoint from '../../../../../images/workpath-connect/multiEndpoint.svg';
import conditionalEndpoint from '../../../../../images/workpath-connect/conditionalEndpoint.svg';
import passData from '../../../../../images/workpath-connect/passData.svg';
import dataLists from '../../../../../images/workpath-connect/dataLists.svg';
import MediaQuery from 'react-responsive';

const Platform = () => {
    const theme = useTheme()
    return (
        <div>
        
        <Container>
            <Typography variant='h4' marginTop={{ xs: -4, sm: -6, md: -8 }} sx={{ textAlign: 'center', justifyContent: 'center', fontWeight: 700, marginBottom: 4 }}>
                Features for Easy Configuration
            </Typography>
        
        <Grid container marginTop={{ xs: -8, sm: -8, md: -8 }} paddingLeft={{ xs: 3, sm: 0, md: 0 }} paddingRight={{ xs: 3, sm: 0, md: 0 }} rowSpacing={10} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6} md={4}>

            <img src={interfaceWPC} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                    Simplified interface for authentication
                </Typography>

                <Typography color="text.secondary" >
                    Easily supports Name and Password (Basic/NTLM) parameter and/or 
                    header values. Includes OAuth support with auto refresh and supports 
                    data preview and test modes.
                </Typography>

            </Grid>
            <Grid item xs={12} sm={6} md={4}>
            <img src={easyEndpoint} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                    Easy endpoint configuration &amp; testing
                </Typography>

                <Typography color="text.secondary" >
                    Easily test endpoints and review outgoing and returned data 
                    from a single easy-to-use interface.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}> 
            <img src={multiEndpoint} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />  
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                    Multi endpoints in a single session
                </Typography>

                <Typography color="text.secondary" >
                    Easily add multiple REST API endpoints to a single session. 
                    Eliminates multiple session logins to test endpoints. 
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
            <img src={conditionalEndpoint} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                Conditional endpoints
                </Typography>

                <Typography color="text.secondary" >
                    Make your endpoints conditional with an easy-to-use expression 
                    syntax &mdash; run an endpoint only when specific data is returned.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
            <img src={passData} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                    Pass data to other endpoints
                </Typography>

                <Typography color="text.secondary" >
                    Apply extensive data and document transforms including: Data validation and 
                    correction, file and image conversions, document clean-up and manipulation, 
                    OCR, document construction, and more.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
            <img src={dataLists} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                    Data lists
                </Typography>

                <Typography color="text.secondary" >
                    Easily handle returned lists of data &mdash; extract single list 
                    items and perform additional processing based on their value.
                </Typography>
            </Grid>

        </Grid>
        </Container>

        </div>
    )
};

export default Platform;