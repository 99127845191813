import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Grid from '@mui/material/Grid';
import Container from 'components/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import dataCapture from '../../../../../svg/prism/datacapture.svg';
import docCreation from '../../../../../svg/prism/doccreation.svg';
import ecm from '../../../../../svg/prism/ecm.svg';
import eforms from '../../../../../svg/prism/eforms.svg';
import intelligent from '../../../../../svg/prism/intelligent.svg';
import rpaEtl from '../../../../../svg/prism/rpaetl.svg';
import MediaQuery from 'react-responsive';

const Platform = () => {
    const theme = useTheme()
    return (
        <div>
        
        <Container>
            <Typography variant='h4' marginTop={{ xs: 0, sm: -2, md: -8 }} sx={{ textAlign: 'center', justifyContent: 'center', fontWeight: 700, marginBottom: 6 }}>
                All-inclusive platform for your content {'&'} your work
            </Typography>
        
        <Grid container paddingLeft={{ xs: 3, sm: 0, md: 0 }} paddingRight={{ xs: 3, sm: 0, md: 0 }} rowSpacing={10} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6} md={4}>

            <img src={ecm} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                    Electronic content repository
                </Typography>

                <Typography color="text.secondary" >
                    Enterprise content management provides a centralized repository for 
                    access to your documents and data from anywhere.
                </Typography>

            </Grid>
            <Grid item xs={12} sm={6} md={4}>
            <img src={intelligent} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                    Intelligent work process automation
                </Typography>

                <Typography color="text.secondary" >
                    Time is precious. We guarantee a high degree of efficiency and a premium 
                    level of service by using the right tools.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}> 
            <img src={eforms} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />  
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                    eForms
                </Typography>

                <Typography color="text.secondary" >
                    We are passionate about what we do and we take pride in achieving the personalised 
                    results that you have in mind.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
            <img src={rpaEtl} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                RPA {'&'} ETL for advanced processing
                </Typography>

                <Typography color="text.secondary" >
                    We keep you in the loop every step of the way. You can rely on a transparent, 
                    comprehensible flow of information.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
            <img src={dataCapture} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                    Automated data capture
                </Typography>

                <Typography color="text.secondary" >
                    Time is precious. We guarantee a high degree of efficiency and a premium 
                    level of service by using the right tools.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
            <img src={docCreation} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                    Automated document creation
                </Typography>

                <Typography color="text.secondary" >
                    We are passionate about what we do and we take pride in achieving the personalised 
                    results that you have in mind.
                </Typography>
            </Grid>

        </Grid>
        </Container>

        </div>
    )
};

export default Platform;

