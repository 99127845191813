import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import logo from '../../../../svg/prism/prism-logo-all-wht.svg';
import Container from 'components/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MediaQuery from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import Routes from '../../../../Routes.js';

const Footer = () => {
  const theme = useTheme();

  //backgroundColor={'#343F53'}

  return (
    <Container >
    <Row >
    <MediaQuery minWidth={900}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
        <Col sm={5}>

          <Box
            display={'flex'}
            component="a"
            href="/"
            title="Prism Software"
            width={100}
          >
            <Box
              component={'img'}
              src={logo}
              height={1}
              width={1}
            />
          </Box>

            <Typography
              align={'center'}
              fontSize={'13px'}
              color='white'
              component={'p'}
              maxWidth={350}
              textAlign={'left'}
              lineHeight={'180%'}
              paddingTop={3}
              >
                  Prism Software provides a range of applications for the processing and managing of documents and data. 
                  These affordable applications enable all size organizations to greatly reduce their costs 
                  and significantly increase their efficiencies. Prism's applications are used by leading 
                  organizations worldwide.
            </Typography>

          </Col>

          <Col md={1}>

            <Box marginTop={1} >
            <Typography fontSize='16px' fontWeight={550} marginBottom={3} color='white'>
            Products
            </Typography>
              <Link
                underline="none"
                href="/solutions/docrecord"
                variant={'subtitle2'}

              >
                <Typography
                  marginBottom={1} color='white' fontSize='14px'>
                    DocRecord
                </Typography>
              </Link>
              <Link
                underline="none"

                href="/solutions/workpath"
                variant={'subtitle2'}
              >
                <Typography
                  marginBottom={1}
                  color='white'
                  fontSize='14px'>
                    WorkPath
                </Typography>
              </Link>
              <Link
                underline="none"

                href="/solutions/capture"
                variant={'subtitle2'}
              >
                <Typography color='white' fontSize='14px'>
                  Capture
                </Typography>
              </Link>
            </Box>


          </Col>

          <Col md={1}>


            <Box marginTop={1} >
            <Typography fontSize='16px' fontWeight={550} marginBottom={3} color='white'>
            Company
            </Typography>
              <Link
                underline="none"
                fontSize='16px'
                href="/about"
                variant={'subtitle2'}
              >
                <Typography color='white' fontSize='14px' marginBottom={1}> 
                  About Us
                </Typography>
              </Link>
                <Link
                underline="none"
                fontSize='16px'
                href="/news"
                variant={'subtitle2'}
              >
                <Typography color='white' fontSize='14px' marginBottom={1}> 
                  News
                </Typography>
              </Link>
              <Link
                underline="none"
                fontSize='16px'
                href="/careers"
                variant={'subtitle2'}
              >
                <Typography color='white' fontSize='14px' marginBottom={1}> 
                  Careers
                </Typography>
              </Link>
              <Link
                underline="none"
                fontSize='16px'
                href="https://prismsoftware.com/contact-us/"
                variant={'subtitle2'}
              >
                <Typography color='white' fontSize='14px' marginBottom={1}> 
                  Contact Us
                </Typography>
              </Link>
            </Box>

          </Col>

          <Col md={1}>
            <Box marginTop={1}>
            <Typography fontSize='16px' fontWeight={550} marginBottom={3} color='white'>
              Support
            </Typography>
                <Link
                  underline="none"
                  fontSize='16px'
                  href="https://prismsoftware.com/support/"

                  variant={'subtitle2'}
                >
                  <Typography color='white' fontSize='14px' marginBottom={1}>
                    Support
                  </Typography>
                </Link>
                <Link
                  underline="none"
                  fontSize='16px'
                  href="/solutions/docrecord/accounting"

                  variant={'subtitle2'}
                >
                  <Typography color='white' fontSize='14px'>
                    Common Problems We Solve
                  </Typography>
                </Link>
            </Box>
            
      

          </Col>
        </div>

         {/* HORIZONTAL SEPARATOR */}
         <Typography color='white'>
          <hr></hr>
        </Typography>

        {/* RESOURCES */}
        <div style={{color: 'white', display: 'flex', alignItems: 'baseline', justifyContent: 'center' }}>
        <Link
            underline="none"
            fontSize='16px'
            href="https://prismsoftware.com/support/"

            variant={'subtitle2'}
          >
            <Typography color='white' fontSize={'14px'} marginRight={'10px'}>
              Support
            </Typography> 
          </Link>
          <Link
            underline="none"
            fontSize='16px'
            href="/privacy"

            variant={'subtitle2'}
          >
            <Typography color='white' fontSize={'14px'} marginRight={'10px'}>
              Privacy
            </Typography> 
          </Link>
          <Link
            underline="none"
            fontSize='16px'
            href="/accessibility"

            variant={'subtitle2'}
          >
            <Typography color='white' fontSize={'14px'} marginRight={'50vw'}>
              Accessibility 
            </Typography>
          </Link>

        {/* SOCIAL MEDIA ICONS & LINKS */}
          <Link
            underline="none"
            fontSize='16px'
            href="https://twitter.com/prismsoftware"

            variant={'subtitle2'}
          >
            <Typography color='white' marginRight={'10px'}>
              <TwitterIcon/>
            </Typography>
          </Link>
          <Link
            underline="none"
            fontSize='16px'
            href="https://www.facebook.com/PrismSoftwareIrvine/"

            variant={'subtitle2'}
          >
            <Typography color='white' marginRight={'13px'}>
              <FacebookIcon/>
            </Typography>
          </Link>
          <Link
            underline="none"
            color='white'
            fontSize='16px'
            href="https://www.linkedin.com/company/prism-software"

            variant={'subtitle2'}
          >
          
            <Typography marginTop='3px' color='white'>  
              <FontAwesomeIcon icon={faLinkedinIn} size="lg" paddingTop='10px'></FontAwesomeIcon>  
            </Typography>        
          </Link>


        
        </div>
        <Grid container marginTop={5}>
          <Grid item container justifyContent='center'>
          <Typography
          justifyContent={'center'}
          variant={'subtitle2'}
          color="white"
          gutterBottom

        >
          &copy; Prism Software 2022, All Rights Reserved
        </Typography>
        </Grid>
        </Grid>
      </MediaQuery>

      <MediaQuery minWidth={599} maxWidth={899}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>


      <Col md={2}>

          <Box
            display={'flex'}
            component="a"
            href="/"
            title="Prism Software"
            width={100}
          >
            <Box
              component={'img'}
              src={logo}
              height={1}
              width={1}
            />
          </Box>

          </Col>

      <Col md={1}>

            <Box marginTop={1} >
            <Typography fontSize='16px' fontWeight={550} marginBottom={3} color='white'>
            Products
            </Typography>
              <Link
                underline="none"
                href="/solutions/docrecord"
                variant={'subtitle2'}

              >
                <Typography
                  marginBottom={1} color='white' fontSize='14px'>
                    DocRecord
                </Typography>
              </Link>
              <Link
                underline="none"

                href="/solutions/workpath"
                variant={'subtitle2'}
              >
                <Typography
                  marginBottom={1}
                  color='white'
                  fontSize='14px'>
                    WorkPath
                </Typography>
              </Link>
              <Link
                underline="none"

                href="/solutions/capture"
                variant={'subtitle2'}
              >
                <Typography color='white' fontSize='14px'>
                  Capture
                </Typography>
              </Link>
            </Box>


          </Col>

          <Col md={1}>


            <Box marginTop={1} >
            <Typography fontSize='16px' fontWeight={550} marginBottom={3} color='white'>
            Company
            </Typography>
              <Link
                underline="none"
                fontSize='16px'
                href="/about"
                variant={'subtitle2'}
              >
                <Typography color='white' fontSize='14px'> 
                  About Us
                </Typography>
              </Link>
              <Link
                underline="none"
                fontSize='16px'
                href="/news"
                variant={'subtitle2'}
              >
                <Typography color='white' fontSize='14px'> 
                  News
                </Typography>
              </Link>
              <Link
                underline="none"
                fontSize='16px'
                href="/careers"
                variant={'subtitle2'}
              >
                <Typography color='white' fontSize='14px'> 
                  Careers
                </Typography>
              </Link>
              <Link
                underline="none"
                fontSize='16px'
                href="https://prismsoftware.com/contact-us/"
                variant={'subtitle2'}
              >
                <Typography color='white' fontSize='14px'> 
                  Contact Us
                </Typography>
              </Link>
            </Box>

          </Col>

          <Col md={1}>
            <Box marginTop={1}>
            <Typography fontSize='16px' fontWeight={550} marginBottom={3} color='white'>
              Support
            </Typography>
                <Link
                  underline="none"
                  fontSize='16px'
                  href="https://prismsoftware.com/support/"

                  variant={'subtitle2'}
                >
                  <Typography color='white' fontSize='14px' marginBottom={1}>
                    Support
                  </Typography>
                </Link>
                <Link
                  underline="none"
                  fontSize='16px'
                  href="/solutions/docrecord/accounting"

                  variant={'subtitle2'}
                >
                  <Typography color='white' fontSize='14px'>
                    Common Problems We Solve
                  </Typography>
                </Link>
            </Box>
            
      

          </Col>

        </div>

        {/* HORIZONTAL SEPARATOR */}
        <Typography color='white'>
          <hr></hr>
        </Typography>

        {/* RESOURCES */}
        <Box sx={{color: 'white', display:'flex', flexDirection: 'row', justifyContent: 'center', padding: '0px 0px' }}>
        <Link
            underline="none"
            fontSize='16px'
            href="https://prismsoftware.com/support/"

            variant={'subtitle2'}
          >
            <Typography color='white' fontSize={'14px'} marginRight={'10px'}>
              Support
            </Typography> 
          </Link>
          <Link
            underline="none"
            fontSize='16px'
            href="/privacy"

            variant={'subtitle2'}
          >
            <Typography color='white' fontSize={'14px'} marginRight={'10px'}>
              Privacy
            </Typography> 
          </Link>
          <Link
            underline="none"
            fontSize='16px'
            href="/accessibility"

            variant={'subtitle2'}
          >
            <Typography color='white' fontSize={'14px'} marginRight={'30vw'}>
              Accessibility 
            </Typography>
          </Link>

        {/* SOCIAL MEDIA ICONS & LINKS */}
          <Link
            underline="none"
            fontSize='16px'
            href="https://twitter.com/prismsoftware"

            variant={'subtitle2'}
          >
            <Typography color='white' marginRight={'10px'}>
              <TwitterIcon/>
            </Typography>
          </Link>
          <Link
            underline="none"
            fontSize='16px'
            href="https://www.facebook.com/PrismSoftwareIrvine/"

            variant={'subtitle2'}
          >
            <Typography color='white' marginRight={'13px'}>
              <FacebookIcon/>
            </Typography>
          </Link>
          <Link
            underline="none"
            color='white'
            fontSize='16px'
            href="https://www.linkedin.com/company/prism-software"

            variant={'subtitle2'}
          >
          
            <Typography marginTop='3px' color='white'>
              <FontAwesomeIcon icon={faLinkedinIn} size="lg" paddingTop='10px'></FontAwesomeIcon>  
            </Typography>
          </Link>

         
        </Box>
        <Grid container marginTop={5}>
          <Grid item container justifyContent='center'>
          <Typography
          justifyContent={'center'}
          variant={'subtitle2'}
          color="white"
          gutterBottom

        >
          &copy; Prism Software 2022, All Rights Reserved
        </Typography>
        </Grid>
        </Grid>
      </MediaQuery>

      
<MediaQuery maxWidth={599}>
  <div style={{paddingLeft: '8%'}} >

      <Col sm={12} justifyContent={"left"} align={'left'} paddingLeft={10} >
          <Box>
            <Box
              display={'flex'}
              component="a"
              href="/"
              title="Prism Software"
              width={100}
              justifyContent="left"
              marginBottom={2}
            >
              <Box
                component={'img'}
                src={logo}
                height={1}
                width={1}
              />
            </Box>

              <Box marginTop={5} >
                <Typography fontSize='16px' fontWeight={600} marginBottom={1} color='white'>
                  Products
                </Typography>
                <Link
                  underline="none"
                  href="/solutions/docrecord"
                  variant={'subtitle2'}
                >
                  <Typography
                    marginBottom={1} color='white' fontSize='14px'>
                      DocRecord
                  </Typography>
                </Link>
                <Link
                  underline="none"

                  href="/solutions/workpath"
                  variant={'subtitle2'}
                >
                  <Typography
                    marginBottom={1}
                    color='white'
                    fontSize='14px'>
                      WorkPath
                  </Typography>
                </Link>
                <Link
                  underline="none"

                  href="/solutions/capture"
                  variant={'subtitle2'}
                >
                  <Typography color='white' fontSize='14px' marginBottom={2}>
                    Capture
                  </Typography>
                </Link>
              </Box>

              <Box marginTop={1} >
                <Typography fontSize='16px' fontWeight={600} marginBottom={1} color='white'>
                Company
                </Typography>
                <Link
                  underline="none"
                  fontSize='16px'
                  href="/about"
                  variant={'subtitle2'}
                >
                  <Typography color='white' fontSize='14px' marginBottom={1}> 
                    About Us
                  </Typography>
                </Link>
                <Link
                  underline="none"
                  fontSize='16px'
                  href="/news"
                  variant={'subtitle2'}
                >
                  <Typography color='white' fontSize='14px' marginBottom={1}> 
                    News
                  </Typography>
                </Link>
                <Link
                  underline="none"
                  fontSize='16px'
                  href="/careers"
                  variant={'subtitle2'}
                >
                  <Typography color='white' fontSize='14px' marginBottom={1}> 
                    Careers
                  </Typography>
                </Link>
                <Link
                  underline="none"
                  fontSize='16px'
                  href="https://prismsoftware.com/contact-us/"
                  variant={'subtitle2'}
                >
                  <Typography color='white' fontSize='14px' marginBottom={2}> 
                    Contact Us
                  </Typography>
                </Link>
              </Box>

              <Box marginTop={1}>
                <Typography fontSize='16px' fontWeight={600} marginBottom={1} color='white'>
                  Support
                </Typography>
                <Link
                  underline="none"
                  fontSize='16px'
                  href="https://prismsoftware.com/support/"

                  variant={'subtitle2'}
                >
                  <Typography color='white' fontSize='14px' marginBottom={1}>
                    Support
                  </Typography>
                </Link>
                <Link
                  underline="none"
                  fontSize='16px'
                  href="/solutions/docrecord/accounting"
                  variant={'subtitle2'}
                >
                  <Typography color='white' fontSize='14px' marginBottom={2}> 
                    Common Problems We Solve
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Col>
        </div>

        {/* HORIZONTAL SEPARATOR */}
        <Typography color='white' width='90%' margin='auto'>
          <hr></hr>
        </Typography>

        {/* SOCIAL MEDIA ICONS & LINKS */}
        <Box style={{ justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
          <Link
            underline="none"
            fontSize='16px'
            href="https://twitter.com/prismsoftware"

            variant={'subtitle2'}
          >
            <Typography color='white' marginRight={'10px'}>
              <TwitterIcon/>
            </Typography>
          </Link>
          <Link
            underline="none"
            fontSize='16px'
            href="https://www.facebook.com/PrismSoftwareIrvine/"

            variant={'subtitle2'}
          >
            <Typography color='white' marginRight={'13px'}>
              <FacebookIcon/>
            </Typography>
          </Link>
          <Link
            underline="none"
            color='white'
            fontSize='16px'
            href="https://www.linkedin.com/company/prism-software"

            variant={'subtitle2'}
          >
          
            <Typography marginTop='3px' color='white'>
              <FontAwesomeIcon icon={faLinkedinIn} size="lg" paddingTop='10px'></FontAwesomeIcon>  
            </Typography>
          </Link>
        </Box>

        {/* RESOURCES */}
        <Box style={{color: 'white', display:'flex', flexDirection: 'row', justifyContent:'center'}}>
        <Link
            underline="none"
            fontSize='16px'
            href="https://prismsoftware.com/support/"

            variant={'subtitle2'}
          >
            <Typography color='white' fontSize={'14px'} marginRight={'10px'}>
              Support
            </Typography> 
          </Link>
          <Link
            underline="none"
            fontSize='16px'
            href="/privacy"

            variant={'subtitle2'}
          >
            <Typography color='white' fontSize={'14px'} marginRight={'10px'}>
              Privacy
            </Typography> 
          </Link>
          <Link
            underline="none"
            fontSize='16px'
            href="/accessibility"

            variant={'subtitle2'}
          >
            <Typography color='white' fontSize={'14px'} marginRight={'10px'}>
              Accessibility 
            </Typography>
          </Link>
          
        </Box>
        <Grid container marginTop={5}>
          <Grid item container justifyContent='center'>
          <Typography
          justifyContent={'center'}
          variant={'subtitle2'}
          color="white"
          gutterBottom

        >
          &copy; Prism Software 2022, All Rights Reserved
        </Typography>
        </Grid>
        </Grid>
        
      </MediaQuery>

    
    </Row>
    
    </Container>
  );
};

export default Footer;
