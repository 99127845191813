import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Grid from '@mui/material/Grid';
import Container from 'components/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import docsdata from '../../../../../../svg/workpath/docsdata.svg';
import conversions from '../../../../../../svg/workpath/conversions.svg';
import etl from '../../../../../../svg/workpath/etlWP.svg';
import rpa from '../../../../../../svg/workpath/rpaWP.svg';
import outputs from '../../../../../../svg/workpath/outputs.svg';
import inputs from '../../../../../../svg/workpath/inputs.svg';
import MediaQuery from 'react-responsive';

const Platform = () => {
    const theme = useTheme()
    return (
        <div>
        
        <Container>
            <Typography variant='h4' marginTop={{ xs: 2, sm: 0, md: -8 }} sx={{ textAlign: 'center', justifyContent: 'center', fontWeight: 700, marginBottom: 4 }}>
                All-inclusive platform for your content {'&'} your work
            </Typography>
        
        <Grid container paddingLeft={{ xs: 3, sm: 0, md: 0 }} paddingRight={{ xs: 3, sm: 0, md: 0 }} rowSpacing={10} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6} md={4}>

                <img src={rpa} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                    RPA (Robotic Process Automation)
                </Typography>

                <Typography color="text.secondary" >
                    RPA (robotic process automation) enables automated work processes 
                    that eliminate repetitive manual processes &mdash; saving labor and 
                    time.
                </Typography>

            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <img src={etl} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                    ETL (Extract-Transform-Load)
                </Typography>

                <Typography color="text.secondary" >
                    ETL (extract-transform-load) enables data and documents to be processed with 
                    a wide range of tools and then output to a wide range of destinations. 
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}> 
                <img src={docsdata} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />  
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                    Process both data {'&'} documents
                </Typography>

                <Typography color="text.secondary" >
                    Most organizations need to process, transform, and workflow both data and 
                    documents often combining them into a final database or output.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <img src={inputs} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                    Inputs
                </Typography>

                <Typography color="text.secondary" >
                    Wide range of file inputs including database, HTML web bage data extraction, 
                    email, JSON, XML, CSV, FTP, SFTP, and online repositories such as 
                    SharePoint, Google Drive, OneDrive, Dropbox, Box, and more.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <img src={conversions} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                    Conversions, OCR {'&'} Transformations
                </Typography>

                <Typography color="text.secondary" >
                    Apply extensive data and document transforms including: Data validation and 
                    correction, file and image conversions, document clean-up and manipulation, 
                    OCR, document construction, and more.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <img src={outputs} style={{ justifyContent: 'center', height: '75px', marginBottom: '20px' }} />
                <Typography variant='h6' sx={{ fontWeight: 600, marginBottom: 2 }} >
                    Outputs
                </Typography>

                <Typography color="text.secondary" >
                    Output your work process to a wide range of choices including cloud 
                    repositories such as SharePoint, email, databases, HTTP, print, FTP/FTPS, 
                    folders, and more.
                </Typography>
            </Grid>

        </Grid>
        </Container>

        </div>
    )
};

export default Platform;