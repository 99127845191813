import React from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import accounting from './images/industry-accounting-1.svg';
import education from './images/industry-education-1.svg';
import legal from './images/industry-legal-1.svg';
import hr from './images/industry-hr-1.svg';
import medical from './images/industry-medical-1.svg';
import manufacturing from './images/industry-manufacturing-1.svg';

const blue = {
    95: '#e8f2fc',
    90: '#d1e6fa',
    75: '#8dbff2',
    45: '#1976d2',
};

const Tab = styled(TabUnstyled)`
  font-family: Lato, sans-serif;
  color: #535b5f;
  cursor: pointer;
  font-size: 0.875rem;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  justify-content: center;

  indicator {
    backgroundColor: white;
  }

  &:hover {
    background-color: ${blue[95]};
  }


  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[90]};
    color: #474e52;
  }

`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  justify-content: center;
  font-family: Lato, sans-serif;
  font-size: 0.875rem;
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: hidden;
`;

const TabsList = styled(TabsListUnstyled)`
  width: 100%;

  min-width: 450px;
  display: flex;
  scrollButtons;
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  align-content: space-evenly;

  indicator {
    backgroundColor: transparent;
    }
`;


function CommonProblems() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box data-aos={'fade-up'} sx={{ width: 0, flexGrow: 1, flex: 1 }}>
                    <Box sx={{ flex: 1, flexWrap: 'wrap' }}>
                        <Typography variant='h4'  sx={{ fontWeight: 700, textAlign: 'center', marginBottom: 3}}>
                            Department {'&'} Industry Problems We Solve
                        </Typography>
                        <Typography color='text.secondary'  sx={{ textAlign: 'center', marginBottom: 5 }}>
                            Automate your work processes, eliminate manual 
                            processing, integrate applications, and greatly 
                            reduce costs.
                        </Typography>
                        <TabsUnstyled defaultValue={0} >
             
                        <TabsList
                        
                            value={value}
                            justifyContent='center'
                            onChange={handleChange}
                            overflow='scroll'
                            scrollButtons
                            >
                            <Tab value={0}>Accounting</Tab>
                            <Tab value={1}>Human Resources</Tab>
                            <Tab value={2}>Legal</Tab>
                            <Tab value={3}>Manufacturing</Tab>
                            <Tab value={4}>Healthcare</Tab>
                            <Tab value={5}>Education</Tab>
                        </TabsList>
       
                            
 
                        <TabPanel value={0} >
                            <Grid container spacing={6}>
                            <Grid item container md={7}>
                            <Typography variant='h5' fontWeight={700} marginTop={4} marginBottom={4}>
                                Accelerate AP & AR Processing
                            </Typography>

                            <Typography color={'text.secondary'} fontSize='16px' lineHeight={'26px'}>
                                Organizations require a wide range of custom accounting process automations that are nearly instant, 
                                error-free, and reduce costs. With Prism's applications, these automated custom and complex processes 
                                are easily built and affordable.
                                <br />
                                <br />
                                These automated accounting processes include data extraction from invoices and other documents, database 
                                lookups and validation, payment exceptions determination, intelligent workflows that include routing 
                                rules and approval guidelines, automated notifications, purchase order and check creations, sending 
                                data to line-of-business application, and much more.
                            </Typography>
                            </Grid>
                            <Grid item container md={5} justifyContent='center' alignItems='center' marginTop={5}>
                                <img src={accounting} style={{ borderRadius: '20px', maxWidth: '400px' }} />
                            </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={1} >
                             <Grid container spacing={6}>
                             <Grid item container md={7}>
                            <Typography variant='h5' fontWeight={700} marginTop={4} marginBottom={4}>
                                Streamline Application & Onboarding Processes
                            </Typography>

                            <Typography color={'text.secondary'} fontSize='16px'  lineHeight={'26px'}>
                                New hire applications and onboarding, as well as current employee record keeping, require 
                                comprehensive and custom workflows and employee communications.
                                <br />
                                <br />
                                Prism provides affordable and customized eForms and workflows for the collection of data and documents 
                                for applicants, and the automated dissemination of documents for new hires. Existing employees require 
                                customized workflows utilizing eForms, custom employee communications, employee records archiving, and much more.
                            </Typography>
                            </Grid>
                            <Grid item container md={5} justifyContent='center' alignItems='center' marginTop={5}>
                                 <img src={hr} style={{ borderRadius: '20px', maxWidth: '400px' }} />
                            </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={2} >
                            <Grid container spacing={6}>
                            <Grid item container md={7}>
                            <Typography variant='h5' fontWeight={700} marginBottom={4} marginTop={4}>
                                Enable Instant Document Access & Automated Workflows
                            </Typography>

                            <Typography color={'text.secondary'} fontSize='16px' lineHeight={'26px'}>
                                Law firms and legal departments require automated operational improvements that help minimize labor costs 
                                and speed response and communication times while substantially improving digital record keeping and 
                                remote retrieval.
                                <br />
                                <br />
                                Prism's DocRecord provides this wide range of affordable automations including document versioning, 
                                document check-in and check-out, intelligent custom workflows, automated email notifications, eForms, 
                                document retention policies, case management, data extraction from documents, and much more.
                            </Typography>
                            </Grid>
                            <Grid item container md={5} justifyContent='center' alignItems='center' marginTop={5}>
                                <img src={legal} style={{ borderRadius: '20px', maxWidth: '400px' }} />
                            </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={3}>
                            <Grid container spacing={6}>
                            <Grid item container md={7}>
                            <Typography variant='h5' fontWeight={700} marginTop={4} marginBottom={4} >
                                Lower Cost of Goods Sold & Improve Manufacturing Times
                            </Typography>

                            <Typography color={'text.secondary'} fontSize='16px' lineHeight={'26px'}>
                                Manufacturing operations require highly customized work automation processes that lower costs, 
                                speed production times, reduce errors, improve record keeping, and provide accurate and timely 
                                communications to all stakeholders.
                                <br />
                                <br />
                                Prism provides this affordable and highly customized set of automated processes and communications. 
                                This automation includes extracting data from incoming sales orders, creating manufacturing and 
                                supporting documents, intelligent workflows with multi-stage approvals, auto-generation of 
                                manufacturing and compliance documents, routing status and other data to other departments, and much more.
                            </Typography>
                            </Grid>
                            <Grid item container md={5} justifyContent='center' alignItems='center' marginTop={5}>
                                <img src={manufacturing} style={{ borderRadius: '20px', maxWidth: '400px' }} />
                            </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={4} >
                            <Grid container spacing={6}>
                            <Grid item container md={7}>
                            <Typography variant='h5' fontWeight={700} marginBottom={4} marginTop={4}>
                                Significantly Improve Data Gathering & Communications
                            </Typography>

                            <Typography color={'text.secondary'} fontSize='16px'  lineHeight={'26px'}>
                                Healthcare organizations require a wide range of automations that help improve patient care and response times, 
                                greatly speed data collection, and enable instant record retrieval.
                                <br />
                                <br />
                                Prism provides affordable, custom, and complex automations for every department in healthcare. These automations 
                                include data gathering eForms, intelligent workflows with multi-level and conditional approvals, discharge 
                                alerts, patient notifications, accounting data, and much more.
                            </Typography>
                            </Grid>
                            <Grid item container md={5} justifyContent='center' alignItems='center' marginTop={5}>
                                <img src={medical} style={{ borderRadius: '20px', maxWidth: '400px' }} />
                            </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={5}>
                            <Grid container spacing={6}>
                            <Grid item container md={7}>
                            <Typography variant='h5' fontWeight={700} marginBottom={4} marginTop={4}>
                                Significantly Improve Student & Faculty Operations
                            </Typography>

                            <Typography color={'text.secondary'} fontSize='16px'  lineHeight={'26px'}>
                                Education organizations require a uniquely wide range of automations that greatly ease and improve 
                                all aspects of campus life, for both students and faculty. These process automations include 
                                enrollments and admissions, financial aid, accounting, HR, student records, faculty records, healthcare, 
                                and many other aspects. 
                                <br />
                                <br />
                                Prism provides affordable, custom, and complex automations for many campus data and document work processes. 
                                These automations include data gathering eForms, intelligent workflows with conditional routing, student 
                                notifications, routing data to other campus applications, automated delectronic document and booklet 
                                creations, email notifications, data extraction, document portfolio assembly, document retention policy, 
                                remote access to archives, and much more.
                            </Typography>
                            </Grid>
                            <Grid item container md={5} justifyContent='center' alignItems='center' marginTop={12}>
                                <img src={education} style={{ borderRadius: '20px', maxWidth: '400px' }} />
                            </Grid>
                            </Grid>
                        </TabPanel>
                
                        </TabsUnstyled>
                
            </Box>
        </Box>
    )
}

export default CommonProblems;
