import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';

const Content = () => {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  return (
    <Box>
      {/*item 1 */}
      <Typography component={'p'} color={'text.secondary'} marginBottom={3}>
        Prism Software ("Prism", "us", or "we") is committed to protecting consumer privacy both online and within our software 
        products. Prism Software has created this privacy statement to demonstrate its firm commitment to secure and trustworthy 
        internet use and commerce, software product use and the individual's privacy.
      </Typography>

      {/*item 2*/}
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 600,
        }}
      >
        Acceptance of the Privacy Policy
      </Typography>
      <Typography component={'p'} color={'text.secondary'} marginBottom={3}>
        The privacy practices of this statement ("Privacy Policy") apply to our website, software services, professional services, 
        software products, and web-based applications (collectively, "Services") from Prism. By accessing or using our Services you agree 
        to be bound by the terms and conditions of this Privacy Policy. If you do not agree, please do not use or access our Services.
      </Typography>
      {/*item 3*/}
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 600,
        }}
      >
        Changes to the Privacy Policy
      </Typography>
      <Typography component={'p'} color={'text.secondary'} marginBottom={3}>
        Prism reserves the right to make changes to the Privacy Policy without notifying you. The most current version of the policy can be 
        reviewed by clicking on the "Privacy Policy" link located at the bottom of our web pages. Your continued use of this website and 
        our Services following the posting of changes to the Privacy Policy will mean you accept those changes. Please note Prism will 
        always use your personal information according to the Privacy Policy effective at the time such information was provided.
      </Typography>
      {/*item 4*/}
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 600,
        }}
      >
        Information We Collect
      </Typography>
      <Typography component={'p'} color={'text.secondary'} marginBottom={3}>
        This Privacy Policy applies to all users ("Consumers") of our Services, including those who have used our websites. Certain portions 
        of the Services and websites may require that you submit personal information, such as your name, title, company name, and email address.
        <br/>
        Prism Software collects your personal information in order to:<br/><br/>
          <ul>
            <li>
              Process and fulfill your requests for information, products, and services,
            </li>

            <li>
              Solicit your opinions and other feedback,
            </li>

            <li>
              Conduct research,
            </li>

            <li>
              Customize the advertising and content you see and receive,
            </li>

            <li>
              Provide information for internal reporting, metrics, and/or statistics, and
            </li>

            <li>
              Improve our products and services.
            </li>
          </ul>
      </Typography>
      {/*item 5*/}
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 600,
        }}
      >
        Use and Sharing of Your Personal Information 
      </Typography>
      <Typography component={'p'} color={'text.secondary'} marginBottom={3}>
        Prism will not sell, rent, or in any way intentionally disclose personal information that you 
        provide to us in connection with the Services to any third party (other than an agent acting 
        on our behalf as described above), unless: 
        <br/>
        <br/>
        <ul>
          <li>
             Prism is required to do so by law, regulation, warrant, subpoena or court order,
          </li>

          <li>
             Prism is required in urgent circumstances to protect the personal safety of Prism 
             employees, users of Prism products or services, or members of the public, or to enforce 
             its policies and any terms and conditions applicable to its products and Services, or  
          </li>

          <li>
            Such disclosure is part of a sale of all (or substantially all) of the assets of one or more divisions 
            or affiliates of Prism.
          </li>
        </ul>
        Additionally, Prism may be required to disclose personal data in response to lawful requests by 
        public authorities, including to meet national security or law enforcement requirements.
      </Typography>
      {/*item 6*/}
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 600,
        }}
      >
        Protection of Personal Information
      </Typography>
      <Typography component={'p'} color={'text.secondary'} marginBottom={3}>
        The security of your personal information is a high priority for Prism. To protect the privacy of 
        personal information provided by you, Prism employs industry-standard controls including physical 
        access controls, Internet firewalls, intrusion detection, and network monitoring. Additionally, only 
        authorized administrators and staff have access to systems containing such information. Although Prism 
        cannot take responsibility for any theft, misuse, unauthorized disclosure, loss, alteration or destruction 
        of data by a third party, we take precaution to help prevent such occurrences.  
      </Typography>
      {/*item 7*/}
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 600,
        }}
      >
        Personal Information Access Request
      </Typography>
      <Typography component={'p'} color={'text.secondary'} marginBottom={3}>
        We acknowledge the right of individuals to access, correct, and delete their information. Requests 
        to access personal information should be submitted to{' '}
        <a href='mailto:privacy@prismsoftware.com' style={{ textDecoration: 'none' }}>privacy@prismsoftware.com</a>{' '}
        with the subject line “Personal Information Access Request.” 
        <br/>
        We will also provide an individual opt-out or opt-in choice before we share their data with third parties other 
        than our agents, or before we use it for a purpose other than which it was originally collected or 
        subsequently authorized. To limit the use and disclosure of your personal information, please submit a 
        written request to <a href='mailto:privacy@prismsoftware.com' style={{ textDecoration: 'none' }}>privacy@prismsoftware.com</a>.
      </Typography>
      {/*item 8*/}
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 600,
        }}
      >
        California Statutory Rights
      </Typography>
      <Typography component={'p'} color={'text.secondary'} marginBottom={3}>
        In accordance to California Civil Code sections 1798.83 - 1798.84, California consumer residents have the right to know certain 
        information about our data practices. They can ask us for a notice describing the following from us:
        <br/>
        <br/>
        <ul>
          <li>
             The categories of personal information we have collected about them,
          </li>

          <li>
             The categories of sources from which the personal information was collected,
          </li>

          <li>
            The categories of personal information about them that we disclosed for a business purpose or sold, 
          </li>

          <li>
            The categories of third parties to whom the personal information was disclosed for a business purpose or sold, and 
          </li>

          <li>
            The business or commercial purpose for collecting or selling the personal information.
          </li>
        </ul>
        If you are a California resident and would like a copy of this notice, please submit a written request 
        to the following address: <br/>
        Prism Software <br/>
        Attn: Privacy Policy <br/>
        184 Technology Dr. Suite 201 <br/>
        Irvine, CA 92618<br/>
        Please allow 30 business days for a response.
      </Typography>
      {/*item 9*/}
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 600,
        }}
      >
        Cookies
      </Typography>
      <Typography component={'p'} color={'text.secondary'} marginBottom={3}>
        Cookies are files with small amounts of data, which may include an anonymous unique identifier. 
        Cookies are sent to your browser from a website and stored on your computer's hard drive. 
        Cookies set by the website or service provider are called 'first-party cookies.' Cookies set 
        by parties other than the website are called 'third-party cookies.' These third-party cookies enable 
        features and functionality to be provided on the website (i.e. analytics and interactive content).
        <br/>
        <br/>
        You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help 
        for how to do this). Be aware that disabling cookies will affect the functionality of this and many other 
        websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features 
        of this site. The specific types of first-and third-party cookies we use are:
        <br/>
        <br/>
        <ul>
          <li>
            Email newsletter related cookies: Prism offers email subscription services or notification and cookies may be used 
            to remember if you are already registered and whether to show certain notifications which might only be 
            valid to subscribed or unsubscribed users, and
          </li>

          <li>
            Form related cookies: when you submit data to a form such as those found on our Schedule a Demo page, cookies 
            may be set to remember your user details for future correspondence.
          </li>
        </ul>
      </Typography>
      {/*item 10*/}
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 600,
        }}
      >
        Third-Party Cookies
      </Typography>
      <Typography component={'p'} color={'text.secondary'} marginBottom={3}>
        The following section details which third-party cookies you might encounter through this site.
        <br/>
        <br/>
        <ul>
          <li>
            Prism uses Google Analytics (which is one of the most widespread and trusted analytics solutions 
            on the web) to help us understand how we can improve 
            your experience of our website. These cookies may track things such as how long you spend on the site and 
            the pages that you visit so we can continue to produce engaging content. For more information 
            on Google Analytics cookies, see the official Google Analytics page.
          </li>

          <li>
            From time to time, we test new features and make subtle changes to the way that the site is delivered. 
            When we are still testing new features, these cookies may be used to ensure that you receive a consistent 
            experience.
          </li>
        </ul>
      </Typography>
      {/*item 11*/}
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 600,
        }}
      >
        Questions
      </Typography>
      <Typography component={'p'} color={'text.secondary'} >
        If you have questions to report regarding this Privacy Policy or about the security practices of Prism 
        Software, please contact us by email:{' '}
        <a href='mailto:privacy@prismsoftware.com' style={{ textDecoration: 'none' }}>privacy@prismsoftware.com</a> 
        or by mail at: <br/>
        Prism Software <br/>
        Attn: Privacy Policy <br/>
        184 Technology Dr. Suite 201 <br/>
        Irvine, CA 92618
        <br/>
        <br/>
        Copyright Prism Software &copy;2022 - All Rights Reserved
      </Typography>
    </Box>
  );
};

export default Content;
