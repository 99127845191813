import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import Page from '../../components/Page';
import Main from 'layouts/Main';

const Workflow = () => {

  return (
    <Main>
      <Page>
        <Box>
          <Box
            display={'flex'}
            alignItems={'baseline'}>
            <MenuBookIcon fontSize='large' marginRight={'20px'} sx={{ position: 'relative', bottom: -9, marginRight: 2 }} />
            <Typography variant='h5' fontWeight={600} marginBottom={2}>
                Complex multi-page intelligent eForms
            </Typography>
          </Box>

          <Typography color={'text.secondary'} fontSize='16px' marginBottom={-3} lineHeight={'26px'}>
              <Box maxWidth={620}>
                  eForms vary substantially in complexity, requirements, and purpose. DocRecord can create a wide range of intelligent eForms 
                  for all needs. eForms &mdash; single page or complex multi-page &mdash; can be stand-alone, part of a comprehensive workflow, 
                  embedded within a website, or displayed on mobile devices.
              </Box>
              <br />
                  Many of these intelligent eForms include:
          </Typography>
          <Typography color='text.secondary' fontSize='16px' lineHeight={'36px'} marginLeft={3}>
            <br />
            <ul>
              <li>
                Conditional fields displayed and based upon data entry,
              </li>

              <li>
                Database lookups for conditional data display,
              </li>

              <li>
                eSignatures,
              </li>

              <li>
                Image and document uploading,
              </li>

              <li>
                Desktop and mobile enabled,
              </li>

              <li>
                Searchable eForms library,
              </li>

              <li>
                Part of comprehensive work processing workflow,
              </li>

              <li>
                Can include multi-signature workflow.
              </li>
            </ul>
          </Typography>
          <Box paddingY={4}>
            <Divider />
          </Box>
          <Box
            display={'flex'}
            alignItems={'baseline'}>
            <WorkspacesIcon fontSize='large' marginRight={'20px'} sx={{ position: 'relative', bottom: -9, marginRight: 2 }} />
            <Typography variant='h5' fontWeight={600} marginBottom={2}>
              eForms increase data accuracy, speed processing, and reduce cost
            </Typography>
          </Box>

          <Typography color={'text.secondary'} fontSize='16px' maxWidth={650} lineHeight={'26px'}>
            DocRecord allows the design of intelligent, complex, and conditional eForms &mdash; for all 
            organizations across all industries. DocRecord greatly improves data gathering and processing times 
            allowing your team to get more done, in less time, with less work.
            <br />
            <br />
            eForms power workflows, manufacturing processes, patient and student data gathering, HR applications, 
            and much more.
          </Typography>
        </Box>
      </Page>
    </Main>
  );
};

export default Workflow;
