import React from 'react';
import { useTheme } from '@mui/material/styles';
import Typed from 'react-typed';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MediaQuery from 'react-responsive';

const Capabilities = () => {
    const theme = useTheme();
    return (
      <Box>
      <MediaQuery maxDeviceWidth={499}>
        <Box
              justifyContent='center'
              display={'block'}
              maxWidth='330px'
              sx={{
                marginTop: '20px',
                marginBottom: '40px',
                marginLeft: 'auto',
                marginRight: 'auto',
                textDecoration: 'none',
                boxShadow: '3px 8px 8px 3px rgba(0,0,0,0.2)',
                borderRadius: '10px'
              }}
            >

        <Card
                justifyContent='center'
                data-aos={'fade-up'}
                data-aos-delay={1 * 100}
                data-aos-duration={600}
                flexDirection={'column'}
                display={'flex'}
                maxWidth='300px'
              >
        
          <Box>
          <CardContent >
          
            <Box padding={1} marginBottom={2}>
              <Typography variant={'h5'} sx={{ fontWeight: 700, textAlign: 'center', fontSize: '22px', marginBottom: '30px' }} gutterBottom>
                Advanced capabilities for all types of work process automations
              </Typography>
                
            
            <Grid container spacing={1}>
              {[
                'Automatic work processes with RPA (robotic process automation)',
                'Automated document processing with ETL (extract-transform-load)',
                'Database pull/push with data validation & processing',
                'File conversion, OCR data extraction & image correction',
                'HTML input for web page data extraction',
                'Wide range of file inputs including JSON, XML & CSV',
                'Extensive file output options',
              ].map((item, i) => (
                <Grid item xs={12} sm={6} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText 
                        disableTypography
                        primary={<Typography color='text.secondary' style={{ fontSize: 14 }}>{item}</Typography>} />
                  </Box>
                </Grid>
              ))}
            </Grid>
            </Box>
            </CardContent>
          </Box>

        </Card>
        </Box>
      </MediaQuery>

      <MediaQuery minDeviceWidth={500} maxDeviceWidth={700}>
        <Box
              justifyContent='center'
              display={'block'}
              maxWidth='500px'
              sx={{
                marginTop: '30px',
                marginBottom: '40px',
                marginLeft: 'auto',
                marginRight: 'auto',
                textDecoration: 'none',
                boxShadow: '3px 8px 8px 3px rgba(0,0,0,0.2)',
                borderRadius: '10px'
              }}
            >

        <Card
                justifyContent='center'
                data-aos={'fade-up'}
                data-aos-delay={1 * 100}
                data-aos-duration={600}
                flexDirection={'column'}
                display={'flex'}
                maxWidth='300px'
              >
        
          <Box>
          <CardContent >
          
            <Box padding={5} marginBottom={2}>
              <Typography variant={'h4'} sx={{ fontWeight: 700, textAlign: 'center', fontSize: '26px', marginBottom: '30px' }} gutterBottom>
                Advanced capabilities for all types of work process automations
              </Typography>
                
            
            <Grid container spacing={1}>
              {[
                'Automatic work processes with RPA (robotic process automation)',
                'Automated document processing with ETL (extract-transform-load)',
                'Database pull/push with data validation & processing',
                'File conversion, OCR data extraction & image correction',
                'HTML input for web page data extraction',
                'Wide range of file inputs including JSON, XML & CSV',
                'Extensive file output options',
              ].map((item, i) => (
                <Grid item xs={12} sm={12} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText primary={item} />
                  </Box>
                </Grid>
              ))}
            </Grid>
            </Box>
            </CardContent>
          </Box>

        </Card>
        </Box>
      </MediaQuery>

      <MediaQuery minDeviceWidth={701} maxDeviceWidth={900}>
        <Box
              justifyContent='center'
              display={'block'}
              maxWidth='700px'
              sx={{
                marginTop: '30px',
                marginBottom: '40px',
                marginLeft: 'auto',
                marginRight: 'auto',
                textDecoration: 'none',
                boxShadow: '3px 8px 8px 3px rgba(0,0,0,0.2)',
                borderRadius: '10px'
              }}
            >

        <Card
                justifyContent='center'
                data-aos={'fade-up'}
                data-aos-delay={1 * 100}
                data-aos-duration={600}
                flexDirection={'column'}
                display={'flex'}
                maxWidth='300px'
              >
        
          <Box>
          <CardContent >
          
            <Box padding={5} marginBottom={2}>
              <Typography variant={'h4'} sx={{ fontWeight: 700, textAlign: 'center', fontSize: '16px', marginBottom: '30px' }} gutterBottom>
                Advanced capabilities for all types of work process automations
              </Typography>
                
            
            <Grid container spacing={1}>
              {[
                'Automatic work processes with RPA (robotic process automation)',
                'Automated document processing with ETL (extract-transform-load)',
                'Database pull/push with data validation & processing',
                'File conversion, OCR data extraction & image correction',
                'HTML input for web page data extraction',
                'Wide range of file inputs including JSON, XML & CSV',
                'Extensive file output options',
              ].map((item, i) => (
                <Grid item xs={12} sm={6} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText primary={item} />
                  </Box>
                </Grid>
              ))}
            </Grid>
            </Box>
            </CardContent>
          </Box>

        </Card>
        </Box>
      </MediaQuery>

      <MediaQuery minDeviceWidth={901} maxDeviceWidth={1100}>
        <Box
              justifyContent='center'
              display={'block'}
              maxWidth='900px'
              sx={{
                marginTop: '40px',
                marginBottom: '-10px',
                marginLeft: 'auto',
                marginRight: 'auto',
                textDecoration: 'none',
                boxShadow: '3px 8px 8px 3px rgba(0,0,0,0.2)',
                borderRadius: '10px'
              }}
            >

        <Card
                justifyContent='center'
                data-aos={'fade-up'}
                data-aos-delay={1 * 100}
                data-aos-duration={600}
                flexDirection={'column'}
                display={'flex'}
                maxWidth='300px'
              >
        
          <Box>
          <CardContent >
          
            <Box padding={5} marginBottom={2}>
              <Typography variant={'h5'} sx={{ fontWeight: 700, textAlign: 'center', fontSize: '28px', marginBottom: '30px' }} gutterBottom>
                Advanced capabilities for all types of work process automations
              </Typography>
                
            
            <Grid container spacing={1}>
              {[
                'Automatic work processes with RPA (robotic process automation)',
                'Automated document processing with ETL (extract-transform-load)',
                'Database pull/push with data validation & processing',
                'File conversion, OCR data extraction & image correction',
                'HTML input for web page data extraction',
                'Wide range of file inputs including JSON, XML & CSV',
                'Extensive file output options',
              ].map((item, i) => (
                <Grid item xs={12} sm={6} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText primary={item} />
                  </Box>
                </Grid>
              ))}
            </Grid>
            </Box>
            </CardContent>
          </Box>

        </Card>
        </Box>
      </MediaQuery>

      <MediaQuery minDeviceWidth={1101}>
        <Box
              justifyContent='center'
              display={'block'}
              maxWidth='1200px'
              sx={{
                marginTop: '40px',
                marginBottom: '-10px',
                marginLeft: 'auto',
                marginRight: 'auto',
                textDecoration: 'none',
                boxShadow: '3px 8px 8px 3px rgba(0,0,0,0.2)',
                borderRadius: '10px'
              }}
            >

        <Card
                justifyContent='center'
                data-aos={'fade-up'}
                data-aos-delay={1 * 100}
                data-aos-duration={600}
                flexDirection={'column'}
                display={'flex'}
                maxWidth='300px'
              >
        
          <Box>
          <CardContent >
          
            <Box padding={5} marginBottom={2}>
              <Typography variant={'h5'} sx={{ fontWeight: 700, textAlign: 'center', fontSize: '28px', marginBottom: '30px' }} gutterBottom>
                Advanced capabilities for all types of work process automations
              </Typography>
                
            
            <Grid container spacing={1}>
              {[
                'Automatic work processes with RPA (robotic process automation)',
                'Automated document processing with ETL (extract-transform-load)',
                'Database pull/push with data validation & processing',
                'File conversion, OCR data extraction & image correction',
                'HTML input for web page data extraction',
                'Wide range of file inputs including JSON, XML & CSV',
                'Extensive file output options',
              ].map((item, i) => (
                <Grid item xs={12} sm={6} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText primary={item} />
                  </Box>
                </Grid>
              ))}
            </Grid>
            </Box>
            </CardContent>
          </Box>

        </Card>
        </Box>
      </MediaQuery>
      </Box>
    )
};

export default Capabilities;