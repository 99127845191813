import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

const mock = [
  {
    feedback:
      'Prism eForm Designer allows you to easily build eForms that range in complexity and purpose. Using the \
      eForm Designer, you can design and publish an eForm with drop-down fields, eSignatures, and many more.',
    title: 'Create',
    icon: <BorderColorIcon/>
  },
  {
    feedback:
      'Simply route documents and data collected with eForms for further processing. A workflow can process \
      using your organization\'s unique requirements for approvals, notifications, and more.',
    title: 'Automate',
    icon: <AltRouteIcon/>
  },
  {
    feedback:
      'The collected data is also used to auto-populate a related suite of forms or documents. \
      In addition, data can be written into barcode format onto any eForm or document for automatic generation.',
    title: 'Process',
    icon: <DisplaySettingsIcon/>
  },
];

const HowItWorks = () => {
  return (
    <Box
      width={1}
      height={1}
      data-aos={'fade-up'}
      component={Card}
    >
      <CardContent>
        <Box>
          <Box marginBottom={4}>
            <Box marginBottom={2}>
              <Typography
                sx={{
                  fontWeight: 'medium',
                  color: '#555555'
                }}
                color={'primary'} // TODO change color
                align={'center'}
              >
                Automate electronic processing of data and documents with eForms
              </Typography>
            </Box>
            <Typography
              variant="h4"
              align={'center'}
              data-aos={'fade-up'}
              gutterBottom
              sx={{
                fontWeight: 700,
                marginTop: 1,
              }}
            >
              How it works
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {mock.map((item, i) => (
              <Grid item xs={12} md={4} key={i}>
                <Box
                  width={1}
                  height={1}
                  data-aos={'fade-up'}
                  data-aos-delay={i * 100}
                  data-aos-offset={100}
                  data-aos-duration={600}
                  component={Card}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  boxShadow={0}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Box marginBottom={1}>{item.icon}</Box>
                    <Typography
                      align={'center'}
                      variant={'h6'}
                      sx={{ fontWeight: 700 }}
                      gutterBottom
                    >
                      {item.title}
                    </Typography>
                    <Typography align={'center'} color="text.secondary">
                      {item.feedback}
                    </Typography>
                  </CardContent>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box 
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'}}
          >
            <Button
              variant={'contained'}
              size={'large'}
              href={
                'https://prismsoftware.com/contact-us/'
              }
            >
              Schedule a Demo
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Box>
  );
};

export default HowItWorks;
