import React, { useState } from 'react';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TodayIcon from '@mui/icons-material/Today';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Routes from '../../Routes.js';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from '@mui/material/Fab';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Zoom from '@mui/material/Zoom';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Popover from '@mui/material/Popover';
import TabPanel from '@mui/lab/TabPanel';
import {
    OverviewWP,
    FeaturesWP,
    PricingWP,
    Hero,
} from './AllPages';

const grayTheme = createTheme({ 
  palette: { 
    primary: {
      main: '#eceff9'
      }
  } 
})

const blueTheme = createTheme({
    palette: {
        primary: {
            main: '#1976d2'
        }
    }
})

const activeClickTheme = createTheme({
    palette: {
        primary: {
            main: '#40a9ff'
        }
    }
})

function TabPanelMobile(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelMobile.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function WorkTablet() {
    const theme = useTheme();
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElMobile, setAnchorElMobile] = React.useState(null);

    const [view, setView] = React.useState('overview');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [value, setValue] = React.useState(0);


    const handleChangeIndex = (index) => {
    setValue(index);
    };

    const open = Boolean(anchorEl);

    const handleOpenMobileMenu = (event) => {
    setAnchorElMobile(event.currentTarget);
    };

    const handleCloseMobileMenu = () => {
        setAnchorElMobile(null);
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const id = open ? 'simple-popover' : undefined;
    return (
        <Box>
        <Main>
        <Hero />
        <ThemeProvider theme={grayTheme}>
            <AppBar position='sticky' sx={{ boxShadow: '0px 0px 0px 0px' }}>
            <Toolbar>
            <Box
            display={'flex'}
            alignItems={'baseline'}
            >
            <Typography fontFamily='Lato, sans-serif' fontSize='28px' sx={{ textAlign: 'center', color: '#343F53', marginLeft: '15px' }}>
            Prism WorkPath
            </Typography>
            <IconButton
                aria-describedby={id} 
                onClick={handleClick}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
            >
                <KeyboardArrowDownIcon />
            </IconButton>
            </Box>
            <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
            >
                <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ "&& .Mui-selected": { // && are used to increase the specificity
                         color: "#40a9ff" }, 
                         borderRight: 1, 
                         borderColor: 'divider' }}
                >
                    <Tab label="Overview" onClick={handleClose} {...a11yProps(0)} 
                        sx={{
                            '&:hover': {
                            color: '#40a9ff',
                            opacity: 1,}
                            }} />
                    <Tab label="Features" onClick={handleClose} {...a11yProps(1)}
                        sx={{
                            '&:hover': {
                            color: '#40a9ff',
                            opacity: 1,}
                            }} />
                    <Tab label="Pricing" onClick={handleClose} {...a11yProps(2)}
                        sx={{
                            '&:hover': {
                            color: '#40a9ff',
                            opacity: 1,}
                            }} />
                </Tabs>
            </Popover>
            <ThemeProvider theme={blueTheme}>
                <Button size='small' variant="contained" href='https://prismsoftware.com/contact-us/'  startIcon={<TodayIcon />} sx={{ boxShadow: '0px 0px 0px 0px', marginBottom: 0, marginLeft: 1, marginRight: 1 }}>
                    Schedule a demo
                </Button>
            </ThemeProvider>
            </Toolbar>
            </AppBar>
            </ThemeProvider>
                <TabPanelMobile value={value} index={0} sx={{ padding: 0, marginTop: 5 }}>
                    <OverviewWP />
                </TabPanelMobile>
                <TabPanelMobile value={value} index={1} sx={{ padding: 0, marginTop: 5 }}>
                    <FeaturesWP />
                </TabPanelMobile>
                <TabPanelMobile value={value} index={2} sx={{ padding: 0, marginTop: 5 }}>
                    <PricingWP />
                </TabPanelMobile>
    
        </Main>
        </Box>
    )
}