import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import protect from './Protect.svg';
import cs_benefits2 from 'images/cs_benefits2.jpg';

const Benefits = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const LeftSide = () => (
    <Grid container spacing={2} >
      <Grid container item sx={{ alignItems: 'center', display: 'relative', justifyContent: 'center' }}>
        <Box
          width={{ xs: 1, sm: 0.8, md: 1 }}
          height={{ xs: 1, sm: 0.8, md: 1 }}
          marginBottom={{ xs: 0, sm: 5, md: 0 }}
          alignItems='center'
          justifyContent='center'
        >
          <img src={protect}/>
        </Box>
      </Grid>
    </Grid>
  );

  const RightSide = () => (
    <Box>
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
            color: '#555555'
          }}
          gutterBottom
          color={'text.secondary'} // TODO change color
        >
          Real-Time Breach Alerts
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
          Protect your confidential data
        </Typography>
        <Typography variant={'p'} sx={{ lineHeight: 1.8 }}>
          bizhub SECURE Alert creates a record of any bizhub MFP user activity: copy, print, scan, 
          fax, and email. This record contains both the MFP user and device data of the bizhub MFP 
          activity. Activity records are accessible only by permitted users.
          <br/>
          <br/>
          bizhub SECURE Alert also provides real-time alerts in the event of a data breach at the MFP. 
          The user predefines the data and keywords that constitute such a breach. An automatic email 
          notification is sent to user-designated personnel when bizhub SECURE Alert detects a breach 
          alert. The bizhub SECURE Alert also provides the specific who, what, when, and where of 
          such a data breach.
        </Typography>
      </Box>
      
      <Box
        marginTop={4}
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'flex-start' }}
      >
        <Button
          component={'a'}
          variant="contained"
          color="primary"
          size="large"
          href={
            'https://prismsoftware.com/contact-us/'
          }
        >
          Learn More
        </Button>
        {/* <Box
          marginTop={{ xs: 2, sm: 0 }}
          marginLeft={{ sm: 2 }}
          width={{ xs: '100%', md: 'auto' }}
        >
          <Button
            component={'a'}
            href={'/docs/introduction'}
            variant="outlined"
            color="primary"
            size="large"
            fullWidth={isMd ? false : true}
          >
            Download Datasheet      {/**TODO add download arrow *
          </Button>
        </Box> */}
      </Box>
    </Box>
  );

  return (
    <Grid container spacing={4}>
      <Grid
        item
        xs={12}
        md={5}
      >
        <LeftSide />
      </Grid>
      <Grid item container alignItems={'center'} xs={12} md={7}>
        <RightSide />
      </Grid>
    </Grid>
  );
};

export default Benefits;
