
import React, {useState, useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from 'components/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CancelIcon from '@mui/icons-material/Cancel';
// import ReactPaginate from "react-paginate";
// import Pagination from "react-paginate";
import MediaQuery from 'react-responsive';

const features = [
  {
    title: 'Enterprise | Fully Automated RPA, ETL & Work Processing',
    id: 1,
  },
  {
    title: 'Individual | Desktop RPA, ETL & Work Processing',
    id: 2,
  },
  {
    title: 'Desktop PDF & Document Editing',
    id: 3,
  },
  {
    title: 'Document-centric Work Processing',
    id: 4,
  },
  {
    title: 'Data-centric Work Processing',
    id: 5,
  },
  {
    title: 'Output Automation: SharePoint, OneDrive, Box, Dropbox, Google Drive',
    id: 6,
  },
  {
    title: 'WorkPath Connect REST API Module Compatibility',
    id: 7,
  }
];

//prices and highlights haven't been edited yet

const pricing = [
  {
    title: 'Desktop',
    price: {
      monthly: 22,
      annual: 210,
    },
    features: [2, 3, 4],
    lack: [1, 5, 6, 7],
    isHighlighted: false,
    btnText: 'Get a demo',
    href: 'https://prismsoftware.com/contact-us/',
  },
  {
    title: 'Standard',
    price: {
      annual: 420,
      monthly: 44,
    },
    features: [1, 4, 7],
    lack: [2, 3, 5, 6],
    isHighlighted: true,
    btnText: 'Get a demo',
    href: 'https://prismsoftware.com/contact-us/',
  },
  {
    title: 'Advanced',
    price: {
      annual: 740,
      monthly: 77,
    },
    features: [1, 4, 5, 6, 7],
    lack: [2, 3],
    isHighlighted: false,
    btnText: 'Get a demo',
    href: 'https://prismsoftware.com/contact-us/',
  },
];

const PriceTable = () => {
  const theme = useTheme();

  // // PAGINATION
  // const[versions, setVersions] = useState(pricing);
  // // state representing which version we're looking at
  // const[pageNumber, setPageNumber] = useState(0);

  // // look at how many versions at a time
  // const versionsPerPage = 1;
  // // how many pages looked at
  // const pagesVisited = pageNumber*versionsPerPage;

  // // function to slice through versions 
  // const displayVersions = versions
  // .slice(pagesVisited, pagesVisited + versionsPerPage)
  // .map((version) => {
  //   return (
  //     version.title
  //   );
  // });

  // // show features for specified version 
  // const displayFeatures = versions
  // .slice(pagesVisited, pagesVisited + versionsPerPage)
  // .map((version) => {
  //   return (
  //     features.map((feature) => (
  //       <TableRow key={feature.id}>
  //         <TableCell component="th" scope="row">
  //           {feature.title}
  //         </TableCell>
  //         <TableCell align="center">
  //           <Box display={'flex'} justifyContent={'center'}>
  //             {version.features.indexOf(feature.id) !== -1 ? (
  //               <Box
  //                 component={Avatar}
  //                 bgcolor={theme.palette.secondary.main}
  //                 width={20}
  //                 height={20}
  //               >
  //                 <svg
  //                   width={12}
  //                   height={12}
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   viewBox="0 0 20 20"
  //                   fill="currentColor"
  //                 >
  //                   <path
  //                     fillRule="evenodd"
  //                     d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
  //                     clipRule="evenodd"
  //                   />
  //                 </svg>
  //               </Box>
  //             ) : (
  //               ''
  //             )}
  //           </Box>
  //         </TableCell>
  //       </TableRow>
  //     )
  //   )
  // )});

  // const pageCount = Math.ceil(versions.length/versionsPerPage)

  // const changePage = ({selected}) => {
  //   //selected = number that we want to move to
  //   setPageNumber(selected);
  // };

  return (
    // MOBILE VIEW PAGINATION
    <Box>
      {/* <MediaQuery maxWidth={640}>
        <Container>
          <Box marginBottom={4}>
            <Typography fontWeight={700} variant={'h4'} textAlign='center'>
              Versions and Features
            </Typography>
            <Typography color='text.secondary' textAlign='center' marginTop='30px'>
              Prism WorkPath versions are optimized for your specific work 
              process automation
            </Typography>
          </Box>
          <Box>
            <TableContainer component={Paper} elevation={0}>
              <Table aria-label="caption table" sx={{ minWidth: 300 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Features</TableCell>
                    <TableCell align="center">
                      <Typography
                          sx={{ textTransform: 'uppercase', fontWeight: 'medium' }}
                          >
                          {displayVersions}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayFeatures}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
        {/* <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationButtons"}
          previousLinkClassName={"prevButton"}
          nextLinkClassName={"nextButton"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        /> */}
    {/* </MediaQuery> */} 
  
   {/* BROWSER VIEW */}
  {/* <MediaQuery minWidth={'641px'}> */}
      <Container>
        <Box marginBottom={4}>
          <Typography fontWeight={700} variant={'h4'} textAlign='center'>
            Versions and Features
          </Typography>
          <Typography color='text.secondary' textAlign='center' marginTop='30px'>
            Prism WorkPath versions are optimized for your specific work 
            process automation
          </Typography>
        </Box>
        <Box>
          <TableContainer component={Paper} elevation={0}>
            <Table aria-label="caption table" sx={{ minWidth: 600 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Features</TableCell>
                  {pricing.map((item, i) => (
                    <TableCell align="center" key={i}>
                      <Typography
                        sx={{ textTransform: 'uppercase', fontWeight: 'medium' }}
                      >
                        {item.title}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {features.map((feature) => (
                  <TableRow key={feature.id}>
                    <TableCell component="th" scope="row">
                      {feature.title}
                    </TableCell>
                    <TableCell align="center">
                      <Box display={'flex'} justifyContent={'center'}>
                        {pricing[0].features.indexOf(feature.id) !== -1 ? (
                          <Box
                            component={Avatar}
                            bgcolor='green'
                            width={20}
                            height={20}
                          >
                            <svg
                              width={12}
                              height={12}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Box>
                        ) : (
                          ''
                        )}
                        {pricing[0].lack.indexOf(feature.id) !== -1 ? (
                          <Box
                            component={Avatar}
                            color='red'
                            bgcolor='white'
                            width={20}
                            height={20}
                          >
                            <CancelIcon  />
                          </Box>
                        ) : (
                          ''
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box display={'flex'} justifyContent={'center'}>
                        {pricing[1].features.indexOf(feature.id) !== -1 ? (
                          <Box
                            component={Avatar}
                            bgcolor='green'
                            width={20}
                            height={20}
                          >
                            <svg
                              width={12}
                              height={12}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Box>
                        ) : (
                          ''
                        )}
                        {pricing[1].lack.indexOf(feature.id) !== -1 ? (
                          <Box
                            component={Avatar}
                            color='red'
                            bgcolor='white'
                            width={20}
                            height={20}
                          >
                            <CancelIcon  />
                          </Box>
                        ) : (
                          ''
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box display={'flex'} justifyContent={'center'}>
                        {pricing[2].features.indexOf(feature.id) !== -1 ? (
                          <Box
                            component={Avatar}
                            bgcolor='green'
                            width={20}
                            height={20}
                          >
                            <svg
                              width={12}
                              height={12}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Box>
                        ) : (
                          ''
                        )}
                        {pricing[2].lack.indexOf(feature.id) !== -1 ? (
                          <Box
                            component={Avatar}
                            color='red'
                            bgcolor='white'
                            width={20}
                            height={20}
                          >
                            <CancelIcon  />
                          </Box>
                        ) : (
                          ''
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell />
                  {pricing.map((item, i) => (
                    <TableCell align="center" key={i}>
                      <Button
                        size={'large'} 
                        variant='outlined'
                        href={item.href}
                      >
                        {item.btnText}
                      </Button>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        </Container>
       {/* </MediaQuery> */}
    </Box>
  );
};

export default PriceTable;
