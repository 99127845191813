import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import automate from './Automate.svg';

const Features = () => {
  const theme = useTheme();

  const RightSide = () => (
    <Grid container spacing={1}>
      <Grid container item style={{alignItems: 'center', justifyContent: 'center'}}>
        <Box
          width={{ xs: 1, sm: 0.8, md: 1 }}
          height={{ xs: 1, sm: 0.8, md: 1 }}
          marginBottom={{ xs: 0, sm: 5, md: 0 }}
          alignItems='center'
          justifyContent='center'
        >
          <img src={automate} />
        </Box>
      </Grid>
    </Grid>
  );

  const LeftSide = () => (
    <Box>
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
            color: '#555555'
          }}
          gutterBottom
        >
          Automate Data Collection
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
          High volume of personalized business communications
        </Typography>
        <Typography color="text.secondary" variant={'h6'}>
          Substantially improve data and document efficiencies and significantly lower costs 
          with eForms, automated workflow, and automated document generation.
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {[
          'Automated data collection and processing',
          'Automatic forms creation',
          'Automated document workflow',
          'eForms packet creation',
          'Automatic document generation',
          'Proof of delivery of service and goods',
          'Continued elimination of paper',
        ].map((item, i) => (
          <Grid item xs={12} sm={6} key={i}>
            <Box component={ListItem} disableGutters width={'auto'} padding={0}>
              <Box
                component={ListItemAvatar}
                minWidth={'auto !important'}
                marginRight={2}
              >
                <Box
                  component={Avatar}
                  bgcolor={theme.palette.secondary.main}
                  width={20}
                  height={20}
                >
                  <svg
                    width={12}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Box>
              </Box>
              <ListItemText primary={item} />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box marginTop={4}>
        <Button
          variant={'contained'}
          size={'large'}
          href={
            'https://prismsoftware.com/contact-us/'
          }
        >
          Get started
        </Button>
      </Box>
    </Box>
  );

  return (
    <Grid container spacing={4}>
      <Grid
        item
        container
        justifyContent='center'
        xs={12}
        md={7}
      >
        <LeftSide />
      </Grid>
      <Grid item container alignItems={'center'} justifyContent='center' xs={12} md={5}>
        <RightSide />
      </Grid>
    </Grid>
  );
};

export default Features;
