import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Platform from './components/Platform/Platform.js';
import Intuitive from './components/Intuitive/Intuitive.js';
import PriceTable from './components/Table/Table.js';
import InterestForm from './components/InterestForm/InterestForm.js';



const FeaturesWP = () => {
    const theme = useTheme();
    
    return (
        <Box>
            <Platform />
            <Box>
                <Intuitive />
            </Box>
            <PriceTable />



            {/*<InterestForm />*/}
        </Box>
    )
};

export default FeaturesWP;
