import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import AltRouteIcon from '@mui/icons-material/AltRoute';

const mock = [
  {
    feedback:
      'DocForm has a project design interface that allows the creation of a wide range of print and electronic \
      communications. Projects can range from simple notifications to complex conditionally processed customer offers.',
    title: 'Create',
    icon: <NoteAddIcon sx={{ fontSize: '3rem' }}/>
  },
  {
    feedback:
      'DocForm can generate print output to feed the fastest production printers. DocForm\'s integrated workflow \
      also enables the conditional output of documents based on data and other preset processing rules.',
    title: 'Enable',
    icon: <DoneOutlineIcon sx={{ fontSize: '3rem' }}/>
  },
  {
    feedback:
      'Deliver your communications in any type of document including: Statements & invoices, Customer notifications, \
      Explanation of Benefits (EOB), Employee handbooks, promotional notifications, and many more.',
    title: 'Deliver',
    icon: <AltRouteIcon sx={{ fontSize: '3rem' }}/>
  },
];

const HowItWorks = () => {
  return (
    <Box
      width={1}
      height={1}
      data-aos={'fade-up'}
      component={Card}
    >
      <CardContent>
        <Box>
          <Box marginBottom={4}>
            <Box marginBottom={2}>
              <Typography
                sx={{
                  fontWeight: 'medium',
                  color: '#555555'
                }}
                color={'primary'} // TODO change color
                align={'center'}
              >
                Data-drive bisuness communications with DocForm
              </Typography>
            </Box>
            <Typography
              variant="h4"
              align={'center'}
              data-aos={'fade-up'}
              gutterBottom
              sx={{
                fontWeight: 700,
                marginTop: 1,
              }}
            >
              How it works
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {mock.map((item, i) => (
              <Grid item xs={12} md={4} key={i}>
                <Box
                  width={1}
                  height={1}
                  data-aos={'fade-up'}
                  data-aos-delay={i * 100}
                  data-aos-offset={100}
                  data-aos-duration={600}
                  component={Card}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  boxShadow={0}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Box marginBottom={1}>{item.icon}</Box>
                    <Typography
                      align={'center'}
                      variant={'h6'}
                      sx={{ fontWeight: 700 }}
                      gutterBottom
                    >
                      {item.title}
                    </Typography>
                    <Typography align={'center'} color="text.secondary">
                      {item.feedback}
                    </Typography>
                  </CardContent>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box 
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'}}
          >
            <Button
              variant={'contained'}
              size={'large'}
              href={
                'https://prismsoftware.com/contact-us/'
              }
            >
              Schedule a Demo
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Box>
  );
};

export default HowItWorks;
