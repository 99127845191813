import React, { useState } from 'react';
import { useTheme, createTheme, ThemeProvider  } from '@mui/material/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import useMediaQuery from '@mui/material/useMediaQuery';
import MediaQuery from 'react-responsive';
import Container from 'components/Container';

const blueTheme = createTheme({
    palette: {
        primary: {
            main: '#1976d2'
        }
    }
})

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1230,
   
    },
  },
});

const Main = () => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box>
      
      <Container>

        <Typography variant='h4' marginTop={{ xs: 2, sm: 0, md: -8 }} textAlign='center' fontWeight={600} marginBottom='50px'>
          Affordable Pricing for All Work Process Automations
        </Typography>

        <Grid container spacing={4}>

            <Grid item xs={12} lg={3} sm={6}>
              <Box
                component={Card}
                height={1}
                display={'flex'}
                flexDirection={'column'}
                variant={'outlined'}
              >
                <CardContent
                  sx={{
                    padding: 4,
                  }}
                >
                  <Box marginBottom={2} height='150px'>
                    
                      <Typography variant={'h7'} fontWeight={600} gutterBottom color='#1976d2'>
                        ONE-TIME PURCHASE
                      </Typography>
  
                    <Typography variant={'h4'} fontWeight={600} gutterBottom>
                      Desktop
                    </Typography>
                    
                    <Typography color={'text.secondary'}>
                      Desktop RPA, ETL {'&'} work processing for individuals and teams
                    </Typography>
                  </Box>

            
                    <Box
                      display={'flex'}
                      alignItems={'baseline'}
                      marginBottom={2}
                    >
                      <Typography fontWeight={700} sx={{ fontSize: '44px' }}>
                        $240
                      </Typography>
                      
                      <Typography
                        variant={'subtitle1'}
                        color={'text.secondary'}
                        fontWeight={700}
                      >
                        per user
                      </Typography>
                    </Box>
             

                  <Typography sx={{ fontStyle: 'italic', fontSize: '14px', marginTop: -3.5, marginBottom: 2 }}>
                      One-time purchase*
                  </Typography>

                  <Typography color='text.secondary' marginBottom='10px'>
                      Includes:
                  </Typography>
                  <Grid container spacing={1}>
                    
                    {['Personalized automated workflows', 
                      'Local automated document processing', 
                      'Easily integrate with DocRecord'].map((feature, j) => (
                      <Grid item xs={12} key={j}>
                        <Box
                          component={ListItem}
                          disableGutters
                          width={'auto'}
                          padding={0}
                        >
                        
                          <Box
                            component={ListItemAvatar}
                            minWidth={'auto !important'}
                            marginRight={2}
                          >
               
                            <Box
                              component={Avatar}
                              bgcolor='#1976d2'
                              width={20}
                              height={20}
                            >
                              <svg
                                width={12}
                                height={12}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </Box>
                           
                          </Box>
                          <ListItemText primary={feature} />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions sx={{ justifyContent: 'center', marginBottom: 3 }}>
                  <ThemeProvider theme={blueTheme}>
                    <Grid justifyContent='center' container>
                      <Grid container justifyContent='center' item md={12}>
                        <Button size={'large'} variant={'outlined'} sx={{ boxShadow: '0px 0px 0px 0px', marginBottom: 2 }}>
                          Contact to purchase
                        </Button>
                      </Grid>
                      <Grid container justifyContent='center' item md={12}>
                        <Button size={'large'} variant={'contained'} sx={{ boxShadow: '0px 0px 0px 0px' }}>
                          Schedule a demo
                        </Button>
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </CardActions>
                <CardContent>
                  <Typography sx={{ fontSize: '8px', marginRight: 2, marginLeft: 2, marginTop: -4}}>
                        *One-time billing licenses are perpetual licenses. Licenses do not include upgrades but are valid for all updates.
                  </Typography>
                </CardContent>
              </Box>
            </Grid>

            <Grid item xs={12} lg={3} sm={6}>
              <Box
                component={Card}
                height={1}
                display={'flex'}
                flexDirection={'column'}
                variant={'outlined'}
              >
                <CardContent
                  sx={{
                    padding: 4,
                  }}
                >
                  <Box marginBottom={2} height='150px'>
                    <Typography variant={'h7'} fontWeight={600} gutterBottom color='#1976d2'>
                      ONE-TIME PURCHASE
                    </Typography>
                    <Typography variant={'h4'} fontWeight={600} gutterBottom>
                      Standard
                    </Typography>
                    <Typography color={'text.secondary'}>
                      Enterprise documents {'&'} files: Fully automated workflows, RPA, ETL {'&'} work processing
                    </Typography>
                  </Box>
         
                  <Box
                    display={'flex'}
                    alignItems={'baseline'}
                    marginBottom={2}
                  >
                    <Typography fontWeight={700} sx={{ fontSize: '44px' }}>
                      $2,000
                    </Typography>
                    
                    <Typography
                      variant={'subtitle1'}
                      color={'text.secondary'}
                      fontWeight={700}
                    >
                      per server
                     
                    </Typography>
                  </Box>

                  <Typography sx={{ fontStyle: 'italic', fontSize: '14px', marginTop: -3.5, marginBottom: 2 }}>
                      One-time purchase*
                  </Typography>

                  <Typography color='text.secondary' marginBottom='10px'>
                      Includes:
                  </Typography>
                  <Grid container spacing={1}>
                    
                    {['Enterprise automation & work processing',
                      'Web inputs such as Box, Google Drive, etc.',
                      'Wide range of document & file input types'].map((feature, j) => (
                      <Grid item xs={12} key={j}>
                        <Box
                          component={ListItem}
                          disableGutters
                          width={'auto'}
                          padding={0}
                        >
                        
                          <Box
                            component={ListItemAvatar}
                            minWidth={'auto !important'}
                            marginRight={2}
                          >
               
                            <Box
                              component={Avatar}
                              bgcolor='#1976d2'
                              width={20}
                              height={20}
                            >
                              <svg
                                width={12}
                                height={12}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </Box>
                           
                          </Box>
                          <ListItemText primary={feature} />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions sx={{ justifyContent: 'center', marginBottom: 3 }}>
                  <ThemeProvider theme={blueTheme}>
                    <Grid justifyContent='center' container>
                      <Grid container justifyContent='center' item md={12}>
                        <Button size={'large'} variant={'outlined'} sx={{ boxShadow: '0px 0px 0px 0px', marginBottom: 2 }}>
                          Contact to purchase
                        </Button>
                      </Grid>
                      <Grid container justifyContent='center' item md={12}>
                        <Button size={'large'} variant={'contained'} sx={{ boxShadow: '0px 0px 0px 0px' }}>
                          Schedule a demo
                        </Button>
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </CardActions>
                <CardContent>
                  <Typography sx={{ fontSize: '8px', marginRight: 2, marginLeft: 2, marginTop: -4}}>
                        *One-time billing licenses are perpetual licenses. Licenses do not include upgrades but are valid for all updates.
                  </Typography>
                </CardContent>
              </Box>
            </Grid>

            <Grid item xs={12} lg={3} sm={6}>
              <Box
                component={Card}
                height={1}
                display={'flex'}
                flexDirection={'column'}
                variant={'outlined'}
              >
                <CardContent
                  sx={{
                    padding: 4,
                  }}
                >
                  <Box marginBottom={2} height='150px'>
                    <Typography variant={'h7'} fontWeight={600} gutterBottom color='#1976d2'>
                      SUBSCRIPTION
                    </Typography>
                    <Typography variant={'h4'} fontWeight={600} gutterBottom>
                      Advanced
                    </Typography>
                    <Typography color={'text.secondary'}>
                      Enterprise documents {'&'} files: Fully automated workflows, RPA, ETL {'&'} work processing
                    </Typography>
                  </Box>
                  <Box
                    display={'flex'}
                    alignItems={'baseline'}
                    marginBottom={2}
                  >
                    <Typography fontWeight={700} sx={{ fontSize: '44px' }}>
                      $167
                    </Typography>
                    
                    <Typography
                      variant={'subtitle1'}
                      color={'text.secondary'}
                      fontWeight={700}
                    >
                      /mo
                     
                    </Typography>
                  </Box>

                  <Typography sx={{ fontStyle: 'italic', fontSize: '14px', marginTop: -3.5, marginBottom: 2 }}>
                      Annual subscription required; billed annually**
                  </Typography>

                  <Typography color='text.secondary' marginBottom='10px'>
                      Includes:
                  </Typography>
                  <Grid container spacing={1}>
                    
                    {['Automated integrations: SharePoint and more',
                      'XML, JSON & HTML inputs and processing',
                      'Wide range of other data-centric inputs'].map((feature, j) => (
                      <Grid item xs={12} key={j}>
                        <Box
                          component={ListItem}
                          disableGutters
                          width={'auto'}
                          padding={0}
                        >
                        
                          <Box
                            component={ListItemAvatar}
                            minWidth={'auto !important'}
                            marginRight={2}
                          >
               
                            <Box
                              component={Avatar}
                              bgcolor='#1976d2'
                              width={20}
                              height={20}
                            >
                              <svg
                                width={12}
                                height={12}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </Box>
                           
                          </Box>
                          <ListItemText primary={feature} />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions sx={{ justifyContent: 'center', marginBottom: 3 }} >
                  <ThemeProvider theme={blueTheme}>
                    <Grid justifyContent='center' container>
                      <Grid container justifyContent='center' item md={12}>
                        <Button size={'large'} variant={'outlined'} sx={{ boxShadow: '0px 0px 0px 0px', marginBottom: 2 }}>
                          Contact to purchase
                        </Button>
                      </Grid>
                      <Grid container justifyContent='center' item md={12}>
                        <Button size={'large'} variant={'contained'} sx={{ boxShadow: '0px 0px 0px 0px' }}>
                          Schedule a demo
                        </Button>
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </CardActions>
                <CardContent>
                  <Typography sx={{ fontSize: '8px', marginRight: 2, marginLeft: 2, marginTop: -4}}>
                        **Annual subscriptions include all updates and upgrades during the licensed annual period.
                  </Typography>
                </CardContent>
              </Box>
            </Grid>

            <Grid item xs={12} lg={3} sm={6}>
              <Box
                component={Card}
                height={1}
                display={'flex'}
                flexDirection={'column'}
                variant={'outlined'}
              >
                <CardContent
                  sx={{
                    padding: 4,
                  }}
                >
                  <Box marginBottom={2} height='150px'>
                    <Typography variant={'h7'} fontWeight={600} gutterBottom color='#1976d2'>
                      SUBSCRIPTION
                    </Typography>
                    <Box
                      display={'flex'}
                      alignItems={'baseline'}
              >
                      <Typography variant={'h4'} fontWeight={600} gutterBottom>
                        Connect 
                      </Typography>
                      <Typography sx={{ fontStyle: 'italic', fontSize: '22px', marginLeft: 1 }}>
                        (Module)
                      </Typography>
                    </Box>
                    <Typography color={'text.secondary'}>
                      Enables bidirectional REST API connections: Requires either Standard or Advanced
                    </Typography>
                  </Box>
                  <Box
                    display={'flex'}
                    alignItems={'baseline'}
                    marginBottom={2}
                  >
                    <Typography fontWeight={700} sx={{ fontSize: '44px' }}>
                      $150
                    </Typography>
                    
                    <Typography
                      variant={'subtitle1'}
                      color={'text.secondary'}
                      fontWeight={700}
                    >
                      /mo
                     
                    </Typography>
                  </Box>

     
                    <Typography sx={{ fontStyle: 'italic', fontSize: '14px', marginTop: -3.5, marginBottom: 2 }}>
                        Annual subscription required; billed annually**
                    </Typography>
                    


                  <Typography color='text.secondary' marginBottom='10px'>
                      Includes:
                  </Typography>
                  <Grid container spacing={1}>
                    
                    {['Bidirectional application data exchange',
                      'Easy no-code REST API integrations',
                      'Designed for non-Developers'].map((feature, j) => (
                      <Grid item xs={12} key={j}>
                        <Box
                          component={ListItem}
                          disableGutters
                          width={'auto'}
                          padding={0}
                        >
                        
                          <Box
                            component={ListItemAvatar}
                            minWidth={'auto !important'}
                            marginRight={2}
                          >
               
                            <Box
                              component={Avatar}
                              bgcolor='#1976d2'
                              width={20}
                              height={20}
                            >
                              <svg
                                width={12}
                                height={12}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </Box>
                           
                          </Box>
                          <ListItemText primary={feature} />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions sx={{ justifyContent: 'center', marginBottom: 3 }}>
                  <ThemeProvider theme={blueTheme}>
                    <Grid justifyContent='center' container>
                      <Grid container justifyContent='center' item md={12}>
                        <Button size={'large'} variant={'outlined'} sx={{ boxShadow: '0px 0px 0px 0px', marginBottom: 2 }}>
                          Contact to purchase
                        </Button>
                      </Grid>
                      <Grid container justifyContent='center' item md={12}>
                        <Button size={'large'} variant={'contained'} sx={{ boxShadow: '0px 0px 0px 0px' }}>
                          Schedule a demo
                        </Button>
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </CardActions>
                <CardContent>
                  <Typography sx={{ fontSize: '8px', marginRight: 2, marginLeft: 2, marginTop: -4}}>
                        **Annual subscriptions include all updates and upgrades during the licensed annual period.
                  </Typography>
                </CardContent>
              </Box>
            </Grid>
      
      
        </Grid>
      </Container>
    </Box>
  );
};

export default Main;
