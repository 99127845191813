import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Container from 'components/Container';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import employees from '../svg/employees.svg';
import building from '../svg/building.svg';

const Content = () => {
  
  return (
    <Box>
     
                <Grid 
                  container 
                  spacing={2}
                  position={'relative'}
                >
                    <Grid item container md={7} sx={{ justifyContent: 'center' }} >
                      <Container>
                        <Typography variant='h4' style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Our story</Typography>
                        <Typography color='text.secondary'>
                            Prism Software provides a range of applications for the processing and 
                            managing of documents and data. These affordable applications enable all size organizations 
                            to greatly reduce their costs and significantly increase their efficiencies.
                        </Typography>
                      </Container>
                    </Grid>

                    <Grid container item md={5} sx={{ justifyContent: 'center' }}>
                  
                       <img src={employees} style={{ maxWidth: '300px' }} />
        
                    </Grid>
                </Grid>
   


            
                <Grid container 
                  spacing={2}
                  position={'relative'}
                  direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}
                >
                    <Grid marginBottom={{ xs: 2, sm: 5, md: 0 }} item container md={5} sx={{ justifyContent: 'center' }}>
                        
                            <img  src={building} style={{ maxWidth: '300px' }}/>
                   
                    </Grid>

                    <Grid item container md={7} sx={{ justifyContent: 'center' }}>
                      <Container>
                        <Typography variant='h4' style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Trusted by all organizations</Typography>
                        <Typography color='text.secondary'>
                            We empower organizations to do more. Prism's solutions are used by leading organizations worldwide. 
                            Our applications are essential for industries such as healthcare, government, financial, 
                            insurance, defense, education and corporations.
                        </Typography>
                      </Container>
                    </Grid>
                </Grid>
        


             
    </Box>
  );
};

export default Content;
