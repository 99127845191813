import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import DocTablet from './DocTablet.js';
import DocMobile from './DocMobile.js';
import DocDesktop from './DocDesktop.js';

export default function DocRecord() {
    const theme = useTheme();

    const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
        defaultMatches: true,
    });
    const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
        defaultMatches: true,
    });
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <Box >
            {isMd ? <DocDesktop/> : (isSm && isXs) ? <DocTablet/> : <DocMobile/> }
        </Box>
    )
}