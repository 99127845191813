import React from 'react';
import { useTheme } from '@mui/material/styles';
import Typed from 'react-typed';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ImportExportRoundedIcon from '@mui/icons-material/ImportExportRounded';
import PanToolAltRoundedIcon from '@mui/icons-material/PanToolAltRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const ModulesToWP = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      position={'relative'}
      sx={{
        backgroundColor: theme.palette.alternate.main
      }}
    >
      <Box
        justifyContent='center'
        display={'block'}
        width='85vw'

        sx={{
          marginTop: '80px',
          marginBottom: '80px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textDecoration: 'none',
          boxShadow: '3px 8px 8px 3px rgba(0,0,0,0.2)',
          borderRadius: '10px'
        }}
      >

        <Card
          justifyContent='center'
          data-aos={'fade-up'}
          data-aos-delay={1 * 100}
          data-aos-duration={600}
          flexDirection={'column'}
          display={'flex'}
          maxWidth='500px'
        >

          <Box>
            <CardContent>
              <Box marginBottom={2}>
                <Typography
                  variant={'h5'}
                  sx={{
                    fontWeight: 300,
                    textAlign: 'center',
                    paddingTop: '20px',
                    paddingBottom: '10px',
                  }}>
                  Introducing a module to WorkPath
                </Typography>
                <Typography variant={'h4'} sx={{ fontWeight: 700, textAlign: 'center' }} gutterBottom>
                  How it works
                </Typography>

              </Box>
              <Grid container justifyContent='center' marginTop='75px'>

                <Grid item md={3.5}>
                  <Grid
                    container
                    align="center"
                    justify="center"
                    direction="column">

                    <Grid item>
                      <PublicRoundedIcon sx={{ fontSize: '3rem' }} />

                      <Typography
                        variant={'h5'}
                        sx={{
                          textAlign: 'center',
                          marginTop: '20px',
                          marginBottom: '20px',
                        }}
                      >Connect
                      </Typography>
                      <Typography

                        sx={{
                          textAlign: 'center',
                          padding: '10px'
                        }}>
                        Prism WorkPath Connect easily connects using RESTful APIs together to allow bidirectional exchange of data.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>


                <Grid item md={3.5}>
                  <Grid
                    container
                    align="center"
                    justify="center"
                    direction="column">

                    <Grid item>
                      <PanToolAltRoundedIcon sx={{ fontSize: '3rem' }} />

                      <Typography
                        variant={'h5'}
                        sx={{
                          textAlign: 'center',
                          marginTop: '20px',
                          marginBottom: '20px',
                        }}>Enable</Typography>
                      <Typography

                        sx={{
                          textAlign: 'center',
                          padding: '10px'
                        }}>
                        Easily pass data between your existing applications to enable seamless communication.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={3.5}>
                  <Grid
                    container
                    align="center"
                    justify="center"
                    direction="column">

                    <Grid item>

                      <ImportExportRoundedIcon sx={{ fontSize: '3rem', verticalAlign: 'center' }} />
                      <Typography
                        variant={'h5'}
                        sx={{
                          textAlign: 'center',
                          marginTop: '20px',
                          marginBottom: '20px',
                        }}>Exchange</Typography>
                      <Typography

                        sx={{
                          textAlign: 'center',
                          padding: '10px'
                        }}>
                        Save time and money with the automated exchange of data between your most essential applications.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

        
              <Grid
                container
                spacing={0}
                align="center"
                justify="center"
                direction="column"
                marginTop='50px'
              >
                <Grid item >
                 
                <Button size='large' variant='contained'>Get Started</Button>
                </Grid>
              </Grid>

            </CardContent>
          </Box>

        </Card>
      </Box>
    </Grid>
  )
};

export default ModulesToWP;