import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Meat from './components/Meat/Meat.js';
import Capabilities from './components/Capabilities/Capabilities.js';
import Organization from './components/Organization/Organization.js';
import InterestForm from './components/InterestForm/InterestForm.js';

const OverviewWP = () => {
  const theme = useTheme();
  return (
    <Box>
        <Meat />
        <Capabilities />
        <Organization />
        <InterestForm />
    </Box>
  );
};

export default OverviewWP;
