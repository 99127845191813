import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Helmet} from 'react-helmet'; 
import Main from 'layouts/Main';
import Container from 'components/Container';
import { ContactCard, Content, Contact } from './components';

const Careers = () => {
  const theme = useTheme();

  return (
    <Main>
      <div>
        <Box marginTop={{md: 1.8}}>
          <Box bgcolor={theme.palette.primary.main}>
            <Container paddingX={{ xs: 2, sm: 4 }}>
              <Typography
                variant={'h4'}
                gutterBottom
                sx={{
                  fontWeight: 700,
                  color: theme.palette.common.white,
                }}
              >
                Careers
              </Typography>
              <Typography
                gutterBottom
                sx={{  
                  color: theme.palette.common.white,
                }}
              >
                We're hiring!
              </Typography>
            </Container>
            <Box
              component={'svg'}
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
              width={1}
              marginBottom={-1}
            >
              <path
                fill={theme.palette.background.paper}
                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
              ></path>
            </Box>
          </Box>
          <Box>
              <Container>
                <Content />
              </Container>
              <Container
              paddingTop={'0 !important'}
              paddingX={{ xs: 5, sm: 4 }}
              position={'relative'}
              top={0}
            >
              <Box
                component={Grid}
                container
                spacing={4}
                marginTop={{ xs: 2, sm: 0, md: 0 }}
                flexDirection={{ xs: 'column', md: 'row' }}
              >
                  <Contact />
              </Box>
          </Container>
          </Box>
        </Box>
      </div>
    </Main>
  );
};

export default Careers;
