import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Container from 'components/Container';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import Contact from '../../Contact/Contact.js';
import Main from 'layouts/Main';
import { useTheme } from '@mui/material/styles';
import Routes from '../../../../../../Routes.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const May26 = () => {
  const theme = useTheme();
  // mui xs, sm, md responsiveness
  return (
    <Main>
    
    <Box marginTop={{md: 1.8}} sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
      <Box bgcolor={theme.palette.primary.main}>
        <Container >
          <Typography
            variant={'h4'}
            gutterBottom
            sx={{
              fontWeight: 700,
              color: theme.palette.common.white,
            }}
          >
            News
          </Typography>
          <Typography
            gutterBottom
            sx={{  
              color: theme.palette.common.white,
            }}
          >
            Latest news of Prism Software
          </Typography>
        </Container>
        
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          width={1}
          marginBottom={-1}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
      <Container
        paddingTop={'0 !important'}
        paddingX={{ xs: 5, sm: 4 }}
        position={'relative'}
        top={0}
      >
        <Box
          component={Grid}
          container
          spacing={4}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
    <Box sx={{ justifyContent: 'center' }}>
    <Container>
      <Box marginLeft={'-6px'} marginBottom={'15px'}>
        <Button href='/news' startIcon={<ArrowBackIcon/>} >Back</Button>
      </Box>

      <Typography variant='h5' sx={{ marginBottom: 2 }}>
        Prism Software Releases Latest WorkPath Connect; A Low-Cost, No-Code Data Integration Application
      </Typography>

      <Typography color='text.secondary' sx={{ textTransform: 'uppercase', marginBottom: 2 }}>
        May 26, 2022
      </Typography>

      <Typography variant='div' marginBottom={2}>
      <span>IRVINE, CA &mdash; </span> 
        WorkPath Connect, a no-code data integration application by Prism Software, 
        continues expanding its capabilities, ease-of-use, and value with its recent 
        update v4.2.
        <br/><br/>
        WorkPath Connect is a low-cost, rapid data integrator that enables organizations 
        to connect different applications through their RESTful APIs. It is designed to 
        seamlessly exchange data between different applications without having to utilize 
        developers. Easily pass data between cloud and on-prem CRM, ERP, accounting, HR, 
        ECM, shopping, payment, messaging, and other applications.
        <br/><br/>
        This update adds additional features that continue enabling a rapid and easy data 
        transfer. Enhancements include refinements to its already-intuitive interface, quicker 
        addition of multiple endpoints in a single session, and easier endpoint configuration 
        and testing.
        <br /><br />
        Prism WorkPath Connect is a module of Prism WorkPath, an advanced work process 
        automation platform.
        <br/><br/>
      </Typography>
      
      <Typography variant='div' marginBottom={2}>
      <span style={{ fontWeight: 'bold' }}>About Prism WorkPath: </span> 
        Prism WorkPath is a low-cost work process automation platform that eliminates 
        organizations' complex repetitive manual processes. WorkPath utilizes both 
        RPA (robotic process automation) and ETL (extract-transform-load) capabilities 
        enabling the rapid design and automation of intelligent and comprehensive work 
        processes. WorkPath greatly reduces an organization's costs by decreasing labor 
        and substantially improving processing speeds.
        <br/><br/>
        WorkPath Standard is designed for document-centric work processes and WorkPath 
        Advanced is for both data-centric and document-centric requirements. WorkPath 
        Advanced includes a wider range of additional input, processing, and output 
        capabilities. <em>Inputs: </em>Includes web pages, 
        databases, JSON, HTML, and other data sources. 
        <em> Processing: </em>Includes data extraction, correction, 
        and validation. Additionally, it can map HTML web page data and perform data 
        clean up. <em>Outputs: </em> Extensive integration with 
        SharePoint, Google Drive, One Drive, Dropbox, Box, and more. This output includes 
        file manipulations such as uploading, downloading, moving, deleting, renaming, 
        and many, many more. Learn more at 
        <a href='https://www.prismsoftware.com/solutions/workpath' style={{ textDecoration: 'none', marginRight: '3px', marginLeft: '3px' }}>https://www.prismsoftware.com/solutions/workpath</a> and 
        <a href='https://www.prismsoftware.com/solutions/workpath-connect' style={{ textDecoration: 'none', marginLeft: '3px' }}>https://www.prismsoftware.com/solutions/workpath-connect</a>.   
        <br/><br/>
      </Typography>

      <Typography variant='div' marginBottom={2}>
      <span style={{ fontWeight: 'bold' }}>About Prism Software: </span> 
        Prism provides software solutions that greatly improve and automate how work is done while substantially lowering costs. 
        Prism specializes in: ECM with work process automation, advanced work process automation, easy no-code REST API integrations 
        and connections, advanced data capture, and automated business communications. 
        Learn more at <a href='/' style={{ textDecoration: 'none' }}>www.prismsoftware.com</a>.
        <br/><br/>
      </Typography>
        
      <Typography style={{ fontWeight: 'bold', marginTop: '30px' }}>Media contact: </Typography>   
      <Typography variant='div' marginBottom={2}>
        CJ Park 
        <br />
        +1 949.771.9854
        <br/>
        <a href="mailto:cpark@prismsoftware.com" style={{ textDecoration: 'none' }}>cpark@prismsoftware.com</a>  
      </Typography>
     
      <Typography style={{ fontWeight: 'bold', marginTop: '30px' }}>Related links: </Typography> 
      <Typography variant='div' marginBottom={2}>
        <a href='/' style={{ textDecoration: 'none' }}>www.prismsoftware.com</a>
      </Typography>

    </Container>      
    </Box>
            </Box>
          </Container>
          <Contact />
        </Box>

    </Main>
  );
};

export default May26;
