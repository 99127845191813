/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import capture_editor from 'images/capture_editor.png';



const More = () => {
  const theme = useTheme();

  const RightSide = () => (
    <Box>
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 300,
            color: '#555555'
          }}
          gutterBottom
        >
          AND THERE'S MORE
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
          Explore additional features
        </Typography>
        <Typography color="text.secondary" variant={'h6'}>
          Prism Capture is easier and more powerful to use. Start using it in minutes rather than hours or days as with other applications.
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {[
          'Eliminates manual document data extraction and processing',
          'Automate filing documents and keywords',
          'Reduce number of people processing documents',
          'Faster accessibility to documents and data',
        ].map((item, i) => (
          <Grid item xs={12} sm={6} key={i}>
            <Box component={ListItem} disableGutters width={'auto'} padding={0}>
              <Box
                component={ListItemAvatar}
                minWidth={'auto !important'}
                marginRight={2}
              >
                <Box
                  component={Avatar}
                  bgcolor={theme.palette.secondary.main}
                  width={20}
                  height={20}
                >
                  <svg
                    width={12}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Box>
              </Box>
              <ListItemText primary={item} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
  return (
    <Grid container spacing={{xs: 8, sm: 10}} paddingY={{xs: '30px', sm:'80px'}} justifyContent={'center'}>
      <Grid item>
        <Box
          sx={{ display: { xs: 'none', sm: 'block' } }}
          width={1}
        >
          <img src={capture_editor} style={{ maxWidth: '550px'}} />
        </Box>
      </Grid>
      <Grid item container alignItems={'center'} xs={12} md={6}>
        <Box>
          <RightSide />
        </Box>
      </Grid>
      <Grid item container alignItems={'center'} xs={12} md={6}>
        <Box
          sx={{ display: { xs: 'block', sm: 'none' } }}
          width={1}
        >
          <img src={capture_editor} style={{ maxWidth: '100%'}} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default More;
