import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Typography from '@mui/material/Typography';
import automates from '../../../../../../images/workpath/WorkPath-Automates.png';
import work from '../../../../../../images/workpath/WorkPath-Work.png';
import cog from '../../../../../../svg/workpath/Simplify-cog.svg';
import dropdown from './WorkPath-Menu-Dropdown-Automation-Output_1000px.jpg';
import menu from './ETL-WorkPath-Screenshot-Menu-Dropdown.png';
import screenshot from './ETL-WorkPath-Screenshot.png';
import Button from '@mui/material/Button';
import MediaQuery from 'react-responsive';

const Intuitive = () => {
    const theme = useTheme();
    return (
        <Box >
        
        <Box sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>

        <Grid justifyContent='center' position={'relative'}
        sx={{
        backgroundColor: theme.palette.alternate.main, paddingTop: 1
        }}>
            <Typography
                variant='h4'
                textAlign='center'
                marginTop='30px'
                fontWeight={700}
                textAlign='center'
                >
                Intuitive Process Building
            </Typography>
            <Container marginTop={{ xs: -2, sm: -5, md: -6 }} sx={{ maxWidth: '580px', marginBottom: '-20px' }}>
            <Typography
                flex={1}
                textAlign='center'
                justifyContent='center'
                color='text.secondary'
                >
                Easy no-code interface enables rapid design and implementation
            </Typography>
            </Container>

            <Box sx={{ backgroundColor: theme.palette.alternate.main }}>
            <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
                <Grid 
                    container 
                    spacing={2}
                    position={'relative'}
                    marginTop={-10}
                    marginBottom={-10}
                >
                
                    <Grid container item xs={12} md={6} justifyContent='center' >

                        <Container sx={{ justifyContent: 'center', textAlignVertical: 'center' }}>
                        <Typography variant='h4' marginTop={{ xs: 5, sm: 3, md: 0 }} style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Menu-driven makes it fast</Typography>
                        <Typography color='text.secondary'>
                            Prism WorkPath's menu-driven interface allows for the rapid design of custom automated processes in less time than other applications.
                            <br/><br/>
                            A full range of extensive drag-and-drop menu options enables faster production and easy changes.
                        </Typography>
                        </Container>
                    </Grid>

                    <Grid container item xs={12} md={6} justifyContent='center'>
                    <Container marginTop={{ xs: 0, sm: -2, md: -2 }} marginBottom={{ xs: 2, sm: 0, md: 0 }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                        <img style={{ maxWidth: '500px', left: 'auto', right: 'auto', textAlign: 'center', marginTop: -40, marginBottom: 40 }} src={dropdown} />
                    </Container>
                    </Grid>
                </Grid>
            </Container>
            </Box>

            <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
                <Grid container 
                    spacing={2}
                    position={'relative'}
                    marginTop={-10}
                    marginBottom={-10}
                    direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}
                >

                    <Grid container justifyContent='center' item xs={12} sm={12} md={6}>
                        <Container marginTop={{ xs: -6, sm: -10, md: -6 }} marginBottom={{ xs: 8, sm: 5, md: 3 }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                            <img style={{ maxWidth: '480px', left: 'auto', right: 'auto' }} src={menu} />
                        </Container>
                    </Grid>

                    <Grid item container xs={12} sm={12} md={6}>
                        <Container>
                        <Typography variant='h4' marginTop={{ xs: 5, sm: 3, md: 0 }} style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Simple allows complex</Typography>
                        <Typography color='text.secondary'>
                            Prism Workpath's simple interface also enables the design of highly complex automated work processes.
                        </Typography>
                        </Container>
                    </Grid>
                </Grid>
            </Container>

            <Box sx={{ backgroundColor: theme.palette.alternate.main }}>
            <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
                <Grid 
                    container 
                    spacing={2}
                    position={'relative'}
                    marginTop={-10}
                    marginBottom={-10}
                >
                
                    <Grid container item xs={12} md={6} justifyContent='center' >

                        <Container sx={{ justifyContent: 'center', textAlignVertical: 'center' }}>
                        <Typography variant='h4' marginTop={{ xs: 5, sm: 3, md: 0 }} style={{ fontWeight: 700, fontSize: '28px', marginBottom: '40px' }}>Rapid implementation</Typography>
                        <Typography color='text.secondary'>
                            Easy and quick design also means easy to test and fast to implement.
                            <br/><br/>
                            Design work process automations in hours rather than weeks.
                            <br/><br/>
                            <Button variant='contained' marginTop={10}>Get Started</Button>
                        </Typography>
                        
                        </Container>
        
                    </Grid>

                    <Grid container item xs={12} md={6} justifyContent='center'>
                    <Container marginTop={{ xs: -2, sm: -4, md: -2 }} marginBottom={{ xs: 2, sm: 1, md: 0 }} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                        <img style={{ maxWidth: '500px', left: 'auto', right: 'auto', textAlign: 'center', marginTop: -40, marginBottom: 40 }} src={screenshot} />
                    </Container>
                    
                    </Grid>
                    
                </Grid>
            </Container>
            </Box>
        </Grid>
        </Box>
    
    </Box>
    )
};

export default Intuitive;