import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Container from 'components/Container';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import img from '../DocRecord-organizational-benefits-team-1.svg';

const Features = () => {
  const theme = useTheme();

  const RightSide = () => (
    <Grid container spacing={1}>
      <Grid container item justifyContent='center' alignContent='top' >
        <Box
          width={{ xs: 1, sm: 0.8, md: 0.8 }}
          height={{ xs: 1, sm: 0.8, md: 0.8 }}
          marginBottom={{ xs: 0, sm: 0, md: 0 }}
          justifyContent='center'
          zIndex={0}
          marginTop={{ xs: 0, sm: -12, md: 0 }}
        >
          <img src={img} />
        </Box>
      </Grid>
    </Grid>
  );

  const LeftSide = () => (
    <Box zIndex={1} position='relative'>
      <Grid container spacing={1} marginTop={4}>
        {[
          'Substantially improve and automate manual work processes',
          'Elimination of repetitive processing tasks of employees',
          'Instant on-demand access to content, records, and work processes',
          'Continued improvement for remote working capabilities',
          'Convert legacy paper files to electronic records',
        ].map((item, i) => (
          <Grid item xs={12} key={i}>
            <Box component={ListItem} disableGutters width={'auto'} padding={1}>
              <Box
                component={ListItemAvatar}
                minWidth={'auto !important'}
                marginRight={2}
              >
                  <AccountCircleIcon
                    fontSize='medium'
                    color='#707070'
                    />
              </Box>
              <ListItemText primary={item} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return (
    <Container>
      <Box>
        <Typography variant={'h4'} sx={{ fontWeight: 700, zIndex: 1, position: 'relative', marginTop: {xs: -5, sm: -10, md: -10}, marginBottom: {xs: -8, sm: -2, md: 0} }} textAlign='center' gutterBottom>
          What organizations use DocRecord for
        </Typography>
      </Box>
    <Grid container spacing={8}>
      <Grid
        item
        xs={12}
        md={6}
      >
        <LeftSide />
      </Grid>
      <Grid item container xs={12} md={6}>
        <RightSide />
      </Grid>
    </Grid>
    </Container>
  );
};

export default Features;
