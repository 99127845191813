import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import qrcode from './qrcode.svg';

const Accord = () => {
    const theme = useTheme();

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>

            <Container className='justify-content-center' sx={{ justifyContent: 'center', left: 'auto', right: 'auto' }}>
            <Typography 
                variant='h4'
                align={'center'}
                data-aos={'fade-up'}
                gutterBottom
                sx={{
                    fontWeight: 700,
                    marginBottom: 5
                }}>
                Try it yourself
            </Typography>

            <Grid container spacing={5}>
                <Grid item container justifyContent='center' md={6}>
                    <img src={qrcode} style={{ borderRadius: '30px', maxWidth: '500px' }}/>
                </Grid>
                <Grid item container md={6}> 
                <Accordion defaultExpanded expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ left: 'auto', right: 'auto', padding: 3 }} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant='h6' fontWeight='bold'>Try it yourself</Typography>
                        </AccordionSummary>
                        
                        <AccordionDetails> 
                             <Typography color="text.secondary" textAlign='left' marginBottom={1}>
                                Scan the QR code to preview Prism's Smartphone Forms.
                            </Typography>
                            <Grid container spacing={2}>
                                {[
                                'Using your mobile device, scan the QR code.',
                                'Your browser will preview the eForm. Every eForm is customizable',
                                'Once the form is viewed and submitted, you will receive a confirmation email.'
                                ].map((item, i) => (
                                <Grid item xs={12} key={i}>
                                    <Box component={ListItem} disableGutters width={'auto'} padding={0}>
                                    <Box
                                        component={ListItemAvatar}
                                        minWidth={'auto !important'}
                                        marginRight={2}
                                    >
                                        <Box
                                        component={Avatar}
                                        bgcolor={theme.palette.secondary.main}
                                        width={20}
                                        height={20}
                                        >
                                        <svg
                                            width={12}
                                            height={12}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                            fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                            />
                                        </svg>
                                        </Box>
                                    </Box>
                                    <ListItemText primary={item} />
                                    </Box>
                                </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                </Accordion>
                
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ left: 'auto', right: 'auto', padding: 3 }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1b-content"
                        id="panel1b-header"
                    >
                        <Typography variant='h6' fontWeight='bold'>How to scan QR codes with a Samsung Galaxy</Typography>
                    </AccordionSummary>
                     <AccordionDetails> 
                            <Grid container spacing={2} >
                             <ol style={{ lineHeight: 2.5 }}>
                                <li>
                                    Swipe down your screen to access your Quick Settings.
                                </li>

                                <li>
                                    Tap on QR Scanner and click OK to proceed to next step.
                                </li>

                                <li>
                                    The Camera app will launch &mdash; direct your camera at the QR code.
                                </li>

                                <li>
                                    Once the QR code is scanned, you should be able to launch the webpage.
                                </li>

                                <li>
                                    If the QR code cannot scan, you may need to enable the setting. Tap on the Camera Settings icon button and toggle Scan QR Codes.
                                </li>
                             </ol>
                            </Grid>
                            <Typography color="text.secondary" variant='caption' textAlign='left' >
                                This setting is currently only available to Galaxy devices operating on OS Version 9 (Pie).
                            </Typography>
                        </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ left: 'auto', right: 'auto', padding: 3 }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                    >
                        <Typography variant='h6' fontWeight='bold'>How to scan QR codes with an Apple iPhone</Typography>
                    </AccordionSummary>
                     <AccordionDetails> 
                            <Grid container spacing={2} >
                             <ol style={{ lineHeight: 2.5 }}>
                                <li>
                                    Open the Settings app from your homescreen.
                                </li>

                                <li>
                                    Scroll down and tap on Camera.
                                </li>

                                <li>
                                    Find "Scan QR Codes" and enable it by making sure the switch is green.
                                </li>

                                <li>
                                    You're ready to scan QR codes. Open the camera towards the QR code. Click on the notification to open the content of the QR code.
                                </li>
                             </ol>
                            </Grid>
                        </AccordionDetails>
                </Accordion>
            </Grid>
            </Grid>
            
            </Container>

        </Box>
    )
    };

    export default Accord;