import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Page from '../../components/Page';
import Main from 'layouts/Main';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const Accounting = () => {


  return (
    <Main>
      <Page>
        <Box>
          
          <Box
            display={'flex'}
            alignItems={'baseline'}>
            <SettingsIcon fontSize='large' marginRight={'20px'} sx={{ position: 'relative', bottom: -9, marginRight: 2 }} />

            <Typography variant='h5' fontWeight={600} marginBottom={2}>
                Automating unique accounting processes
            </Typography>
          </Box>

          <Typography color={'text.secondary'} fontSize='16px' marginBottom={-3} lineHeight={'26px'}>
              <Box maxWidth={620}>
                  Accounting processes vary widely by organizations. DocRecord 
                  can create work processes that automate much of your current 
                  manual processing.
              </Box>
              <br />
                 While yours will likely vary, many automated accounting 
                 processes include:
          </Typography>
          <Typography color='text.secondary' fontSize='16px' lineHeight={'36px'} marginLeft={3}>
            <br />
            <ul>
              <li>
                Automatic data capture from emailed and other PDFs such as invoices,
              </li>

              <li>
                Auto-validating and correcting this extracted data with a database,
              </li>

              <li>
                Comparing the invoice against open purchase orders,
              </li>

              <li>
                Routing for payment approvals based on vendor and total amounts,
              </li>

              <li>
                Emailing the vendor as to the status of their invoice,
              </li>

              <li>
                Scheduling of payment and check runs,
              </li>

              <li>
                Automating check printing and accompanying remittance letter,
              </li>

              <li>
                Archiving all documents in a single, secure repository for instant retrieval.
              </li>

            </ul>
          </Typography>

          <Box paddingY={4}>
            <Divider />
          </Box>

          <Box
            display={'flex'}
            alignItems={'baseline'}>
            <AttachMoneyIcon fontSize='large' marginRight={'20px'} sx={{ position: 'relative', bottom: -9, marginRight: 2 }} />
            <Typography variant='h5' fontWeight={600} marginBottom={2}>
            Automation reduces labor costs and eliminates errors
            </Typography>
          </Box>

          <Typography color={'text.secondary'} fontSize='16px' maxWidth={650} lineHeight={'26px'}>
            DocRecord is designed to automate work processes to eliminate 
            most of the manual processing, manual data entry, and paper-based 
            activities. This automation greatly speeds the processing times allowing 
            your team to get more done, in less time, with less work.
            <br />
            <br />
            Best of all, workflows can be accessed and processed remotely allowing 
            your team to work from anywhere at any time.
          </Typography>
        </Box>
      </Page>
    </Main>
  );
};

export default Accounting;
